import { useState, useContext, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";

import HomeModal from "../../components/Modal/homeModal";
import Header from "../../components/Header";
import STEPS from "./steps";
import FullWidthTabs from "../../components/OptionsTab";
import RegisterInputs from "../../components/Register";
import Start from "../../components/Start";
import Enroll from "../../components/Enroll";
import DatabaseConsent from "../../components/SignupComponents/DatabaseConsent";
import CannotVerify from "../../components/SignupComponents/CannotVerify";
import VerifyAgeWithScan from "../../components/SignupComponents/VerifyAgeWithScan";
import DLScan from "../../components/DLScanning/DLFaceCompare";
import CameraPermissionFail from "../../components/CameraPermissionFail";
import { useNavigate, useSearchParams } from "react-router-dom";
import Success from "../../components/Success";
import { UserContext } from "../../context/UserContext";
import AdditionalRequirements from "../../components/AdditionalRequirements";
import useToast from "../../utils/useToast";
import {
  getUser,
  getUserPortrait,
  verifyIdApi,
  verifyTokenApi,
} from "../../services/api";
import { SUCCESS, REQUIRES_INPUT, getStatusFromUser } from "../../utils";
import {
  getUserStatus,
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import { generateVCPayload } from "../../utils/vc-dock";
import { updateUser } from "@privateid/cryptonets-web-sdk";
import Feedback from "../../components/Feedback";
import ValuesYourPrivacy from "../../components/ValuesYourPrivacy";
import PreDocument from "../../components/SignupComponents/PreDocument";
import {
  ACCOUNT_CREATED,
  ACCOUNT_CREATION_FAILED,
  FLOW,
  MAX_VERIFY_COUNTS,
} from "../../constants";
import InsuranceCard from "../../components/SignupComponents/InsuranceCard";
import PersonalDetails from "../../components/SignupComponents/PersonalDetails";
import RegisterInputsSsn from "../../components/RegisterSsn";
import AddressDetail from "../../components/SignupComponents/AddressDetails";
import Failure from "../../components/Failure";
import { CvsRequestContext } from "../../context/RequestContext";
import { UserStatusPayload } from "../../interface";
import Animation from "../../components/SignupComponents/Animation";
import DLAnimation from "../../components/SignupComponents/DLAnimation";
import HealthCareScan from "../../components/HealthCareScan";
import PrePassportScan from "../../components/SignupComponents/PrePassportScan";
import PassportScan from "../../components/SignupComponents/PassportScan";
import { useStep } from "../../context/StepContext";
import { UserContextV2 } from "../../context/UserContextV2";
import { generateDidForUser, issueCredentials } from "../../services/vc-dock";
import Passkey from "../../components/Passkey";

interface RegisterPageProps {
  theme: string;
  skin: string;
}

const RegisterPage = ({ theme, skin }: RegisterPageProps) => {
  const { showToast } = useToast();
  const context = useContext(UserContext);
  const requestContext = useContext(CvsRequestContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParams = searchParams.get("token") as string;
  const { setStep, step } = useStep();
  const [prevStep, setPrevStep] = useState(STEPS.PRE_ENROLL);
  const [loading, setLoading] = useState(false);
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const userContextV2 = useContext(UserContextV2);

  const postOidc: () => void = () => {
    // FOR OIDC CONNECTER NEEDS TO POST LIKE A FORM
    if (requestContext.requestFromOIDC) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = `https://oidc.privateid.com/interaction/${requestContext.interactionUID}/register`;
      const params: any = {
        uuid: context.uuid,
        guid: context.guid,
        token: context.tokenParams,
      };
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    }
  };

  const failureSessionRedirect = (session: { failureUrl: string | URL }) => {
    showToast("Your ID verification was not completed.", "error");
    setStep(STEPS.FAILURE);
  };

  const convertLinkToImageData = async (link: string, setState: any) => {
    const newImg = new Image();
    newImg.crossOrigin = "anonymous";
    newImg.src = link;
    newImg.onload = async () => {
      const imgSize = {
        w: newImg.width,
        h: newImg.height,
      };
      const canvas = document.createElement("canvas");
      canvas.setAttribute("height", `${imgSize.h}`);
      canvas.setAttribute("width", `${imgSize.w}`);
      const ctx = canvas.getContext("2d");
      // @ts-ignore
      ctx.drawImage(newImg, 0, 0);
      // @ts-ignore
      const enrollImage = ctx.getImageData(0, 0, imgSize.w, imgSize.h);
      setState(enrollImage);
      context.setDlAction("frontscan");
      setStep(STEPS.DRIVERLICENSE);
    };
  };

  const verifyTokenAPI = async (token: any) => {
    userContextV2.setSessionToken(token);
    context.setTokenParams(token);
    await verifyTokenApi(token).then(handleTokenVerification);
    // handleTokenVerification(token);
  };

  const handleTokenVerification = async (res: any) => {
    context.setVerificationSession(res);
    if (res?.user) {
      await handleCustomerVerification(res);
    } else {
      setStep(STEPS.PRE_ENROLL);
    }
  };

  const handleCustomerVerification = async (res: any) => {
    userContextV2.setUserId(res.user);
    const isCompleted = (element: number) =>
      res.flowsCompleted.includes(element);
    try {
      if (!res.flowsCompleted.length) {
        setStep(STEPS.PRE_ENROLL);
      } else if (
        res.flowsCompleted.length === 1 &&
        isCompleted(FLOW.CREATE_USER)
      ) {
        setStep(STEPS.ENROLL);
      } else if (
        res.flowsCompleted.length === 2 &&
        [FLOW.CREATE_USER, FLOW.UPLOAD_SELFIE].every(isCompleted)
      ) {
        const userPortrait: any = await getUserPortrait(res.token);
        console.log({ userPortrait });
        if (userPortrait?.data) {
          await convertLinkToImageData(
            userPortrait.data,
            context.setEnrollImageData
          );
          setStep(STEPS.DRIVERLICENSE);
        } else {
          setStep(STEPS.FAILURE);
        }
      } else if (
        res.flowsCompleted.length === 3 &&
        [FLOW.CREATE_USER, FLOW.UPLOAD_SELFIE, FLOW.UPLOAD_DOC_FRONT].every(
          isCompleted
        )
      ) {
        context.setDlAction("backscan");
        setStep(STEPS.DRIVERLICENSE);
      } else {
        const data: any = await getUser({ id: res.user });
        const { userApproved, requestScanID, requestResAddress, ...rest } =
          data.status || {};
        context.setUserStatus({
          userApproved,
          requestScanID,
          requestResAddress: !requestScanID && requestResAddress,
          ...rest,
        });
        handleUserStatus(userApproved, rest);
      }
    } catch (e) {
      console.log("error", e);
      setStep(STEPS.PRE_ENROLL);
    }
  };

  const handleUserStatus = (userApproved: any, rest: any) => {
    const status = getStatusFromUser({ userApproved, ...rest });
    const session = context.verificationSession;

    if (status === SUCCESS) {
      showToast("You successfully completed your ID verification.", "success");
      if (session.successUrl) {
        setStep(STEPS.PASSKEY);
        // setStep(STEPS.SUCCESS);
      }
    } else if (status === REQUIRES_INPUT) {
      if (context.verifyAttempts >= MAX_VERIFY_COUNTS) {
        setStep(STEPS.FAILURE);
      }
      context.setVerifyAttempts(context.verifyAttempts + 1);
      showToast("We need more information to verify your identity.", "error");
      setStep(STEPS.ADDITIONAL_REQUIREMENTS);
    } else {
      failureSessionRedirect(session);
    }
  };

  useEffect(() => {
    if (!tokenParams) return;
    verifyTokenAPI(tokenParams);
  }, [tokenParams]);

  const onVerifyId = async () => {
    if (loading) return false;
    setLoading(true);
    setStep(STEPS.ANIMATION);
    // const payload = {
    //   token: context.id,
    // };
    // await verifyIdApi({ id: tokenParams, payload });
    const res = await verifyIdWithSession({
      sessionToken: userContextV2.sessionToken,
    });
    console.log("====> Verify RESULT:", res);
    // const {
    //   userApproved,
    //   requestScanID,
    //   requestResAddress,
    //   ...rest
    // }: UserStatusPayload =
    //   (await getUserStatus({
    //     id: context.id,
    //   })) || {};

    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: userContextV2.sessionToken,
    });
    console.log("====> VERIFY TOKEN RES:", verifyTokenRes);
    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }

    if (verifyTokenRes.status === tokenStatus.SUCCESS) {
      setStep(STEPS.PASSKEY);
      showToast("You successfully completed your ID verification.", "success");
      await issueVC(verifyTokenRes.user);
    } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
      showToast("Your ID verification was not completed.", "error");
      // if (verifyTokenRes.failureUrl) {
      //   setStep(STEPS.FAILURE);
      // }
      setStep(STEPS.FAILURE);
    } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
      showToast("We need more information to verify your identity.", "error");

      userContextV2.setAdditionalRequirements(
        verifyTokenRes?.dueRequirements || []
      );
      setStep(STEPS.ADDITIONAL_REQUIREMENTS);
    }
    setLoading(false);
    // handleUserStatus(userApproved, rest);
  };

  const issueVC = async (userId: string) => {
    try {
      await issueCredentials(userId);
    } catch (e) {
      console.log({ e });
    }
  };

  const _renderChildren = () => {
    switch (step) {
      case STEPS.START:
        return (
          <Start
            matchesSM={matchesSM}
            setPrevStep={setPrevStep}
            setStep={setStep}
            skin={skin}
          />
        );
      case STEPS.REGISTER_CONSENT:
        return (
          <DatabaseConsent
            theme={theme}
            skin={skin}
            setStep={setStep}
            setPrevStep={setPrevStep}
          />
        );

      case STEPS.PRIVACY_CONSENT:
        return (
          <ValuesYourPrivacy
            setPrevStep={setPrevStep}
            skin={skin}
            setStep={setStep}
            theme={theme}
          />
        );
      case STEPS.REGISTER_FORM:
        return (
          <RegisterInputs
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );
      case STEPS.REGISTER_FORM_SSN:
        return (
          <RegisterInputsSsn
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );
      case STEPS.CONSENT_FAIL:
        return (
          <CannotVerify
            theme={theme}
            skin={skin}
            setStep={setStep}
            prevStep={prevStep}
          />
        );
      case STEPS.PRE_ENROLL:
        return (
          <VerifyAgeWithScan
            theme={theme}
            skin={skin}
            setPrevStep={setPrevStep}
            setStep={setStep}
          />
        );
      case STEPS.ENROLL:
        return <Enroll setStep={setStep} skin={skin} />;

      case STEPS.CAMERA_PERMISSION_FAIL:
        return (
          <CameraPermissionFail
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );

      case STEPS.DRIVERLICENSE:
        return <DLScan setStep={setStep} onSuccess={onVerifyId} skin={skin} />;
      case STEPS.SWITCH_DEVICE:
        return <FullWidthTabs setStep={setStep} />;
      case STEPS.PASSKEY:
        return <Passkey skin={skin} setStep={setStep} />;
      case STEPS.SUCCESS:
        return (
          <Success
            matchesSM={matchesSM}
            skin={skin}
            postToOidc={postOidc}
            setStep={setStep}
            message={ACCOUNT_CREATED}
          />
        );
      case STEPS.FAILURE:
        return (
          <Failure
            matchesSM={matchesSM}
            skin={skin}
            postToOidc={postOidc}
            isLogin={false}
            setStep={setStep}
            message={ACCOUNT_CREATION_FAILED}
          />
        );
      case STEPS.ADDITIONAL_REQUIREMENTS:
        return (
          <AdditionalRequirements
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
            handleRequirementsComplete={onVerifyId}
            loading={loading}
          />
        );
      case STEPS.FEEDBACK:
        return (
          <Feedback
            matchesSM={matchesSM}
            setStep={() => setStep(STEPS.PRE_ENROLL)}
            skin={skin}
          />
        );
      case STEPS.PRE_PASSPORT_SCAN:
        return <PrePassportScan theme={theme} skin={skin} setStep={setStep} />;
      case STEPS.PASSPORT_SCAN:
        return (
          <PassportScan onSuccess={onVerifyId} setStep={setStep} skin={skin} />
        );
      case STEPS.PRE_HEALTHCARE_SCAN:
        return <InsuranceCard theme={theme} skin={skin} setStep={setStep} />;
      case STEPS.HEALTHCARE_SCAN:
        return (
          <HealthCareScan
            skin={skin}
            setStep={setStep}
            onSuccess={onVerifyId}
          />
        );
      case STEPS.PRE_DOCUMENT:
        return <PreDocument theme={theme} skin={skin} setStep={setStep} />;
      case STEPS.PERSONAL_DETAILS:
        return (
          <PersonalDetails
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
          />
        );
      case STEPS.ADDRESS_DETAIL:
        return (
          <AddressDetail
            matchesSM={matchesSM}
            setStep={setStep}
            skin={skin}
            setPrevStep={setPrevStep}
            onSuccess={onVerifyId}
          />
        );
      case STEPS.ANIMATION:
        return <Animation />;

      case STEPS.DL_ANIMATION:
        return <DLAnimation matchesSM={matchesSM} setStep={setStep} />;
      default:
        return <></>;
    }
  };
  const themeName = skin || "primary";
  const onFeedback = () => {
    setStep(STEPS.FEEDBACK);
    setPrevStep(step);
  };
  return (
    <>
      {<Header theme={themeName} />}
      <div className="homePageWrapper">
        <HomeModal
          handleClose={() => {
            navigate("/");
          }}
          open={true}
          onFeedback={onFeedback}
          showFeedback={step !== STEPS.FEEDBACK}
          step={step}
          success={step === STEPS.SUCCESS}
          failure={step === STEPS.FAILURE}
        >
          {_renderChildren()}
        </HomeModal>
      </div>
    </>
  );
};

export default RegisterPage;
