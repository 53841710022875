import { useEffect, useContext } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import { UserContext } from "../../../context/UserContext";
import { getStatusFromUser, SUCCESS } from "../../../utils";
import greenCheck from "../../../assets/greenCheck.png";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

const ForgetMeSuccess = ({
  skin,
  matchesSM,
}: {
  skin: string;
  matchesSM: boolean;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const context = useContext(UserContext);
  const palette: { [key: string]: any } = mainTheme.palette;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  useEffect(() => {
    setTimeout(() => {
      const status = getStatusFromUser(window.localStorage.getItem("user"));
      const { successUrl } = context.verificationSession;
      if (successUrl && status === SUCCESS) {
        window.location.href = successUrl;
      }
    }, 2000);
  }, []);
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={20}
          fontWeight={500}
          lineHeight={1.5}
          mt={2}
          className={classes.cardInnerHeading}
        >
          Account has been deleted.
        </Typography>
        <img
          src={greenCheck}
          alt=""
          className={classes.checkIcon}
          width={200}
        />
      </Grid>
      <Grid style={{ marginBottom: 30, height: 45 }}>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}

        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => {
            navigateWithQueryParams("/");
          }}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="capitalize"
          >
            Go back to homepage
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default ForgetMeSuccess;
