import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { ClassNameMap } from "@mui/styles";

import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { navigateToUrl } from "../../utils";
import config from "../../config";
import { createVerificationSession, postLoginAttemp } from "../../services/api";
import { CvsRequestContext } from "../../context/RequestContext";
import { FailureMessage } from "../AuthenticateComponents/LoginFailure";
import { LoginContext } from "../../context/LoginContext";
import { useNavigate } from "react-router";
import STEPS from "../../pages/register/steps";
import {
  ACCOUNT_CREATION_FAILED,
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
} from "../../constants";
import useQuery from "../../hooks/useQueryParams";

function ButtonText(props: {
  loading: boolean;
  classes: ClassNameMap;
  message?: string;
}) {
  if (props.loading) {
    return <CircularProgress className={props.classes.scanLoader} />;
  }
  if (props.message) {
    return <>Enroll now</>;
  }
  return <>Try again</>;
}

const Failure = ({
  skin,
  matchesSM,
  message,
  postToOidc = () => {},
  isLogin = false,
  setStep,
}: {
  skin: string;
  matchesSM: boolean;
  message?: string;
  postToOidc?: () => void;
  isLogin: boolean;
  setStep?: (e: any) => void;
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const [loading, setLoading] = useState(false);
  let query = useQuery();
  const consumerFlow = query.get("bypassSsn");
  const createVerification = async () => {
    if (user?._id && user?.token) {
      navigateToUrl(user?.successUrl, user?.token);
      return;
    }
    setLoading(true);
    const payload = {
      ...config.clientConfig,
    };
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      navigate(
        `/register?token=${result?.token}&skin=${skin}&bypassSsn=${consumerFlow}`
      );
    }
    setLoading(false);
  };

  const loginContext = useContext(LoginContext);
  const logUserFailureAttempt = async () => {
    const userData = {
      status: "failure",
      predictedGUID: loginContext.predictedGUID || "none",
      barcodeHash: loginContext.barcodeHash,
      error: true,
      errorMessage: loginContext.errorMessage,
    };

    await postLoginAttemp(userData);
  };

  const requestContext = useContext(CvsRequestContext);
  useEffect(() => {
    if (isLogin) {
      logUserFailureAttempt();
    }
  }, []);

  useEffect(() => {
    if (requestContext.requestFromOIDC) {
      setTimeout(() => {
        postToOidc();
      }, 3000);
    }
  }, [requestContext]);

  let renderComponent;

  if (
    message === ACCOUNT_CREATION_FAILED ||
    message === AUTHENTICATION_FAILED
  ) {
    renderComponent = (
      <>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() =>
            message === AUTHENTICATION_FAILED
              ? setStep?.(STEPS.SIGN_IN)
              : createVerification()
          }
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
          >
            Try again
          </Typography>
        </Button>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => setStep?.(STEPS.FEEDBACK)}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
          >
            Provide Feedback
          </Typography>
        </Button>
      </>
    );
  } else if (message === ACCOUNT_NOT_APPROVED) {
    renderComponent = (
      <>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={createVerification}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
          >
            Register again
          </Typography>
        </Button>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => navigate("/")}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
          >
            Return to home page
          </Typography>
        </Button>
      </>
    );
  } else if (!requestContext.requestFromOIDC) {
    renderComponent = (
      <Box>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={createVerification}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
          >
            <ButtonText loading={loading} classes={classes} message={message} />
          </Typography>
        </Button>

        {isLogin && (
          <Button
            variant="contained"
            color={"inherit"}
            style={styles.continueButton}
            className={classes.continueButton}
            onClick={() => {
              navigate(0);
            }}
          >
            <Typography
              component="p"
              color={palette?.[skin]?.text}
              textAlign="center"
              fontWeight={600}
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              textTransform="none"
            >
              <ButtonText loading={false} classes={classes} />
            </Typography>
          </Button>
        )}
      </Box>
    );
  } else {
    renderComponent = null;
  }

  return (
    <>
      <FailureMessage
        classes={classes}
        message={message}
        palette={palette}
        skin={skin}
        matchesSM={matchesSM}
      />
      <Grid style={{ marginBottom: 20 }}>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        {renderComponent}
      </Grid>
    </>
  );
};

export default Failure;
