import React, { createContext, useMemo, useState } from "react";

export const UserContextV2 = createContext({
  sessionToken: "",
  setSessionToken: (sessionToken: string) => {},
  userId: "",
  setUserId: (id: string) => {},
  documentId: "",
  setDocumentId: (documentId: string) => {},
  phoneNumber: "",
  setPhoneNumber: (phone: string) => {},
  email: "",
  setEmail: (email: string) => {},
  ssn: "",
  setSSN: (ssn: string) => {},
  uuid: "",
  setUUID: (uuid: string) => {},
  guid: "",
  setGUID: (guid: string) => {},
  healthCardOcrResult: "",
  setHealthCardOcrResult: (healthCardOcrResult: string) => {},
  passportOcrResult: "",
  setPassportOcrResult: (passportOcrResult: string) => {},
  barcodeData: "",
  setBarcodeData: (barcode: string) => {},

  enrollImageData: null,
  setEnrollImageData: (enrollImageData: any) => {},
  portraitConfScore: 0,
  setPortraitConfScore: (portraitConfScore: any) => {},

  additionalRequirements: [],
  setAdditionalRequirements: (additionalRequirements: any) => {},
});

const UserContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const [sessionToken, setSessionToken] = useState("");
  const [userId, setUserId] = useState("");
  const [documentId, setDocumentId] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [ssn, setSSN] = useState("");

  const [uuid, setUUID] = useState("");
  const [guid, setGUID] = useState("");

  const [healthCardOcrResult, setHealthCardOcrResult] = useState("");
  const [passportOcrResult, setPassportOcrResult] = useState("");
  const [barcodeData, setBarcodeData] = useState("");

  const [enrollImageData, setEnrollImageData] = useState(null);
  const [portraitConfScore, setPortraitConfScore] = useState(0);

  const [additionalRequirements, setAdditionalRequirements] = useState<[]>([]);

  const values = {
    sessionToken,
    setSessionToken,
    userId,
    setUserId,
    documentId,
    setDocumentId,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    ssn,
    setSSN,
    uuid,
    setUUID,
    guid,
    setGUID,
    healthCardOcrResult,
    setHealthCardOcrResult,
    passportOcrResult,
    setPassportOcrResult,
    barcodeData,
    setBarcodeData,
    enrollImageData,
    setEnrollImageData,
    portraitConfScore,
    setPortraitConfScore,
    additionalRequirements,
    setAdditionalRequirements,
  };
  const memoValues = useMemo(() => values, [values]);
  return (
    <UserContextV2.Provider value={memoValues}>
      {children}
    </UserContextV2.Provider>
  );
};

export default UserContextV2Provider;
