import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { theme, backgroundImages } from "./theme";
import "./App.css";
import router from "./routes";
// import bannerImage from "./assets/bankCard1.png";
import UserContextProvider from "./context/UserContext";
import { useSkinContext } from "./context/SkinContext";
import CvsRequestContextProvider from "./context/RequestContext";
import LoginContextProvider from "./context/LoginContext";
import { StepProvider } from "./context/StepContext";
import UserContextV2Provider from "./context/UserContextV2";
function App() {
  const { skin } = useSkinContext();
  const backgroundImage: { [key: string]: any } = backgroundImages;
  return (
    <CvsRequestContextProvider>
      <UserContextV2Provider>
        <UserContextProvider>
          <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={theme}>
              <StepProvider>
              <LoginContextProvider>
                <Helmet>
                  <link rel="preconnect" href="https://fonts.gstatic.com" />
                  <link
                    href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap"
                    rel="stylesheet"
                  />
                </Helmet>
                {backgroundImage?.[skin] && (
                  <img
                    src={backgroundImage?.[skin]}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                    className="googleBackground"
                  />
                )}
                <RouterProvider router={router} />
              </LoginContextProvider>
              </StepProvider>
            </ThemeProvider>
          </SnackbarProvider>
        </UserContextProvider>
      </UserContextV2Provider>
    </CvsRequestContextProvider>
  );
}

export default App;
