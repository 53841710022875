import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import STEPS from "../../pages/register/steps";
import ScanBackDocument from "../DocumentCamera/ScanBackDocument";
import shield from "../../assets/shield.png";
import { UserContext } from "../../context/UserContext";
import {
  closeCamera,
  createDocumentUploadIdTypeEnum,
  createDocumentUploadIdWithSession,
  documentImageTypeEnum,
  updateTypeEnum,
  updateUser,
  updateUserWithSession,
  uploadDL,
  uploadDocumentImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import { DLType } from "@privateid/cryptonets-web-sdk/dist/types";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SpinnerLoader from "../SpinnerLoader";
import FaceCompareFrontDocument from "../DocumentCamera/FaceCompareFrontDocument";
import { cameraDelay } from "../../utils";
import { ELEMENT_ID } from "../../constants";
import { BrowserInfo, detect } from "detect-browser";
import {
  federationGetHashData,
  updateDocumentUploadIdWithSession,
} from "../../services/api";
import { UserContextV2 } from "../../context/UserContextV2";

export enum DlActionEnum {
  frontscan = "frontscan",
  backscan = "backscan",
}

const DLFaceCompare = ({
  setStep,
  onSuccess,
  skin,
}: {
  setStep: any;
  onSuccess: () => void;
  skin: string;
}) => {
  const classes = useStyles();
  const [isUserVerify, setIsUserVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opStatus, setOpStatus] = useState<number>();
  const [documentId, setDocumentId] = useState<string>("");
  const context = useContext(UserContext);

  const userContextV2 = useContext(UserContextV2);

  const { id, enrollImageData, setPortraitConfScore, dlAction, setDlAction } =
    context;

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsScanningFailed(true);
  //   }, cameraDelay);
  //   setTimeout(() => setIsOverlay(true), 3500);
  // }, []);

  const onSuccessFrontScan = async (result: {
    croppedDocument: string;
    croppedMugshot: string;
    inputImage: string;
    portraitConfScore: number;
  }) => {
    const {
      inputImage,
      croppedDocument,
      croppedMugshot,
      portraitConfScore: compareScore,
    } = result;
    setIsLoading(true);
    // setIsScanningFailed(false);
    setPortraitConfScore(compareScore);

    // setFrontImageObject({
    //   frontOriginal:inputImage,
    //   frontCropped:croppedDocument,
    //   frontMugshot:croppedMugshot,
    // });

    const payload = {
      sessionToken: userContextV2.sessionToken,
      portrait_conf_score: compareScore.toFixed(2).toString(),
      updateType: updateTypeEnum.compare,
    };

    const documentId = await createDocumentUploadIdWithSession({
      documentType: createDocumentUploadIdTypeEnum.drivers_license,
      sessionToken: userContextV2.sessionToken,
    });
    setDocumentId(documentId.id);

    async function uploadAllImagesAndUpdateUser() {
      const promises = [
        uploadDocumentImageWithSession({
          sessionToken: userContextV2.sessionToken,
          documentImageType: documentImageTypeEnum.FRONT,
          documentId: documentId.id,
          imageString: inputImage,
        }),
        uploadDocumentImageWithSession({
          sessionToken: userContextV2.sessionToken,
          documentImageType: documentImageTypeEnum.FRONT_CROPPED_DOCUMENT,
          documentId: documentId.id,
          imageString: croppedDocument,
        }),
        uploadDocumentImageWithSession({
          sessionToken: userContextV2.sessionToken,
          documentImageType: documentImageTypeEnum.FRONT_MUGSHOT,
          documentId: documentId.id,
          imageString: croppedMugshot,
        }),
        updateUserWithSession(payload),
      ];

      await Promise.all(promises);
    }

    // Call the function to upload images and update user
    uploadAllImagesAndUpdateUser()
      .then(() => {
        console.log("All uploads and update completed successfully.");
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    await closeCamera(ELEMENT_ID);
    setTimeout(() => {
      setIsUserVerify(true);
    }, 2000);
    setTimeout(() => {
      setIsLoading(false);
      setIsUserVerify(false);
      // setIsOverlay(false);
      // setTimeout(() => setIsOverlay(true), 3500);
      setDlAction(DlActionEnum.backscan);
      setStep(STEPS.DRIVERLICENSE);
      // setTimeout(() => {
      //   setIsScanningFailed(true);
      // }, cameraDelay);
    }, 4000);
    // }
  };

  const onBackSuccess = async ({
    barcodeData,
    croppedDocument,
    croppedBarcode,
  }: {
    barcodeData: any;
    croppedDocument: string;
    croppedBarcode: string;
  }) => {
    if (isLoading) return;
    setIsLoading(true);
    // setIsScanningFailed(false);

    userContextV2.setBarcodeData(barcodeData);
    // uploadV2
    async function uploadDocumentAndUpdateImages() {
      const promises = [];

      promises.push(
        updateDocumentUploadIdWithSession({
          documentId: documentId,
          sessionToken: userContextV2.sessionToken,
          content: JSON.stringify(barcodeData),
        })
      );

      if (croppedDocument) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: userContextV2.sessionToken,
            documentImageType: documentImageTypeEnum.BACK_CROPPED_DOCUMENT,
            documentId: documentId,
            imageString: croppedDocument,
          })
        );
      }

      promises.push(
        uploadDocumentImageWithSession({
          sessionToken: userContextV2.sessionToken,
          documentImageType: documentImageTypeEnum.BACK_BARCODE,
          documentId: documentId,
          imageString: croppedBarcode,
        })
      );

      await Promise.all(promises);
    }

    uploadDocumentAndUpdateImages()
      .then(() => {
        console.log("All uploads and updates completed successfully.");
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    const year = barcodeData.dateOfBirth.slice(4);
    const day = barcodeData.dateOfBirth.slice(2, 4);
    const month = barcodeData.dateOfBirth.slice(0, 2);
    const dob = year + month + day;
    const federationHash = await federationGetHashData({
      guid: context.guid,
      govID: {
        firstName: barcodeData.firstName,
        lastName: barcodeData.lastName,
        dob: dob,
      },
      preferred: {
        ssn4: context.ssn4,
      },
    });

    const govId = {
      firstName: barcodeData.firstName,
      lastName: barcodeData.lastName,
      dob: barcodeData.dateOfBirth,
      address: {
        addressLine1: barcodeData.streetAddress1,
        addressLine2: barcodeData.streetAddress2,
        city: barcodeData.city,
        state: barcodeData.state,
        zipCode: barcodeData.postCode,
        country: barcodeData.issuingCountry,
        idDocumentNumber: barcodeData.customerId,
      },
    };

    console.log("govID", govId);

    setTimeout(() => {
      setIsUserVerify(true);
    }, 2000);
    setTimeout(() => {
      setIsUserVerify(false);

      onSuccess?.();
    }, 4000);
  };

  const onCameraNotGranted = (e: boolean) => {
    if (!e) {
      setStep(STEPS.CAMERA_PERMISSION_FAIL);
    }
  };

  const onCameraFail = async () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  const onCameraNotFullHd = async () => {
    await closeCamera(ELEMENT_ID);
    setStep(STEPS.SWITCH_DEVICE);
  };

  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;

  return (
    <>
      {/*{opStatus !== 0 && isScanningFailed && (*/}
      {/*  <Alert*/}
      {/*    severity="info"*/}
      {/*    onClick={async () => {*/}
      {/*      setStep(STEPS.SWITCH_DEVICE);*/}
      {/*      await closeCamera(ELEMENT_ID);*/}
      {/*    }}*/}
      {/*    className={classes.alertWrap}*/}
      {/*  >*/}
      {/*    You can try switching to other device.*/}
      {/*  </Alert>*/}
      {/*)}*/}
      <Grid
        style={styles.cardGrid}
        className={`cardGridMobile overflowUnset ${classes.cardSxMobile}`}
      >
        <Box position={"relative"}>
          <Box position={"relative"}>
            {isLoading && (
              <Box style={styles.overlay as React.CSSProperties}>
                {isUserVerify ? (
                  <img
                    src={shield}
                    alt="shield"
                    style={styles.shield as React.CSSProperties}
                  />
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            )}

            {dlAction === DlActionEnum.backscan ? (
              <ScanBackDocument
                onSuccess={onBackSuccess}
                onReadyCallback={onCameraNotGranted}
                onCameraFail={onCameraFail}
                onCameraNotFullHd={onCameraNotFullHd}
                setStep={setStep}
              />
            ) : (
              <FaceCompareFrontDocument
                onSuccess={onSuccessFrontScan}
                onReadyCallback={onCameraNotGranted}
                onFailCallback={(e) => null}
                onCameraFail={onCameraFail}
                enrollImageData={enrollImageData}
                setStep={setStep}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Box style={{ height: 84 }} className={classes.scanBottomBox}>
        {os !== "iOS" && os !== "Android OS" && (
          <Box className={classes.otherDevice} pl={3} mb={1}>
            <Typography
              component="p"
              textAlign={"left"}
              fontSize={15}
              fontWeight={500}
              mt={0}
              onClick={() => {
                setStep(STEPS.SWITCH_DEVICE);
              }}
            >
              <PhoneIphoneIcon /> Switch to Mobile Device
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DLFaceCompare;
