import { makeStyles } from "@mui/styles";
import { localThemes, theme as Theme } from "../../theme";

const mainTheme = Theme;
const palette: { [key: string]: any } = mainTheme.palette;
const skin = localThemes?.includes(window?.location?.search?.split("skin=")[1])
  ? window?.location?.search?.split("skin=")[1]
  : "primary";
export const styles = {
  appBar: { zIndex: 9999999, backgroundColor: "skyblue" },
  loginButton: { marginRight: 10 },
  homelogo: { marginLeft: 20, marginRight: 20 },
  homeBar: {
    backgroundColor: "#fff",
    borderBottom: "1px solid rgb(118 115 115)",
    boxShadow: "none",
  },
  signupButton: {
    background: "transparent",
    boxShadow: "unset",
    border: "2px solid rgb(100 98 98)",
    marginRight: 5,
    minWidth: "100px",
    lineHeight: 1.25,
    marginBottom: 0,
  },
};

export const useStyles = makeStyles((theme: any) => {
  const isHomePage = window.location.pathname === '/'
  return {
    dropdownWrap: {
      position: "absolute",
      right: "0px",
      top: "32px",
      backgroundColor: "#fff",
      width: "150px",
      display: "none",
      "& .AgeItem": {
        color: "#000",
        textAlign: "left",
        justifyContent: "flex-start",
        borderBottom: "1px solid #000",
        padding: "10px 20px",
      },
      "&:hover": {
        display: "block",
      },
    },
    headerButton: {
      color: `${palette[skin]?.text} !important`,
      backgroundColor: `${palette[skin]?.cvs} !important`,
      border: `2px solid ${palette[skin]?.cvs} !important`,
      "&:hover > div": {
        display: "block",
      },
      "&:hover": {
        backgroundColor: "transparent !important",
        color: `${palette[skin]?.cvs} !important`,
      },
      [theme.breakpoints.between("xs", "sm")]: {
        padding: "4px 0px !important",
        minWidth: "70px !important",
      },
    },
    headerButtonMobile: {
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: 20,
        backgroundColor: `${palette[skin]?.cvs} !important`,
        margin: "0 20px 5px 0",
        minWidth: 300,
        border: `3px solid ${palette[skin]?.cvs} !important`,
        color: `white !important`,
      },
    },
    signOutButton: {
      color: `${palette[skin]?.text} !important`,
      backgroundColor: `#c00 !important`,
      border: `2px solid #c00 !important`,
      height: "40px",
      "&:hover > div": {
        display: "block",
      },
      "& svg": {
       marginLeft: "10px",
      },
      "&:hover": {
        backgroundColor: "transparent !important",
        color: `${palette[skin]?.cvs} !important`,
      },
      [theme.breakpoints.between("xs", "sm")]: {
        padding: "4px 0px !important",
        minWidth: "70px !important",
      },
    },
    deleteButton: {
      width: "180px",
    },
    signOutButtonMobile: {
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: 20,
        backgroundColor: `#c00 !important`,
        margin: "0 20px 5px 0",
        minWidth: 300,
        border: `3px solid #c00 !important`,
        color: `white !important`,
      },
    },
    scanLoader: {
      width: "16px !important",
      height: "16px !important",
      marginBottom: "2px",
      marginRight: "0px",
      color: `${palette[skin]?.text} !important`,
      [theme.breakpoints.between("xs", "sm")]: {
        color: `${palette[skin]?.text} !important`,
      },
    },
    headerWrap: {
      "@media (orientation: landscape)": {
        [theme.breakpoints.down(1024)]: {
          // display: isHomePage ? "block" : "none",
        },
      },
    },
  };
});
