import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styles, useStyles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import STEPS from "../../pages/register/steps";

import React from "react";
import { useNavigate } from "react-router";
import CvsButton from "../Button";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

interface ValuesYourPrivacyProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  setPrevStep: (e: string) => void;
}

const ValuesYourPrivacy = (props: ValuesYourPrivacyProps) => {
  const { skin, setStep } = props;
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const classes = useStyles();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={700}
          lineHeight={1.5}
          mt={3}
          mb={2}
          className={classes.cardInnerHeading}
        >
          Identity verification Values Your Privacy
        </Typography>
        <Card className={classes.consentCard}>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
            }}
          >
            <ListItem className={classes.listText}>
              Your selfie will be processed by a neural network running in your
              browser that compares your face to your Photo ID in a few
              milliseconds. This process is fast and accurate, and does not keep
              or share your selfie.
            </ListItem>
            <ListItem className={classes.listText}>
              Your biometric data is only captured and processed on your device,
              and is deleted within one second. Private Identity never
              transmits, receives, stores, manages or discloses your biometric
              data.
            </ListItem>
            <ListItem className={classes.listText}>
              Only encrypted data is sent to the identity verification servers. Identity verification then confirms
              with third-party partners whether or not you have reached the
              required identity assurance threshold.
            </ListItem>
          </List>
        </Card>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <CvsButton
        title={"I'm ready"}
        onClick={() => setStep(STEPS.REGISTER_FORM)}
        skin={skin}
      />
      <CvsButton
        title={"No, I do not consent"}
        onClick={() => navigateWithQueryParams("/")}
        skin={skin}
      />
    </>
  );
};

export default ValuesYourPrivacy;
