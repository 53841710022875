import { Button, Divider, Grid, Link, Typography } from "@mui/material";

import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";

import heartFace from "../../assets/heart-check.png";
import STEPS from "../../pages/register/steps";

const Start = ({
  setStep,
  setPrevStep,
  skin,
  matchesSM,
}: {
  setStep: any;
  setPrevStep: any;
  skin: string;
  matchesSM: boolean;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        className={classes.cardHeaderWrap}
      >
        <Typography
          component="p"
          textAlign="center"
          fontSize={35}
          fontWeight={600}
          letterSpacing={"1px"}
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
            display: "flex",
            paddingRight: matchesSM ? "0px" : "10px",
            alignItems: "center",
          }}
          className={classes.cardHeading}
        >
          GET YOUR GAME <br /> FACE ON
          <img
            src={heartFace}
            alt=""
            width={matchesSM ? "80px" : "150px"}
            height={matchesSM ? "75px" : "140px"}
            style={{
              marginLeft: 15,
            }}
          />
        </Typography>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.backgroundRed} />}
      <Grid p={"0px 20px"} pb={"10px"} className={classes.firstGrid}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={700}
          mt={2}
          className={classes.cardInnerHeading}
        >
          Identity verification needs to verify your identity
          <br /> to keep your account safe and secure.
        </Typography>
        <Typography
          component="p"
          textAlign={matchesSM ? "center" : "left"}
          fontSize={14}
          fontWeight={900}
          mt={3}
          color={"#333"}
          className={classes.cardInnerText}
        >
          How will identity verification verify my identity?
        </Typography>
        <Typography
          component="p"
          textAlign={matchesSM ? "center" : "left"}
          fontSize={12}
          fontWeight={500}
          mt={0}
          className={classes.cardInnerText}
        >
          Identity verification will use a selfie, photo ID, and other personal
          identifiable information to verify your identity. Government agencies
          as well as other third parties may be used in the verification
          process. Data will strictly be processed according to the Privacy
          Policy.
        </Typography>
        <Typography
          component="p"
          fontSize={12}
          fontWeight={500}
          mt={1}
          // sx={{ lineHeight: 1.5, opacity: 0.8 }}
          overflow={"auto"}
        >
          Click{" "}
          <Link
            onClick={() => setStep(STEPS.REGISTER_CONSENT)}
            sx={{ cursor: "pointer" }}
          >
            here
          </Link>{" "}
          to learn how identity verification works.
        </Typography>
        <Typography
          component="p"
          textAlign={matchesSM ? "center" : "left"}
          fontSize={12}
          fontWeight={500}
          mt={2}
          className={classes.cardInnerText}
          mb={1}
        >
          By clicking the “Agree and continue” you acknowledge that you are over
          eighteen (18) years of age, you have read the Privacy Policy and Terms
          of Use, and you consent to Identity verification collection, use, and storage of your
          biometric data.
        </Typography>
        <Typography
          component="p"
          textAlign={matchesSM ? "center" : "left"}
          fontSize={12}
          fontWeight={500}
          mt={1}
          className={classes.cardInnerText}
          mb={1}
        >
          If you have questions, please call Customer Care at 1-888-607-4287.
        </Typography>
      </Grid>
      <Grid>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => setStep(STEPS.PRIVACY_CONSENT)}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="capitalize"
            fontSize={14}
          >
            Agree and continue
          </Typography>
        </Button>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => {
            setStep(STEPS.CONSENT_FAIL);
            setPrevStep(STEPS.START);
          }}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="capitalize"
            fontSize={14}
          >
            No, I do not consent
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default Start;
