import * as React from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import NoHat from "../../assets/no-hat.png";
import GoodLighting from "../../assets/good-lighting.png";
import NoGlasses from "../../assets/glass-selfie.png";
import NoMask from "../../assets/no-mask.png";
import { useInterval } from "../../utils/useInterval";
import { UserContext } from "../../context/UserContext";
import { useMediaQuery, useTheme } from "@mui/material";
import Passport from "../../assets/passport.png";
import DlFront from "../../assets/front-dl.png";
import DlBack from "../../assets/back-dl.png";
import { DlActionEnum } from "../DLScanning/DLFaceCompare";

const steps = [
  {
    icon: NoHat,
    label: `Remove Hat`,
  },
  {
    label: "Ensure good lighting",
    icon: GoodLighting,
  },
  {
    label: "Remove glasses for selfie",
    icon: NoGlasses,
  },
  {
    label: "Take off facemask",
    icon: NoMask,
  },
];

const passportStep = [
  {
    icon: Passport,
    label: `Please show the front page of your Passport`,
  },
];

const documentStep = [
  {
    icon: DlFront,
    label: `Please show the front of your Driver’s License`,
  },
];

const backDocumentStep = [
  {
    icon: DlBack,
    label: `Please show the back of your Driver’s License`,
  },
];

export default function InstructionsStepper({
  finishInstructionSlider,
  isDocumentScan,
  isPassport,
}: {
  finishInstructionSlider: () => void;
  isDocumentScan: boolean;
  isPassport: boolean;
}) {
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const context = React.useContext(UserContext);
  const { dlAction } = context;
  const interval = isDocumentScan ? 3500 : 2000;
  const [activeStep, setActiveStep] = React.useState(0);
  let maxSteps: any;
  if (isDocumentScan) {
    if (dlAction === DlActionEnum.backscan) {
      maxSteps = backDocumentStep?.length;
    } else {
      maxSteps = documentStep?.length;
    }
  } else {
    maxSteps = steps.length;
  }
  useInterval(() => {
    if (maxSteps === 1) {
      finishInstructionSlider();
    } else {
      setActiveStep(
        (prevActiveStep) => (prevActiveStep === 3 ? -1 : prevActiveStep) + 1
      );
    }
  }, interval);

  React.useEffect(() => {
    if (activeStep === maxSteps - 1 && maxSteps > 3) {
      finishInstructionSlider();
    }
  }, [activeStep]);

  let step;

  if (isPassport) {
    step = passportStep;
  } else if (isDocumentScan) {
    if (dlAction === DlActionEnum.backscan) {
      step = backDocumentStep;
    } else {
      step = documentStep;
    }
  } else {
    step = steps;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      sx={{
        // height: 255,
        p: 2,
        maxWidth: 400,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {step[activeStep]?.icon && (
        <img
          style={{ width: "100px" }}
          src={step[activeStep]?.icon}
          alt={"active-step"}
        />
      )}
      <Typography
        variant="h4"
        color={"#262626"}
        ml={matchesSM ? 2 : 0}
        fontSize={matchesSM ? "24px" : "1.7rem"}
        textAlign={"center"}
        width={matchesSM ? "100%" : 300}
      >
        {step[activeStep]?.label}
      </Typography>
      <MobileStepper
        variant="progress"
        sx={{ backgroundColor: "#fff" }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
    </Box>
  );
}
