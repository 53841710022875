import { Box, Container, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useStyles } from "../../styles/styles";


const Terms = ({ skin }: any) => {
  const classes = useStyles();
  return (
    <Box>
      <a className={classes.skipToContentLink} href="#main">
        Skip to content
      </a>
      <Header theme={skin} />
      <Container maxWidth="md">
        <Box pt={10}>
          <Typography
            variant="h1"
            fontSize={32}
            fontWeight={400}
            mb={0}
            align={"center"}
            mt={3}
          >
            Terms of Service for the Private Identity® Estimate Age Service
          </Typography>
          <Typography
            fontSize={"0.9em"}
            align={"center"}
            fontWeight={400}
            mt={1}
          >
            Effective Date: May 1, 2023
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Welcome to Private Identity LLC’s Website, privateid.com (together
            with its subdomains, Content, Marks and services, the “Site”).
            Please read the following Terms of Use carefully before using the
            Services (defined below) so that you are aware of your legal rights
            and obligations with respect to Private Identity LLC (“Private
            Identity”, “we”, “our” or “us”). By accessing or using the Services,
            you expressly acknowledge and agree that you are entering a legal
            agreement with us and understand and agree to comply with, and be
            legally bound by, these Terms of Use, together with the Privacy
            Policy (the “Terms”). You hereby waive any applicable rights to
            require an original (non-electronic) signature or delivery or
            retention of non-electronic records, to the extent not prohibited
            under applicable law. If you do not agree to be bound by these Terms
            please do not access or use the Services.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            1. Background. Private Identity offers: (i) the Site, (ii) certain
            products and services including our age estimation algorithm, and
            (iii) certain authentication services to our customers and end-users
            (collectively, the “Services”).
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            2. Modification. We reserve the right, at our discretion, to change
            these Terms upon notice to you at any time in our discretion. Such
            change will be effective thirty (30) days following posting notice
            of the change and of the revised Terms on the Site or through the
            Services. Your continued use of the Services thereafter means that
            you accept those changes.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            3. Ability to Accept Terms. The Services are only intended for
            individuals aged fifteen (15) years or older. If you are under
            fifteen (15) years old please do not visit or use the Services.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            4. Access. For such time as these Terms are in effect, we hereby
            grant you permission to visit and use the Services provided that you
            comply with these Terms and applicable law. We reserve the right to
            terminate or suspend your access to the Services at any time, for
            any reason or no reason, with or without notice to you. You agree
            that we will not be liable for any loss or damage caused directly or
            indirectly, by any such termination or suspension.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            5. Restrictions. You shall not: (i) copy, distribute or modify any
            part of the Services without our prior written authorization; (ii)
            use, modify, create derivative works of, transfer (by sale, resale,
            license, sublicense, download or otherwise), reproduce, distribute,
            display or disclose Content (defined below), except as expressly
            authorized herein; (iii) disrupt servers or networks connected to
            the Services; (iv) use or launch any automated system (including
            without limitation, “robots” and “spiders”) to access the Services;
            and/or (v) circumvent, disable or otherwise interfere with
            security-related features of the Services or features that prevent
            or restrict use or copying of any Content or that enforce
            limitations on use of the Services.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            6. Intellectual Property Rights. Content, Estimate Age Service, and
            Marks. The (i) content offered through the Services, including
            without limitation, the text, documents, articles, brochures,
            descriptions, products, software, graphics, photos, sounds, videos,
            interactive features, and services (collectively, the “Content”),
            (ii) the Estimate Age service (“Estimation Tool”), and (iii) the
            trademarks, service marks and logos contained therein (“Marks”), are
            the property of Private Identity and/or its licensors and may be
            protected by applicable copyright or other intellectual property
            laws and treaties. Private Identity®, Private ID®, the Private
            Identity logo, and other marks are Marks of Private Identity. All
            other trademarks, service marks, and logos used as part of the
            Services are the trademarks, service marks, or logos of their
            respective owners. We reserve all rights not expressly granted in
            and to the Services and the Content.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Use of Content. Content offered through the Services is provided to
            you for your information and personal use only and may not be used,
            modified, copied, distributed, transmitted, broadcast, displayed,
            sold, licensed, de-compiled, or otherwise exploited for any other
            purposes whatsoever without our prior written consent. If you
            download or print a copy of the Content you must retain all
            copyright and other proprietary notices contained therein.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Information Description. We attempt to be as accurate as possible.
            However, we cannot and do not warrant that the Content offered by
            Private Identity is accurate, complete, reliable, current, or
            error-free. We reserve the right to make changes in or to the
            Content, or any part thereof, in our sole judgment, without the
            requirement of giving any notice prior to or after making such
            changes to the Content. Your use of the Content, or any part
            thereof, is made solely at your own risk and responsibility.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Services. Provided you comply with these Terms, Private Identity
            grants to you a limited, revocable, nonexclusive, non-assignable
            license to download the Private Identity MFA application onto a
            personal device and use the Private Identity MFA application for the
            sole purpose of accessing our authentication Services to enroll or
            authenticate yourself.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            7. Links The Services may contain links, and may enable you to post
            content, to third party websites (e.g., Google, YouTube) that are
            not owned or controlled by Private Identity. We are not affiliated
            with, have no control over, and assume no responsibility for the
            content, privacy policies, or practices of, any third party
            websites. You: (i) are solely responsible and liable for your use of
            and linking to any third party websites and any content that you may
            send or post to a third party website; and (ii) expressly release
            Private Identity from any and all liability arising from your use of
            any third party website. Accordingly, we encourage you to read the
            terms and conditions and privacy policy of each third party website
            that you may choose to visit or link to from the Sites.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Private Identity permits you to link to the Site provided that: (i)
            you link to but do not replicate any page on this Site; (ii) the
            hyperlink text shall accurately describe the Content as it appears
            on the Site; (iii) you shall not misrepresent your relationship with
            Private Identity or present any false information about Private
            Identity and shall not imply in any way that we are endorsing any
            services or products, unless we have given you our express prior
            consent; (iv) you shall not link from a website (“Third Party
            Website”) which prohibited linking to third parties; (v) such Third
            party Website does not contain content that (a) is offensive,
            defaming or controversial (both at our discretion), or (b) infringes
            any intellectual property, privacy rights, or other rights of any
            person or entity; (vi) you, and your website, comply with these
            Terms and applicable law; and (vii) you do not link to an inner page
            which is not accessible to unregistered or authorized users.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            8. Privacy. We will use any personal information that we may collect
            or obtain in connection with the Services in accordance with our
            Privacy Policy​. You agree that we may use personal information that
            you provide or make available to us in accordance with the Privacy
            Policy.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            9. Privacy Laws Obligations. Information from this Website does not
            constitute legal advice. Customers should seek their own independent
            legal advice.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            10. Anti-Fraud. You authorize your wireless operator (AT&T, Sprint,
            T-Mobile, US Cellular, Verizon, or any other branded wireless
            operator) to disclose your mobile number, name, address, email,
            network status, customer type, customer role, billing type, mobile
            device identifiers (IMSI and IMEI) and other subscriber status and
            device details, if available, to our third-party service provider,
            solely to verify your identity and prevent fraud for the duration of
            the business relationship. Refer to our Privacy Policy for how we
            treat your data.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            11. Warranty Disclaimers
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            This section applies whether or not your use of the Services are for
            payment. Applicable law may not allow the exclusion of certain
            warranties, so to that limited extent certain exclusions set forth
            herein may not apply.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
            AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED.
            PRIVATE IDENTITY HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR
            IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
            MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY
            OF DATA, NON-INFRINGEMENT, AND THOSE WARRANTIES ARISING BY STATUTE
            OR FROM A COURSE OF DEALING OR USAGE OF TRADE. PRIVATE IDENTITY DOES
            NOT GUARANTEE THAT THE SERVICES WILL BE FREE OF BUGS, SECURITY
            BREACHES, OR VIRUS ATTACKS. THE SERVICES MAY OCCASIONALLY BE
            UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR OTHER REASONS.
            YOU AGREE THAT PRIVATE IDENTITY WILL NOT BE HELD RESPONSIBLE FOR ANY
            CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT MAY RESULT FROM
            TECHNICAL PROBLEMS OF THE INTERNET, SLOW CONNECTIONS, TRAFFIC
            CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS. WE DO NOT WARRANT,
            ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT, OR SERVICE THAT IS
            FEATURED, LINKED TO OR ADVERTISED ON THE SITE BY A THIRD PARTY.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, PRIVATE IDENTITY
            DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY
            KIND, EXPRESS OR IMPLIED, AS TO THE SECURITY OF ANY INFORMATION YOU
            MAY PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF YOUR
            USE OF THE SERVICES.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            12. Limitation of Liability.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, PRIVATE IDENTITY SHALL NOT
            BE LIABLE FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL,
            CONSEQUENTIAL, OR INCIDENTAL DAMAGES OF ANY KIND, OR FOR ANY LOSS OF
            DATA, REVENUE, PROFITS OR REPUTATION, ARISING UNDER THESE TERMS OR
            OUT OF YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF
            PRIVATE IDENTITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
            OR LOSSES. Some jurisdictions do not allow the limitation or
            exclusion of liability for incidental or consequential damages, so
            the above limitations may not apply to you.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF PRIVATE IDENTITY FOR
            ANY DAMAGES ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR
            INABILITY TO USE, THE SERVICES EXCEED $100.00.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN
            BETWEEN YOU AND PRIVATE IDENTITY AND SHALL APPLY TO ALL CLAIMS OF
            LIABILITY (E.G., WARRANTY, TORT, NEGLIGENCE, CONTRACT, LAW) AND EVEN
            IF A PARTY HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND
            EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE. Some
            jurisdictions do not allow the limitation or exclusion of liability
            for incidental or consequential damages, so the above limitations
            may not apply to you.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            13. Indemnity. You agree to defend, indemnify and hold harmless
            Private Identity and our affiliates, and our respective officers,
            directors, employees and agents, from and against any and all
            claims, damages, obligations, losses, liabilities, costs and
            expenses (including but not limited to attorney’s fees) arising
            from: (i) your use of, or inability to use, the Services; (ii) your
            interaction with any Service user; or (iii) your violation of these
            Terms.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            14. Term and Termination. These Terms are effective until terminated
            by Private Identity. Private Identity, in its sole discretion, has
            the right to terminate these Terms and/or your access to the
            Services, or any part thereof, immediately at any time and with or
            without cause (including, without any limitation, for a breach of
            these Terms). Private Identity shall not be liable to you or any
            third party for the termination of the Services, or any part
            thereof. If you object to any term or condition of these Terms, or
            any subsequent modifications thereto, or become dissatisfied with
            the Services in any way, your only recourse is to immediately
            discontinue use of the Services. Upon termination of these Terms,
            you shall cease all use of the Services. This Section 12 and
            Sections 5 (Intellectual Property Rights), 8 (Privacy), 9 (Warranty
            Disclaimers), 10 (Limitation of Liability), 11 (Indemnity), 13
            (Independent Contractors) and 18 (General) shall survive.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            15. Independent Contractors. You and Private Identity are
            independent contractors. Nothing in these Terms creates a
            partnership, joint venture, agency, or employment relationship
            between you and Private Identity. You must not under any
            circumstances make, or undertake, any warranties, representations,
            commitments or obligations on behalf of Private Identity.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            16. Jurisdictional Issues. The Services are controlled and operated
            from our offices in the United States. We make no representation
            that Content and other materials available through the Services are
            appropriate or available for use in other locations. Those who chose
            to access the Services from other locations do so on their own
            initiative and are responsible for compliance with local laws, if
            and to the extent local laws are applicable.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            17. Assignment. These Terms, and any rights and licenses granted
            hereunder, may not be transferred or assigned by you but may be
            assigned by Private Identity without restriction or notification to
            you.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            18. Notice. Notices to you may be made via posting through the
            Services, on the Site, by email or by regular mail, in our
            discretion.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            19. Questions. If you have any questions, comments or complaints
            regarding these Terms of Use or the Services, please contact us at:
            info@private.id
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            pb={3}
            mt={2}
          >
            20. General. Private Identity reserves the right to discontinue or
            modify any aspect of the Services at any time. These Terms and the
            relationship between you and Private Identity shall be governed by
            and construed in accordance with the laws of New York (USA), without
            regard to its principles of conflict of laws. You agree to submit to
            the personal and exclusive jurisdiction of the courts located in New
            York and waive any jurisdictional, venue, or inconvenient forum
            objections to such courts, provided that Private Identity may seek
            injunctive relief in any court of competent jurisdiction. These
            Terms shall constitute the entire agreement between you and Private
            Identity concerning the Services. If any provision of these Terms is
            deemed invalid by a court of competent jurisdiction, the invalidity
            of such provision shall not affect the validity of the remaining
            provisions of these Terms, which shall remain in full force and
            effect. No waiver of any term of these Terms shall be deemed a
            further or continuing waiver of such term or any other term, and a
            party’s failure to assert any right or provision under these Terms
            shall not constitute a waiver of such right or provision. YOU AGREE
            THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED
            TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
            ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
            BARRED.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Terms;
