import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import shield from "../../assets/shield.png";
import { styles } from "../../pages/authenticate/styles";
import homeStyles from "../../styles/Home.module.css";
import { ERROR, getStatusFromUser, SUCCESS } from "../../utils";
import { getUser } from "../../services/api";
import HomeModal from "../../components/Modal/homeModal";
import useToast from "../../utils/useToast";
import Camera from "../../components/Camera";
import Success from "../../components/Success";
import Failure from "../../components/Failure";
import useFaceLoginWithLivenessCheck from "../../hooks/useFaceLoginWithLiveness";
import STEPS from "../../pages/register/steps";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FullWidthTabs from "../OptionsTab";
import { useStyles } from "../AuthenticateComponents/Login/styles";
import Feedback from "../Feedback";
import { LoginContext } from "../../context/LoginContext";
import { BrowserInfo, detect } from "detect-browser";
import {
  closeCamera
} from "@privateid/cryptonets-web-sdk";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_SUCCESSFUL,
  BASE_URL,
  ELEMENT_ID,
} from "../../constants";
import PreDocumentScan from "../AuthenticateComponents/PreDocumentScan";
import BackDocumentScanModular from "../AuthenticateComponents/BackDocumentScan";
import { UserContext } from "../../context/UserContext";
import VerifiedCredentialsAuthentication from "../AuthenticateComponents/VerifiedCredentialsAuthentication";
import IdentificationType from "../IdentificationType";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

let count = 0;

interface SignInWithMessageProps {
  theme?: string;
  skin: any;
  onModalVisible: (e: boolean) => void;
  withBarcode: boolean;
  withVerifiedCredentialsAuth: boolean;
  twoFactorAuthentication?: boolean;
  requestProof?: boolean;
}

const SignInWithMessage = ({
  skin,
  onModalVisible,
  withBarcode,
  withVerifiedCredentialsAuth,
  twoFactorAuthentication,
  requestProof,
}: SignInWithMessageProps) => {
  const { showToast } = useToast();
  const [isInitialPredict, setInitialPredict] = useState(!withBarcode);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(withBarcode ? STEPS.SIGN_IN : STEPS.INITIAL);
  const [status, setStatus] = useState<any>();
  const [userObj, setUserObj] = useState();
  const [loader, setLoader] = useState<boolean>(false);
  const [isUserVerify, setIsUserVerify] = useState(false);
  const [generatePasskey, setGenPasskey] = useState(false);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const retryTimes = step === STEPS.SIGN_IN ? 50 : 5;
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  const classes = useStyles();
  const loginContext = useContext(LoginContext);
  const context = useContext(UserContext);
  const { setDlAction } = context;

  const [guid, setGuid] = useState("");

  useEffect(() => {
    if (!isInitialPredict) {
      onModalVisible(!isInitialPredict);
    }
  }, [isInitialPredict]);

  useEffect(() => {
    if (user?._id) {
      closeCamera(ELEMENT_ID);
    }
  }, [user]);

  const handleFailure = async (message = "", systemMessage = "") => {
    loginContext.setErrorMessage(systemMessage || message);
    setMessage(message);
    count = 0;
    showToast(message, "error");
    await closeCamera(ELEMENT_ID);
    localStorage.removeItem("user");
    resetFaceLogin(false);
    setStep(STEPS.FAILURE);
  };

  const handleSuccess = () => {
    const tempUser = JSON.parse(localStorage.getItem("tempUser") ?? "{}");
    if (userObj || tempUser._id) {
      localStorage.setItem("user", JSON.stringify(tempUser || userObj || {}));
      localStorage.removeItem("tempUser");
    }
    setTimeout(() => {
      requestProof ? navigateWithQueryParams("/view-presentation") : setStep(STEPS.SUCCESS);
    }, 300);
    setMessage(AUTHENTICATION_SUCCESSFUL);
    showToast(AUTHENTICATION_SUCCESSFUL, "success");
    loginContext.setPredictedGUID(guid);
  };

  const nextStep = async (userData: any) => {
    const user =
      userData?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
    if (!user._id) return;
    const userStatus = getStatusFromUser(userData.status);
    closeCamera(ELEMENT_ID);
    setInitialPredict(false);
    if (userStatus === SUCCESS) {
      setGuid(user?.guid);
      if (twoFactorAuthentication) {
        onPredictSuccess(false);
      } else if (withBarcode) {
        setDlAction("backscan");
        setStep(STEPS.BACK_DOCUMENT_SCAN);
      } else if (withVerifiedCredentialsAuth) {
        setStep(STEPS.VERIFIED_CREDENTIALS_AUTH);
      } else {
        setIsUserVerify(false);
        handleSuccess();
      }
    } else {
      handleFailure(ACCOUNT_NOT_APPROVED);
    }
  };

  const onSetStatus = (e: number) => {};

  const onFeedback = () => {
    setStep(STEPS.FEEDBACK);
  };

  const handlePredictSuccess = async (result: any) => {
    if (result?.status === 0) {
      localStorage.setItem("uuid", JSON.stringify(result?.puid || {}));
      const payload = {
        guid: result?.PI?.guid,
        uuid: result?.puid,
      };
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR) {
        handleFailure(AUTHENTICATION_FAILED);
      } else {
        setIsUserVerify(true);
        if (
          !twoFactorAuthentication &&
          !withBarcode &&
          !withVerifiedCredentialsAuth
        ) {
          localStorage.setItem("user", JSON.stringify(data.user || {}));
        } else {
          setUserObj(data);
          localStorage.setItem("tempUser", JSON.stringify(data.user || {}));
        }
        nextStep(data);
      }
    } else {
      if (step === STEPS.INITIAL) {
        setInitialPredict(false);

        setStep(STEPS.SIGN_IN);
      } else {
        handleFailure(AUTHENTICATION_FAILED);
      }
    }
  };

  const handleEnrollSuccess = async () => {
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    const response = await fetch(BASE_URL + "/generate-registration-options", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid }), // body data type must match "Content-Type" header
    });
    let attResp;
    try {
      const opts = await response.json();
      attResp = await startRegistration(opts);
    } catch (error: any) {
      return;
    }

    const verificationResp = await fetch(BASE_URL + "/verify-registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ data: attResp, uuid: uuid }),
    });

    const verificationJSON = await verificationResp.json();
    if (verificationJSON?.verified) {
      showToast("Passkey successfully generated", "success");
    }
  };

  // Predict ONEFA
  const onPredictSuccess = async (generatePass: boolean) => {
    if (twoFactorAuthentication) {
      setLoader(true);
    }
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    try {
      const response = await fetch(
        BASE_URL + "/generate-authentication-options",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ uuid }), // body data type must match "Content-Type" header
        }
      );
      let asseResp;

      const opts = await response.json();

      if (
        opts.success === false &&
        twoFactorAuthentication &&
        !(generatePass || generatePasskey)
      ) {
        setLoader(false);
        setStep(STEPS.IDENTIFICATION_TYPE);
        return showToast("Passkey not found", "error");
      } else if (opts.success === false && (generatePass || generatePasskey)) {
        setLoader(false);
        return handleEnrollSuccess();
      }

      asseResp = await startAuthentication(opts);

      const verificationResp = await fetch(
        BASE_URL + "/verify-authentication",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ data: asseResp, uuid }),
        }
      );

      const verificationJSON = await verificationResp.json();
      if (verificationJSON?.verified) {
        showToast("Passkey successfully verified", "success");
        if (twoFactorAuthentication) {
          handleSuccess();
        }
      }
    } catch (error) {
      console.log(320, { error });
      showToast(
        "There was some error authenticating with passkey. Please try again.",
        "error"
      );
      return navigateWithQueryParams("/");
    }
  };

  const onClose = async () => {
    await closeCamera(ELEMENT_ID);
    setTimeout(() => {
      navigateWithQueryParams("/");
    }, 50);
  };

  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginInputImageData,
    resetFaceLogin,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onSetStatus,
    retryTimes,
    isInitialPredict
  );

  useEffect(() => {
    if (faceLoginData) {
      if (faceLoginData.status === 0) {
        count = 0;
        handlePredictSuccess(faceLoginData);
      } else {
        if (faceLoginData.retryComplete || count >= 50) {
          const ErrorMessage = faceLoginData.message;
          handleFailure(AUTHENTICATION_FAILED, ErrorMessage);
        } else {
          if (count === 3 && step === STEPS.INITIAL) {
            setStep(STEPS.SIGN_IN);
            setInitialPredict(false);
          } else {
            count++;
            resetFaceLogin();
          }
        }
      }
    } else if (
      isInitialPredict ||
      (!isInitialPredict && faceLoginData?.retryComplete)
    ) {
      handlePredictSuccess(faceLoginData);
    }
  }, [
    faceLoginData?.retryComplete,
    faceLoginData?.status,
  ]);

  const onSwitchDevice = () => setStep(STEPS.SWITCH_DEVICE);
  const _renderChildren = () => {
    switch (step) {
      case STEPS.SIGN_IN:
        return (
          <Box position={"relative"} padding={"10px 10px"} pr={"12px"}>
            {isUserVerify && (
              <Box
                style={
                  {
                    ...styles.overlayCamera,
                    top: matchesSM ? 46 : "56px",
                    height: matchesSM ? "80%" : "72%",
                  } as React.CSSProperties
                }
              >
                <img
                  src={shield}
                  alt="shield"
                  style={styles.shield as React.CSSProperties}
                />
              </Box>
            )}
            <div id="canvasInput" className={homeStyles.container}>
              <Camera
                onReadyCallback={faceLoginWithLiveness}
                onSwitchCamera={faceLoginWithLiveness}
                message={faceLoginWithLivenessMessage}
                canvasLayout={true}
                setStep={setStep}
              ></Camera>
            </div>
            {os !== "iOS" && os !== "Android OS" && (
              <Box className={classes.otherOptions}>
                <Typography
                  component="p"
                  textAlign={matchesSM ? "center" : "left"}
                  fontSize={15}
                  fontWeight={500}
                  mt={2}
                  onClick={async () => {
                    await closeCamera(ELEMENT_ID);
                    setTimeout(() => {
                      setStep(STEPS.SWITCH_DEVICE);
                    }, 50);
                  }}
                >
                  <PhoneIphoneIcon /> Switch to Mobile Device
                </Typography>
              </Box>
            )}
            <Box>
              <Typography
                align="center"
                fontSize={14}
                fontWeight={700}
                mt={1}
                mb={1}
              >
                Please look at the camera to login
              </Typography>
            </Box>
          </Box>
        );
      case STEPS.SUCCESS:
        return (
          <Success
            matchesSM={matchesSM}
            skin={skin}
            message={message}
            fromLogin={true}
            setStep={setStep}
          />
        );
      case STEPS.FAILURE:
        return (
          <Failure
            matchesSM={matchesSM}
            skin={skin}
            message={message}
            isLogin={true}
            setStep={setStep}
          />
        );
      case STEPS.SWITCH_DEVICE:
        return <FullWidthTabs setStep={setStep} stopApi={true} />;
      case STEPS.FEEDBACK:
        return (
          <Feedback
            matchesSM={matchesSM}
            setStep={() => setStep(STEPS.SIGN_IN)}
            skin={skin}
          />
        );
      case STEPS.PRE_DOCUMENT_SCAN:
        return <PreDocumentScan skin={skin} setStep={setStep} />;
      case STEPS.BACK_DOCUMENT_SCAN:
        return (
          <BackDocumentScanModular
            onSuccess={() => {
              // onPredictSuccess(true);
              handleSuccess();
            }}
            onSwitchDevice={onSwitchDevice}
          />
        );

      case STEPS.VERIFIED_CREDENTIALS_AUTH:
        return (
          <VerifiedCredentialsAuthentication
            setStep={setStep}
            skin={skin}
            checked={generatePasskey}
            handleChange={setGenPasskey}
            handleFailure={handleFailure}
            handleSuccess={() => {
              onPredictSuccess(true);
              handleSuccess();
            }}
          />
        );
      case STEPS.IDENTIFICATION_TYPE:
        return (
          <IdentificationType
            setStep={setStep}
            setDlAction={setDlAction}
            requestProof={requestProof}
            onPredictSuccess={onPredictSuccess}
            loader={loader}
            skin={skin}
          />
        );
      default:
        return (
          <Box
            position={"relative"}
            textAlign={"center"}
            padding={"10px 10px"}
            mt={3}
            pr={"12px"}
          >
            <CircularProgress />
          </Box>
        );
    }
  };

  return (
    <>
      <div>
        {isInitialPredict && (
          <div
            id="canvasInput"
            className={homeStyles.container}
            style={{ display: "none" }}
          >
            <Camera
              onReadyCallback={faceLoginWithLiveness}
              onSwitchCamera={faceLoginWithLiveness}
            ></Camera>
          </div>
        )}
        <HomeModal
          showFeedback={true}
          onFeedback={onFeedback}
          handleClose={onClose}
          open={!isInitialPredict}
          success={step === STEPS.SUCCESS}
          failure={step === STEPS.FAILURE}
        >
          {_renderChildren()}
        </HomeModal>
      </div>
    </>
  );
};

export default SignInWithMessage;
