import { Box, Typography } from "@mui/material";
import { useStyles } from "../../../styles/styles";
import DLAnimationGif from "../../../assets/international-drivers-license.gif";
import { useContext, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import STEPS from "../../../pages/register/steps";

const DLAnimation = ({
  setStep,
  matchesSM,
}: {
  setStep: any;
  matchesSM: boolean;
}) => {
  const classes = useStyles();
  const context = useContext(UserContext);

  enum DlActionEnum {
    frontscan = "frontscan",
    backscan = "backscan",
  }

  const { setDlAction } = context;
  useEffect(() => {
    let timer1 = setTimeout(() => {
      setDlAction(DlActionEnum.backscan);
      setStep(STEPS.DRIVERLICENSE);
    }, 5000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <img
        src={DLAnimationGif}
        style={{ width: matchesSM ? "100%" : "75%", margin: "0 auto" }}
        alt={"dl-animation"}
      />
      <Box
        sx={{
          mb: "-3px",
        }}
      >
        <Typography
          component="p"
          textAlign={"center"}
          color={"#262626"}
          fontSize={16}
          fontWeight={700}
          lineHeight={1.5}
          pt={3}
          pb={3}
          className={classes.cardInnerHeading}
          px={10}
        >
          Turn over ID card.
        </Typography>
      </Box>
    </Box>
  );
};

export default DLAnimation;
