import { Button, Typography } from "@mui/material";
import { styles, useStyles } from "../../styles/styles";
import { theme as Theme } from "../../theme";

interface ButtonProps {
  onClick: (e?: any) => void;
  skin: string;
  title: any;
  disabled?: boolean;
  textTransform?: any;
  inverted?:boolean;
}

const CvsButton = (props: ButtonProps) => {
  const classes = useStyles();
  const {
    skin,
    onClick,
    title,
    disabled,
    textTransform = "capitalize",
    inverted
  } = props;
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  return (
    <>
      <Button
        variant="contained"
        color={"inherit"}
        style={inverted? styles.continueButtonInverted :styles.continueButton}
        className={inverted? classes.continueButtonInverted :classes.continueButton}
        onClick={onClick}
        type="submit"
        disabled={disabled}
      >
        <Typography
          component="p"
          color={inverted? palette?.[skin]?.textBlack: palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform={textTransform}
          fontSize={14}
        >
          {title}
        </Typography>
      </Button>
    </>
  );
};

export default CvsButton;
