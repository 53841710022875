//@ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Divider,
  TextField,
  Stack,
} from "@mui/material";

import HomeModal from "../../components/Modal/homeModal";
import Header from "../../components/Header";
import { useNavigate } from "react-router";
import { styles, useStyles } from "../../styles/styles";
import CvsButton from "../../components/Button";
import { theme as Theme } from "../../theme";
import Login from "../../components/AuthenticateComponents/Login";
import { FederationContext } from "../../context/FederationContext";
import { federationCompareHash, getUser } from "../../services/api";
import { closeCamera } from "@privateid/cryptonets-web-sdk";

interface ForgetMePageProps {
  theme?: string;
  skin: string;
}

const FederationHash = ({ theme, skin }: ForgetMePageProps) => {
  const navigate = useNavigate();
  const steps = ["Face Login", "User Data", "Compare Hash Result"];
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const themeName = skin || "primary";
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [userDetails, setUserDetails] = useState(null);
  const [compareHashPayload, setCompareHashPayload] = useState(null);
  const [hashedData, setHashedData] = useState(null);

  const renderChild = (index: number) => {
    switch (index) {
      case 0:
        return (
          <>
            <Step1
              skin={skin}
              nextStep={handleNext}
              setUserDetails={setUserDetails}
              setHashedData={setHashedData}
            />
          </>
        );
      case 1:
        return (
          <>
            <Step2
              setPayload={setCompareHashPayload}
              userDetails={userDetails}
              nextStep={handleNext}
              skin={skin}
              classes={classes}
            />
          </>
        );
      case 2:
        return (
          <>
            <Step3 userData={compareHashPayload} federationHash={hashedData} />
          </>
        );
      default:
        return;
    }
  };

  return (
    <>
      {<Header theme={themeName} />}
      <div className="homePageWrapper">
        <HomeModal
          handleClose={() => {
            navigate("/");
          }}
          open={true}
          onFeedback={() => {}}
          showFeedback={false}
        >
          <Grid
            container
            alignItems={"center"}
            flexDirection={"column"}
            style={styles.cardGrid}
            className={classes.cardGridMobile}
            sx={{
              height: matchesSM ? "calc(100% - 53px)" : "500px",
            }}
          >
            <Box sx={{ width: "100%", pt: 2 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  {renderChild(activeStep)}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                  {renderChild(activeStep)}
                  <Box
                    sx={{
                      display:
                        activeStep === steps.length - 1 ? "flex" : "none",
                      flexDirection: "row",
                      pt: 2,
                    }}
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Grid>
          {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
          <CvsButton
            skin={skin}
            title="Back to homepage"
            onClick={() => {
              navigate("/");
            }}
          />
        </HomeModal>
      </div>
    </>
  );
};

const Step1 = ({
  skin,
  nextStep = () => {},
  setUserDetails,
  setHashedData,
}: {
  skin: string;
  nextStep: any;
  setUserDetails: any;
  setHashedData: any;
}) => {
  const fedrationContext = useContext(FederationContext);

  const onSuccess = async (loginData: any) => {
    fedrationContext.setGUID(loginData?.PI?.guid);
    const payload = {
      guid: loginData?.PI?.guid,
    };
    const result: any = await getUser(payload);
    await closeCamera();
    setUserDetails(result);
    setHashedData(result.federationHash || "");
    nextStep();
  };
  return (
    <>
      <Login
        onFailure={() => {}}
        onSuccess={onSuccess}
        setStep={() => {}}
        skin={skin}
        onSwitchDevice={() => {}}
      />
    </>
  );
};

const Step2 = ({
  setPayload,
  userDetails,
  nextStep,
  skin,
  classes,
}: {
  setPayload: any;
  userDetails: any;
  nextStep: any;
  skin: string;
  classes: string;
}) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const ssn4Ref = useRef();
  const guidRef = useRef();

  //DOB
  const yearRef = useRef();
  const monthRef = useRef();
  const dayRef = useRef();
  useEffect(() => {
    firstNameRef.current.value =
      userDetails?.govId?.firstName || userDetails?.preferred?.firstName || "";
    lastNameRef.current.value =
      userDetails?.govId?.lastName || userDetails?.preferred?.lastName || "";
    ssn4Ref.current.value = userDetails?.ssn4 || "";
    const dob = userDetails?.govId?.dob || userDetails?.preferred?.dob || "";
    const year = dob?.slice(4);
    const day = dob?.slice(2, 4);
    const month = dob?.slice(0, 2);
    yearRef.current.value = year;
    monthRef.current.value = month;
    dayRef.current.value = day;
    guidRef.current.value = userDetails?.guid;
  }, []);

  const continueAndCreatePayload = () => {
    const userData = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      dob:
        yearRef.current.value + monthRef.current.value + dayRef.current.value,
      guid: guidRef.current.value,
      ssn4: ssn4Ref.current.value,
    };
    setPayload(userData);
    nextStep();
  };
  return (
    <>
      <Stack
        gap={2}
        sx={{ p: 2, mt: 2, pt: "15px !important" }}
        style={styles.cardGrid}
        className={classes.cardGridMobile}
      >
        <TextField
          fullWidth
          id="outlined-basic"
          label="GUID"
          type="text"
          placeholder="GUID"
          name="GUID"
          inputRef={guidRef}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          label="First Name"
          type="text"
          placeholder="First Name"
          name="First Name"
          inputRef={firstNameRef}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          name="Last Name"
          inputRef={lastNameRef}
        />
        <Stack width={"100%"} direction={"row"} spacing={2}>
          <TextField
            id="outlined-basic"
            label="Year"
            type="phone"
            placeholder="Year"
            name="Year"
            inputProps={{
              maxLength: 4,
            }}
            inputRef={yearRef}
          />
          <TextField
            id="outlined-basic"
            label="Month"
            type="phone"
            placeholder="Year"
            name="Month"
            inputProps={{
              maxLength: 2,
            }}
            inputRef={monthRef}
          />
          <TextField
            id="outlined-basic"
            label="Day"
            type="phone"
            placeholder="Day"
            name="Day"
            inputProps={{
              maxLength: 2,
            }}
            inputRef={dayRef}
          />
        </Stack>
        <TextField
          fullWidth
          id="outlined-basic"
          label="SSN4"
          type="text"
          placeholder="Last 4 Digit Social Security Number"
          name="SSN4"
          inputRef={ssn4Ref}
        />
      </Stack>
      <CvsButton
        skin={skin}
        title="Compare Hash to input data"
        onClick={continueAndCreatePayload}
      />
    </>
  );
};

const Step3 = ({
  userData,
  federationHash,
}: {
  userData: any;
  federationHash: any;
}) => {
  const [compareResult, setCompareResult] = useState(null);

  const getCompareResult = async () => {
    const result = await federationCompareHash({
      federationHash: federationHash,
      firstName: userData.firstName,
      lastName: userData.lastName,
      dob: userData.dob,
      ssn4: userData.ssn4,
    });

    setCompareResult(result);
  };
  useEffect(() => {
    getCompareResult();
  }, []);
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100%", p: 3 }}
    >
      <Typography component={"h3"} fontSize={24}>
        {`Match percentage: ${
          compareResult ? compareResult.federationScore + "%" : "none"
        } `}
      </Typography>
    </Stack>
  );
};

export default FederationHash;
