import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useContext } from "react";
import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import { updateUser, updateUserManualInputWithSession, updateUserManualInputWithSessionProps } from "@privateid/cryptonets-web-sdk/dist/apiUtils";
import STEPS from "../../pages/register/steps";
import useToast from "../../utils/useToast";
import { states } from "../../utils";
import CvsButton from "../Button";
import { UserContextV2 } from "../../context/UserContextV2";

const RequestAddress = ({
  setStep,
  skin,
  matchesSM,
  onSuccess,
  setPrevStep,
  loading,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
  onSuccess: () => void;
  setPrevStep: (e: string) => void;
  loading: boolean;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const { showToast } = useToast();
  const [addressData, setAddressData] = useState<any>();
  const [state, setState] = useState<any>();
  const palette: { [key: string]: any } = mainTheme.palette;

  const context = useContext(UserContext);
  const { id } = context;
  const userContextV2 = useContext(UserContextV2);

  const handleContinue = async () => {
    // const address = {
    //   addressLine1: addressData?.addressLine1,
    //   addressLine2: addressData?.addressLine2,
    //   city: addressData?.city,
    //   state,
    //   zipCode: addressData?.zipCode,
    //   country: "USA",
    // };

    // const updateUserResult: any = await updateUser({
    //   id,
    //   attributes: { govId: { address } } as any,
    // });

    const updateManualV2Payload: updateUserManualInputWithSessionProps = {
      sessionToken: userContextV2.sessionToken,
      address:{
        addressLine1: addressData?.addressLine1,
        addressLine2: addressData?.addressLine2,
        city: addressData?.city,
        state,
        zipCode: addressData?.zipCode,
        country: "USA",
      }
    }
    await updateUserManualInputWithSession(updateManualV2Payload)

    // if (updateUserResult?.level === "error") {
    //   showToast(updateUserResult?.message, "error");
    // } else {
      onSuccess();
    // }
  };

  const onChange = (e: { target: { name: string; value: string } }) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  return (
    <>
      <Grid style={styles.requestAddressCardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={matchesSM ? "center" : "left"}
          fontSize={16}
          fontWeight={500}
          lineHeight={1.5}
          mt={1}
          className={classes.cardInnerHeading}
          mb={0}
        >
          We are only able to process residential addresses.
          <br />
          Please try again with your home address.
        </Typography>
        <Box width={"100%"}>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Address Line 1"
            name="addressLine1"
            onChange={onChange}
            className={classes.inputStyle}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Address Line 2"
            name="addressLine2"
            onChange={onChange}
            className={classes.inputStyle}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="City"
            name="city"
            onChange={onChange}
            className={classes.inputStyle}
          />
          <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
            <Autocomplete
              id="combo-box-demo"
              options={states}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="State or province" />
              )}
              onChange={(i, e) => setState(e?.abbreviation)}
            />
          </FormControl>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Zip Code"
            name="zipCode"
            inputProps={{
              maxLength: 5,
            }}
            onChange={onChange}
            className={classes.inputStyle}
          />
        </Box>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Box>
        <CvsButton
          skin={skin}
          title={
            loading ? (
              <CircularProgress className={classes.scanLoader} />
            ) : (
              "Continue"
            )
          }
          onClick={() => {
            handleContinue();
          }}
        />
        <CvsButton
          skin={skin}
          title='No, I do not consent'
          onClick={() => {
            setStep(STEPS.CONSENT_FAIL);
            setPrevStep?.(STEPS.ADDITIONAL_REQUIREMENTS);
          }}
        />
      </Box>
    </>
  );
};

export default RequestAddress;
