import { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { styles } from "../../styles/styles";
import { useNavigate } from "react-router";
import { createVerificationSession } from "../../services/api";
import { createSearchParams, useSearchParams } from "react-router-dom";
import config from "../../config";
import { useStyles } from "../../pages/home/styles";
import { navigateToUrl } from "../../utils";
import TooltipContainer from "../TooltipContainer";
import privateIdLogo from "../../assets/priflo-logo.jpeg";
import IdemiaLogo from "../../assets/idemiaLogo.svg";
import bannerImage from "../../assets/banner-frame.png";
import openSanctions from "../../assets/open-sanctions.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import pLogo from "../../assets/pid-logo.png";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

interface HomeProps {
  theme?: string;
  skin?: string;
}
const HomeComponent = ({ theme, skin }: HomeProps) => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState<any>("");
  const [flow] = useState(1);
  const navigate = useNavigate();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const createVerification = async (type?: string) => {
    if (user?._id || uuid?.length > 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("uuid");
    }
    if ((user?._id || user?.token) && user?.successUrl) {
      navigateToUrl(user?.successUrl, user?.token);
      return;
    }
    setLoadingType(type);
    setLoading(true);
    const payload = {
      ...config.clientConfig,
    };
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      onFlowChange(flow, result?.token, type);
    }
    setLoadingType("");
    setLoading(false);
  };

  const handleChange = () => {
    const skipAntiSpoof = searchParams.get("skipAntispoof") !== "false";
    setSearchParams({
      skin: "primary",
      skipAntispoof: (!skipAntiSpoof).toString(),
    });
  };

  const onFlowChange = (flow: number, token: string, type?: string) => {
    switch (flow) {
      case 1:
        // change this from navigate to using window.location.href
        window.location.href = `/register?token=${token}&skin=${skin}${
          type === "ssn" ? `&bypassSsn=true` : ""
        }${
          searchParams.get("skipAntispoof")?.length
            ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
            : ""
        }`;
        break;
      // });
      case 2:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            age: "18",
          }).toString(),
        });
      case 3:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            age: "16",
          }).toString(),
        });
      case 4:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            ageCreditCheck: "true",
          }).toString(),
        });

      case 5:
        return navigate({
          pathname: "/register",
          search: createSearchParams({
            token: token || "",
            step: "9",
          }).toString(),
        });

      default:
        break;
    }
  };

  const onAllThreeAuthenticate = (dl?: boolean) => {
    if (user?._id || uuid?.length > 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("uuid");
    }
    let navigateRoute = `/login_with_barcode`;
    if (dl) {
      navigateRoute = `/login_with_dl`;
    }
    navigateWithQueryParams(navigateRoute);
  };

  const onAllTwoAuthenticate = (requestProof?: boolean) => {
    if (user?._id || uuid?.length > 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("uuid");
    }
    let navigateRoute = `/aal2`;
    if (requestProof) {
      navigateRoute = `/request-proof`;
    }
    navigateWithQueryParams(navigateRoute);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={matchesSM ? 12 : 6}>
            <Box
              className={classes.mainWrap}
              width={matchesSM ? "100%" : "350px"}
            >
              <img
                src={pLogo}
                alt="p-logo"
                width={"100px"}
                className={classes.pLogo}
              />
              <Box className={classes.innerWrap}>
                <Typography
                  component="p"
                  color={`#333`}
                  fontSize={32}
                  fontWeight={400}
                  className={classes.homeSubHeading}
                  mt={0}
                >
                  Verified Private Identity
                </Typography>
              </Box>
              <Box pt={1} className={classes.buttonsGrid}>
                <Grid
                  container
                  alignItems="flex-start"
                  flexDirection="column"
                  className={classes.buttonsWrap}
                >
                  <TooltipContainer content="Consumer IAL 2 Registration (does not include SSN4 for the demo)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => createVerification("ssn")}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={
                        loading && loadingType === "ssn" ? (
                          ""
                        ) : (
                          <ArrowForwardIosIcon />
                        )
                      }
                    >
                      {loading && loadingType === "ssn" ? (
                        <CircularProgress className={classes.homeLoader} />
                      ) : (
                        "Register"
                      )}
                    </Button>
                  </TooltipContainer>
                  {/* <TooltipContainer content="Workforce IAL 2 Registration (Includes SSN4)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => createVerification()}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={
                        loading && !loadingType ? "" : <ArrowForwardIosIcon />
                      }
                    >
                      {loading && !loadingType ? (
                        <CircularProgress className={classes.homeLoader} />
                      ) : (
                        "Workforce Registration"
                      )}
                    </Button>
                  </TooltipContainer> */}
                  <TooltipContainer content="Authenticate with 2 factors (DL, passkey, or verified credentials)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => onAllTwoAuthenticate()}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Sign in with Passkey
                    </Button>
                  </TooltipContainer>
                  <TooltipContainer content="Authenticate with 3 factors (Face, passkey, and Driver's License)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => onAllThreeAuthenticate(true)}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Sign in with Driver's License
                    </Button>
                  </TooltipContainer>
                  <TooltipContainer content="Authenticate with 2 factors (DL, passkey, or verified credentials)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => onAllTwoAuthenticate(true)}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      VC Proof Request
                    </Button>
                  </TooltipContainer>
                  {!matchesSM && (
                    <TooltipContainer content="Authenticate with 3 factors (Face, passkey, and verified credentials)">
                      <Button
                        sx={{
                          textTransform: "unset",
                          opacity: loading ? 0.8 : 1,
                        }}
                        variant="contained"
                        style={styles.ageVerifiedButton}
                        onClick={() => onAllThreeAuthenticate(false)}
                        disabled={loading}
                        className={classes.buttonsWrapButton}
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        Sign in with Verified Credentials
                      </Button>
                    </TooltipContainer>
                  )}
                  {/* <TooltipContainer content="Three step flow (Face login to get federation hash, user detail to compare to hash, compare hash result)">
                    <Button
                      sx={{
                        textTransform: "unset",
                        opacity: loading ? 0.8 : 1,
                      }}
                      variant="contained"
                      style={styles.ageVerifiedButton}
                      onClick={() => navigate("/federation_hash")}
                      disabled={loading}
                      className={classes.buttonsWrapButton}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Workforce Federation
                    </Button>
                  </TooltipContainer> */}
                </Grid>
              </Box>

              {matchesSM ? (
                <Button
                  style={styles.ageLearnMoreButton}
                  sx={{ textTransform: "unset" }}
                  className={classes.buttonsWrapButton}
                >
                  Learn more
                </Button>
              ) : null}
            </Box>
          </Grid>
          {/* {!matchesSM && (
            <Grid item xs={6}>
              <Box className={classes.bannerImage}>
                <img
                  src={bankCard}
                  alt="women"
                  width={"50%"}
                />
              </Box>
            </Grid>
          )} */}
        </Grid>
        {!matchesSM && (
          <Box className={classes.bankIcons}>
            <Typography
              component="p"
              color={`#ababab`}
              fontSize={22}
              fontWeight={400}
              className={classes.homeSubHeading}
              mt={0}
              sx={{ flex: 1 }}
              pl={4}
              letterSpacing={"0.1em"}
            >
              PARTNERS
            </Typography>
            <img src={privateIdLogo} alt="women" height="40px" />
            <img src={IdemiaLogo} alt="women" width="150px" height="30px" />
            <img src={openSanctions} alt="women" width="140px" height="40px" />
          </Box>
        )}
        <div className={classes.livenessSwitch}>
           <FormControlLabel
             onChange={handleChange}
             labelPlacement="start"
             control={<Switch />}
             label="Liveness"
           />
         </div>
      </Container>
      {!matchesSM && ["/", "/signin"]?.includes(window.location.pathname) && (
        <div className="homeSidebarImg">
          <img src={bannerImage} alt="women" />
        </div>
      )}
    </>
  );
};

export default HomeComponent;
