import { useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Email } from "@mui/icons-material";
import { createUserWithSession } from "@privateid/cryptonets-web-sdk/dist/apiUtils";
import PhoneIcon from "@mui/icons-material/Phone";
import InputMask from "react-input-mask";
import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import { createUserID } from "../../utils";
import STEPS from "../../pages/register/steps";
import useToast from "../../utils/useToast";
import { useSearchParams } from "react-router-dom";
import { registerInputsSchema } from "../../constants/schema";
import { UserContextV2 } from "../../context/UserContextV2";

enum InputField {
  EMAIL = "email",
  PHONE = "phone",
}

const RegisterInputs = ({
  setStep,
  skin,
  matchesSM,
  setPrevStep,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
  setPrevStep: (e: string) => void;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const { showToast } = useToast();
  const [loader, setLoader] = useState(false);
  const [isDisable, setIsDisable] = useState<any>({
    phone: false,
    email: false,
  });
  const palette: { [key: string]: any } = mainTheme.palette;
  const [searchParams] = useSearchParams();
  const bypassSsnParams = searchParams.get("bypassSsn");
  const isBypassSsn = bypassSsnParams;

  const ssn4Ref = useRef<HTMLFormElement | null>(null);
  const emailRef = useRef<any>(null);
  const phoneRef = useRef<any>(null);
  const context = useContext(UserContext);

  const userContextV2 = useContext(UserContextV2);
  const { setPhoneNumber, setSSN4, setId, tokenParams, setEmail } = context;
  const validateEmail = async (email: string) => {
    return await registerInputsSchema
      .validate({
        email: email,
      })
      .then(() => true)
      .catch(() => false);
  };

  const [showPhoneError, setShowPhoneError] = useState({
    error: false,
    message: "",
  });

  const handleContinue = async (e?: any) => {
    e.preventDefault();
    const ialValue: any = searchParams.get("IAL");
    setPrevStep(STEPS.REGISTER_FORM);

    const phoneInput = phoneRef?.current?.value;
    const emailInput = emailRef?.current?.value || " ";
    const isValidateEmail = await validateEmail(emailInput);
    if (!isValidateEmail && !isDisable.email) {
      showToast("Enter valid email", "error");
      handleCheckEmailOnBlur({ traget: { value: emailInput } });
      return;
    }

    if (phoneInput?.split("-").join("").length !== 10 && !isDisable.phone) {
      handleCheckPhoneInput({ traget: { value: phoneInput } });
      showToast("Enter mobile number", "error");
      return;
    }

    if (isDisable.email && isDisable.phone) {
      showToast("Need at least one field entered.", "error");
      return;
    }

    setLoader(true);
    setPhoneNumber(phoneInput);
    setEmail(emailRef?.current?.value);
    const newID = await createUserID();
    setId(newID);

    userContextV2.setEmail(emailRef?.current?.value);
    userContextV2.setPhoneNumber(phoneInput);
    userContextV2.setSessionToken(tokenParams);
    if (isBypassSsn) {
      const v2Result = await createUserWithSession({
        sessionToken: tokenParams,
        email: emailRef?.current?.value,
        phone: phoneInput,
        ssn: "9991",
      });

      console.log("create user v2 result:", v2Result);

        setStep(STEPS.ENROLL);
    } else {
      setStep(STEPS.REGISTER_FORM_SSN);
    }

    setLoader(false);
  };

  const [showEmailError, setShowEmailError] = useState({
    error: false,
    message: "",
  });

  const handleCheckPhoneInput = async (e: any) => {
    const phoneInput = e?.target?.value;
    registerInputsSchema
      .validate({
        phone: phoneInput?.split("-")?.join(""),
      })
      .catch((error) =>
        setShowPhoneError({ error: true, message: error?.message })
      );
  };

  const handleCheckEmailOnBlur = (e: any) => {
    const emailInput = e?.target?.value;
    registerInputsSchema
      .validate({
        email: emailInput,
      })
      .catch((error) =>
        setShowEmailError({ error: true, message: error?.message })
      );
  };

  enum FocusHandlerEnum {
    email = "email",
    phone = "phone",
  }

  const handleOnFocus = (handle: FocusHandlerEnum) => {
    switch (handle) {
      case FocusHandlerEnum.email:
        setShowEmailError({ error: false, message: "" });
        return;
      case FocusHandlerEnum.phone:
        setShowPhoneError({ error: false, message: "" });
        return;
      default:
        return;
    }
  };

  const handleCheckboxDisabling = (Field: InputField) => {
    setShowEmailError({ error: false, message: "" });
    setShowPhoneError({ error: false, message: "" });

    switch (Field) {
      case InputField.EMAIL:
        if (!isDisable.email) {
          emailRef.current.value = "";
        }
        setIsDisable({ ...isDisable, email: !isDisable.email });
        return;
      case InputField.PHONE:
        if (!isDisable.phone) {
          phoneRef.current.value = "";
        }
        setIsDisable({ ...isDisable, phone: !isDisable.phone });
        return;
      default:
    }
  };

  const [phoneInput, setPhoneInput] = useState("");

  return (
    <>
      <form onSubmit={handleContinue} style={{ height: "100%" }}>
        <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={16}
            fontWeight={700}
            lineHeight={1.5}
            mt={3}
            mb={2}
            className={classes.cardInnerHeading}
          >
            Please enter your personal details below.
          </Typography>
          <Box width={"100%"}>
            <Grid container>
              <InputMask
                mask={"999-999-9999"}
                value={phoneInput}
                maskChar={null}
                onBlur={handleCheckPhoneInput}
                onFocus={() => {
                  handleOnFocus(FocusHandlerEnum.phone);
                }}
                disabled={isDisable.phone}
                ref={phoneRef}
                onChange={(e) => {
                  setPhoneInput(e.target.value);
                }}
              >
                {
                  // @ts-ignore
                  () => {
                    return (
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Phone"
                        type="tel"
                        placeholder="Phone"
                        name="Phone"
                        InputProps={{
                          startAdornment: <PhoneIcon sx={{ pr: 1 }} />,
                        }}
                        color={showPhoneError.error ? "error" : "primary"}
                        helperText={
                          showPhoneError.error ? showPhoneError.message : ""
                        }
                        inputProps={{ maxLength: 12 }}
                        sx={{
                          "& .MuiFormHelperText-contained": {
                            color: "red",
                          },
                          mb: 0,
                          mt: 2,
                        }}
                      />
                    );
                  }
                }
              </InputMask>

            </Grid>

            <TextField
              fullWidth
              id="outlined-basic"
              label="EMAIL"
              type="email"
              placeholder="Email"
              name="Email"
              InputProps={{
                startAdornment: <Email sx={{ pr: 1 }} />,
              }}
              inputRef={emailRef}
              onBlur={handleCheckEmailOnBlur}
              onFocus={() => {
                handleOnFocus(FocusHandlerEnum.email);
              }}
              color={showEmailError.error ? "error" : "primary"}
              helperText={showEmailError.error ? showEmailError.message : ""}
              sx={{
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mb: 0,
                mt: 2,
              }}
              disabled={isDisable.email}
            />
          </Box>
        </Grid>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        <Box mb={"52px"}>
          <Button
            variant="contained"
            color={"inherit"}
            style={{ ...styles.continueButton, opacity: 1 }}
            className={classes.continueButton}
            onClick={handleContinue}
            type="submit"
          >
            <Typography
              component="p"
              color={palette?.[skin]?.text}
              textAlign="center"
              fontWeight={600}
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              textTransform="none"
            >
              {loader ? (
                <CircularProgress className={classes.scanLoader} />
              ) : (
                "Continue"
              )}
            </Typography>
          </Button>
        </Box>
      </form>
    </>
  );
};

export default RegisterInputs;
