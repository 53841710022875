import { useEffect, useState } from "react";
import styles from "../../styles/Home.module.css";
import useScanFrontDocumentWithoutPredict from "../../hooks/useScanFrontDocumentWithoutPredict";
import Camera from "../Camera";
import { getScanFrontColor } from "../../constants";
import Card from "../../assets/card.svg";
import { getFrontDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import { BrowserInfo, detect } from "detect-browser";
import STEPS from "../../pages/register/steps";

const FaceCompareFrontDocument = ({
  onSuccess,
  onReadyCallback,
  onFailCallback,
  onCameraFail,
  enrollImageData,
  setStep,
  isPassport = false
}: {
  onSuccess: (e: any) => void;
  onReadyCallback: (e: boolean) => void;
  onFailCallback: (e: { status: string; message: string }) => void;
  onCameraFail: (e: any) => void;
  enrollImageData: any;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  isPassport?:boolean
}) => {
  const [isReady, setIsReady] = useState(false);
  const handleFrontSuccess = (result?: any) => {
    onSuccess?.(result);
  };
  const { scanFrontDocument, resultResponse } =
    useScanFrontDocumentWithoutPredict(
      handleFrontSuccess,
      onFailCallback,
      enrollImageData
    ) as any;

  const handleScanDLFront = async (e: boolean) => {
    setIsReady(e);
    onReadyCallback?.(e);
    if (e) {
      await scanFrontDocument();
    }
  };

  const returnMessage = () => {
    return getFrontDocumentStatusMessage(resultResponse?.op_status);
  };

  const getCamera = async () => {

    //@ts-ignore
    const { os }: BrowserInfo = detect();
    if (os === "Mac OS") {
      let devices: MediaDeviceInfo[] = [];

      devices = await navigator.mediaDevices.enumerateDevices();
      devices = devices.filter((d) => d.kind === 'videoinput');
      const constraint = {
        audio: false,
        video: {
          width: { ideal: 1920 },
        },
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraint);
      const track = stream.getVideoTracks()[0];
      const capabilities = track.getCapabilities();
      //@ts-ignore
      if(capabilities?.width < 1920 && capabilities?.height < 1920){
        setStep(STEPS.SWITCH_DEVICE)
      }
    }

  }

  useEffect(() => {
    getCamera();
  }, [])
  return (
    <div id="canvasInput" className={`${styles.container} documentCamera`}>
      {isReady && (
        <>
          <div
            className={styles.cameraFrame}
            style={{
              borderColor: getScanFrontColor(resultResponse?.op_status),
            }}
          >
            <img src={Card} alt="" />
          </div>
          <div className={styles.cameraFrameOuter} />
        </>
      )}
      <Camera
        onReadyCallback={handleScanDLFront}
        onSwitchCamera={handleScanDLFront}
        onCameraFail={onCameraFail}
        style={{ height: "unset" }}
        mode={"back"}
        requireHD={true}
        message={returnMessage()}
        isDocumentScan={true}
        setStep={setStep}
        canvasLayout={true}
        hideInstructionOverlay={false}
        isPassport={isPassport}
      ></Camera>
    </div>
  );
};

export default FaceCompareFrontDocument;
