import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import { useNavigate } from "react-router";
import { useContext, useEffect } from "react";
import { LoginContext } from "../../../context/LoginContext";
import { postLoginAttemp } from "../../../services/api";
import successThumb from "../../../assets/success.gif";
import { CardHeader } from "../LoginSuccess";

export const FailureMessage = ({
  classes,
  message,
  palette,
  skin,
  matchesSM,
}: any) => {
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  return (
    <Grid
      style={{
        ...styles.cardGrid,
        backgroundColor: matchesSM ? "" : "#e9e9e9",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
      className={classes.cardGridMobile}
    >
      {/* <img
        src={successThumb}
        alt=""
        className={classes.checkIcon}
        width={400}
        style={{ transform: "rotate(180deg)" }}
      /> */}
      <Box>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={25}
          fontWeight={matchesSM ? "bold" : 500}
          lineHeight={1.5}
          mt={2}
          className={classes.cardInnerHeading}
          px={matchesSM ? 5 : ""}
        >
          {message
            ? message
            : "Sorry, we were unable to verify you at this time."}
        </Typography>
        {uuid?.length && (
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={15}
            fontWeight={500}
            lineHeight={1.5}
            mt={0}
            className={classes.cardInnerHeading}
          >
            UUID : {uuid}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

const LoginFailure = ({
  skin,
  matchesSM,
  showToast,
  message,
  startAgainUrl,
  postToOidc = () => {},
  userData,
}: {
  skin: string;
  matchesSM: boolean;
  postToOidc: () => void;
  showToast?: () => void;
  message?: string;
  startAgainUrl?: string;
  userData?: any;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;

  const loginContext = useContext(LoginContext);

  const logUserFailureAttempt = async () => {
    const userData = {
      status: "failure",
      predictedGUID: loginContext.predictedGUID || "none",
      barcodeHash: loginContext.barcodeHash,
      error: true,
      errorMessage: loginContext.errorMessage,
    };
    await postLoginAttemp(userData);
  };
  const navigate = useNavigate();
  useEffect(() => {
    logUserFailureAttempt();
    setTimeout(() => {
      postToOidc();
    }, 3000);
  }, []);
  return (
    <>
      <CardHeader classes={classes} title="Login Failed!" />
      {!matchesSM && <Divider color={palette?.[skin]?.listText} />}
      <FailureMessage
        classes={classes}
        message={message || loginContext.errorMessage}
        palette={palette}
        skin={skin}
      />
      <Grid style={{ marginBottom: 20 }}>
        {!matchesSM && <Divider color={palette?.[skin]?.listText} />}
        <Box>
          <Button
            variant="contained"
            color={"inherit"}
            style={styles.continueButton}
            className={classes.continueButton}
            onClick={() => {
              navigate(0);
            }}
          >
            <Typography
              component="p"
              color={palette?.[skin]?.text}
              textAlign="center"
              fontWeight={600}
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              textTransform="capitalize"
            >
              Try again
            </Typography>
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default LoginFailure;
