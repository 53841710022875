import {
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ArrowBack, Lock } from "@mui/icons-material";
import { useStep } from "../../context/StepContext";
import privateIdLogo from "../../assets/privateidLogo.png";

const ModalTop = ({
  showDivider = true,
  palette,
  skin,
}: {
  showDivider?: boolean;
  palette: any;
  skin: any;
}) => {
  const { goOneStepBack, stepFlow } = useStep();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid item container mt={0}>
        <Grid item xs={4} pt={1.5} pl={1}>
          {stepFlow.length > 1 && (
            <IconButton onClick={goOneStepBack} aria-label={"backBtn"}>
              <ArrowBack />
            </IconButton>
          )}
        </Grid>
        {!matchesSM && (
          <Grid item container xs={8} my={2}>
            <img
              src={privateIdLogo}
              alt="logo"
              style={{
                width: 160,
                //height: 21,
              }}
            />
          </Grid>
        )}

        <Grid item container justifyContent={"center"} spacing={1} mt={matchesSM ? 1 : 0}>
          <Grid item alignItems={"center"}>
            <Lock sx={{ fontSize: 18 }} />
          </Grid>
          <Grid item paddingLeft={0}>
            <Typography
              component="p"
              textAlign="center"
              fontSize={"0.9em"}
              fontWeight={500}
              sx={{ padding: 1, pt: 0, pl: 0 }}
              overflow={"auto"}
            >
              Private Identity Verification
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!matchesSM && showDivider && (
        <Divider color={palette?.[skin]?.borderColor} />
      )}
    </>
  );
};

export default ModalTop;
