import { headerVisible } from "../../theme";
import Header from "../../components/Header";
import HomeComponent from "../../components/HomeComponent";
import { useContext, useEffect } from "react";
import { CvsRequestContext } from "../../context/RequestContext";
import { useNavigate } from "react-router";
import config from "../../config";
import { createVerificationSession } from "../../services/api";
import { createSearchParams } from "react-router-dom";

interface HomeProps {
  theme: string;
  skin: string;
}
const Home = ({ theme, skin }: HomeProps) => {
  const themeName = skin || "primary";
  const navigate = useNavigate();

  const cvsQueryContext = useContext(CvsRequestContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const federationToken = urlParams.get("federationToken");
    const apiKey = urlParams.get("apiKey");
    const action = urlParams.get("actionFlow");
    const productGroup = urlParams.get("productGroup");
    const requestIAL = urlParams.get("requestIAL");
    const requestFAL = urlParams.get("requestFAL");
    const requestAAL = urlParams.get("requestAAL");
    const interactionUID = urlParams.get("interactionUID");
    const requestSSN = urlParams.get("requestSSN");

    cvsQueryContext.setFederationToken(federationToken);
    cvsQueryContext.setPrivateID(apiKey);
    cvsQueryContext.setActionFlow(action);
    cvsQueryContext.setProductGroup(productGroup);
    cvsQueryContext.setRequestIAL(requestIAL);
    cvsQueryContext.setRequestFAL(requestFAL);
    cvsQueryContext.setRequestAAL(requestAAL);
    cvsQueryContext.setInteractionUID(interactionUID);
    cvsQueryContext.setRequestSSN(requestSSN === "true");

    const handleLoginAction = () => {
      cvsQueryContext.setRequestFromOIDC(true);
      if (requestAAL === "1.0") {
        navigate("/login");
      } else if (requestAAL === "2.0") {
        navigate("/login");
      } else if (requestAAL === "3.0") {
        navigate("/login_with_barcode");
      }
    };

    const handleForgetMeAction = () => {
      cvsQueryContext.setRequestFromOIDC(true);
      navigate("/forget_me");
    };

    const handleRegisterAction = async () => {
      cvsQueryContext.setRequestFromOIDC(true);
      const payload = {
        ...config.clientConfig,
        // productGroupId: requestSSN === "true" ? "cvs-id" : "cvs-no-id",
      };

      const result: any = await createVerificationSession(payload);
      const searchParam =
        requestSSN === "true"
          ? {
              token: result.token || "",
              IAL: requestIAL ?? "",
            }
          : {
              token: result.token || "",
              IAL: requestIAL ?? "",
              bypassSsn: true,
            };

      navigate({
        pathname: "/register",
        //@ts-ignore
        search: createSearchParams(searchParam).toString(),
      });
    };

    if (action === "login") {
      handleLoginAction();
    }

    if (action === "forgetMe") {
      handleForgetMeAction();
    }

    if (action === "register") {
      handleRegisterAction();
    }
  }, []);

  return (
    <>
      {headerVisible?.includes(skin) && <Header theme={themeName} />}
      <div className="homePageWrapper homeComponent homeBG">
        <HomeComponent theme={theme} skin={skin} />
      </div>
    </>
  );
};

export default Home;
