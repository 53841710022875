import * as React from "react";
import "react-phone-number-input/style.css";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {getUser, verifyTokenApi} from "../../services/api";
import { useSearchParams } from "react-router-dom";
import { useInterval } from "../../utils/useInterval";
import STEPS from "../../pages/register/steps";
import { TabPanelProps } from "../../interface";
import OptionTab from "./optionTab";

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function FullWidthTabs({
  setStep,
  stopApi,
}: {
  setStep: (e: string) => void;
  stopApi?: boolean;
}) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [refreshInterval, setRefreshInterval] = React.useState<null | number>(
    10000
  );
  const [searchParams] = useSearchParams();
  const tokenParams = searchParams.get("token");

  useInterval(() => {
    if (!stopApi) {
      verifyTokenApi(tokenParams).then(async (res: any) => {
        if (["SUCCESS", "FAILURE"].includes(res.status)) {
          setRefreshInterval(null);
          if (res.status === "SUCCESS") {
            const payload = {
              id: res.user,
            };
            const data: any = await getUser(payload);
            localStorage.setItem("uuid", JSON.stringify(data.user.uuid || {}));
            setStep(STEPS.PASSKEY);
          } else {
            setStep(STEPS.FAILURE);
          }
        }
      });
    }
  }, refreshInterval);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <OptionTab
      value={value}
      handleChange={handleChange}
      matchesSM={matchesSM}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      stopApi={stopApi}
    />
  );
}
