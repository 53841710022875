import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import Header from "../../components/Header";
import logoWhite from "../../assets/privateidLogoP.png";
import {
  createPresentations,
  createProofRequest,
  deleteCredentials,
  generateDidForUser,
  getCredentialDetails,
  issueCredentials,
  sendPresentationProofRequest,
  verifyCredentials,
  viewPDF,
} from "../../services/vc-dock";
import { headerVisible } from "../../theme";
import { useStyles } from "../ViewCredentials/styles";
import { updateUser } from "@privateid/cryptonets-web-sdk";
import { Checkbox, CircularProgress } from "@mui/material";
import CvsButton from "../../components/Button";
import {
  extractPathKeys,
  generatePresentationPayload,
  generateVCPayload,
  prepareCredentialsForPresentation,
} from "../../utils/vc-dock";
import useToast from "../../utils/useToast";
import { useNavigate } from "react-router";
// import QRCode from "react-qr-code";

export const Card = ({
  data,
  user,
  selected,
  setSelected,
  setCardData,
}: any) => {
  const classes = useStyles();
  const { credential, createdAt, expiryDate, id, type } = data || {};
  const { showToast } = useToast();
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewLoader, setViewLoader] = useState(false);
  const navigate = useNavigate();
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleVerifyVC = async () => {
    setViewLoader(true);
    handleMenuClose();
    const response: any = await verifyCredentials(
      user._id
    );
    if (response.verified) {
      showToast("VC verified successfully.", "success");
    } else {
      showToast(
        "There was some problem verifying VC. Please try again.",
        "error"
      );
    }
    setViewLoader(false);
  };

  const handleViewCredential = async () => {
    setViewLoader(true);
    handleMenuClose();
    const response: any = await viewPDF(user._id);
    const pdfUrl = URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    const newTab: any = window.open(pdfUrl, "_blank");
    // Make sure to revoke the URL object to release memory once the tab is closed
    newTab.onbeforeunload = () => URL.revokeObjectURL(pdfUrl);
    setViewLoader(false);
  };

  const handleDeleteCredential = async () => {
    setViewLoader(true);
    handleMenuClose();
    await deleteCredentials(user._id);
    const vcData = { credentialsId: "" };
    // TODO: update with new user update api.
    // await updateUser({
    //   id: user.customerId,
    //   // @ts-ignore
    //   attributes: vcData,
    // });
    setCardData(undefined);
    setViewLoader(false);
  };
  return (
    <div className={classes.cardWrap}>
      <div className={classes.cardHeader}>
        <img src={logoWhite} alt="" width={30} />
        <div className={classes.cardHeaderLeft}>
          <h4>Private ID</h4>
          <p>{type}</p>
        </div>
        <div className={classes.cardHeaderRight}>
          {/* Three-dots Action Menu */}
          {viewLoader ? (
            <CircularProgress className={classes.scanLoader} />
          ) : (
            <>
              <MoreVertIcon onClick={handleMenuClick} />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleViewCredential}>
                  View Credential
                </MenuItem>

                <MenuItem onClick={handleVerifyVC}>Verify Credential</MenuItem>

                <MenuItem onClick={handleDeleteCredential}>
                  Delete Credential
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
      <div className={classes.cardBodyWrap}>
        {setSelected && (
          <div className={classes.checkBoxWrap}>
            <Checkbox
              checked={selected === id}
              onChange={() => setSelected(id)}
            />
          </div>
        )}
        <div className={classes.cardItem}>
          <p>LAST NAME</p>
          <h4>{credential?.credentialSubject?.lastname}</h4>
        </div>
        <div className={classes.cardItem}>
          <p>FIRST NAME</p>
          <h4>{credential?.credentialSubject?.firstname}</h4>
        </div>
        <div className={classes.cardItem}>
          <p>JOB TITLE</p>
          <h4>{type}</h4>
        </div>
        <div className={classes.cardItem}>
          <p>START DATE</p>
          <h4>{moment(createdAt).format("DD/MM/YYYY")}</h4>
        </div>
        <div className={classes.cardItem}>
          <p>TERMINATION DATE</p>
          <h4>{expiryDate ? moment(expiryDate).format("DD/MM/YYYY") : "-"}</h4>
        </div>
        {/*<div className={classes.cardItem} style={{ paddingBottom: "10px" }}>*/}
        {/*  <p>Issuer Name</p>*/}
        {/*  <h4>{credential?.issuer?.name}</h4>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export const NoVcFound = ({ skin, classes, user, setCardData }: any) => {
  const generateCredentials = async () => {
    const credentials: any = await issueCredentials(user._id);
    setCardData(credentials);
    const vcData = { did: credentials.did, credentialsId: credentials.id };
    localStorage.setItem("user", JSON.stringify({ ...user, ...vcData }));
  };
  return (
    <div className={classes.noVcWrap}>
      <h3 className={classes.noVCHeading}>
        No Verifiable Credential available.
      </h3>
      <CvsButton
        title={" Generate Now"}
        skin={skin}
        onClick={generateCredentials}
      />
    </div>
  );
};

const VerifyPresentation = ({ theme, skin }: any) => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const classes = useStyles();
  const { showToast } = useToast();
  const [cardData, setCardData] = useState<any>();
  const [selected, setSelected] = useState();
  const [proofRequest, setProofRequest] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isLoadingCreds, setIsLoadingCreds] = useState(true);
  const navigate = useNavigate();
  const viewCredentials = async () => {
    try {
      const credentialDetails = await getCredentialDetails(
        user._id
      );
      setCardData(credentialDetails);
      setIsLoadingCreds(false);
      return;
    } catch (e) {
      setIsLoadingCreds(false);
    }
  };

  const generateProofRequest = async () => {
    const proofRequest: any = await createProofRequest();
    setProofRequest(proofRequest);
  };

  const onSubmitPresentation = async () => {
    try {
      setLoading(true);

      // const requestedKeys = extractPathKeys(proofRequest);
      // const requestedCredentials = prepareCredentialsForPresentation(
      //   cardData.credential,
      //   requestedKeys
      // );
      const presentationPayload = generatePresentationPayload(
        cardData.credential,
        proofRequest.nonce,
        user.did
      );

      const presentationResponse = await createPresentations(
        presentationPayload
      );

      const proofRequestResponse: any = await sendPresentationProofRequest(
        proofRequest.id,
        presentationResponse
      );
      if (proofRequestResponse.verified) {
        showToast("VC verified successfully.", "success");
        navigate("/");
      } else {
        showToast(
          "There was some problem verifying VC. Please try again.",
          "error"
        );
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      showToast(
        "There was some problem verifying VC. Please try again.",
        "error"
      );
    }
  };
  useEffect(() => {
    viewCredentials();
    !proofRequest?.qr && generateProofRequest();
  }, []);

  return (
    <>
      {headerVisible?.includes(skin) && <Header theme={theme} />}
      <div
        className={classes.pageWrap}
        style={{ height: "calc(100vh - 130px)" }}
      >
        {isLoadingCreds ? (
          <div className={classes.noVcWrap}>
            <CircularProgress />
          </div>
        ) : cardData ? (
          <>
            {/*<h3>Scan QR code on device to Verify Credentials</h3>*/}
            {/*{proofRequest?.qr && <QRCode width={200} height={200} value={proofRequest?.qr} />}*/}
            <h3 className={classes.heading} style={{ fontSize: 24 }}>
              Please select the personal details you want to share:
            </h3>

            <Card
              data={cardData}
              user={user}
              setSelected={setSelected}
              selected={selected}
              setCardData={setCardData}
            />
            <div className={classes.buttonWrap}>
              <CvsButton
                title={
                  loading ? (
                    <CircularProgress className={classes.scanLoader} />
                  ) : (
                    "Share personal details"
                  )
                }
                skin={skin}
                onClick={() => onSubmitPresentation()}
                disabled={selected !== cardData?.id}
                textTransform={"none"}
              />
            </div>
          </>
        ) : (
          <NoVcFound
            skin={skin}
            classes={classes}
            user={user}
            setCardData={setCardData}
          />
        )}
      </div>
      {/*{cardData?.id && (*/}

      {/*)}*/}
    </>
  );
};

export default VerifyPresentation;
