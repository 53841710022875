import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {
  closeCamera,
  createDocumentUploadIdTypeEnum,
  createDocumentUploadIdWithSession,
  documentImageTypeEnum,
  updateTypeEnum,
  updateUser,
  updateUserWithSession,
  uploadDL,
  uploadDocumentImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import { DLType } from "@privateid/cryptonets-web-sdk/dist/types";
import { styles, useStyles } from "../../../styles/styles";
import { UserContext } from "../../../context/UserContext";
import { cameraDelay } from "../../../utils";
import { doDocumentOCR, federationGetHashData } from "../../../services/api";
import { ELEMENT_ID } from "../../../constants";
import STEPS from "../../../pages/register/steps";
import SpinnerLoader from "../../SpinnerLoader";
import { theme as Theme } from "../../../theme";
import shield from "../../../assets/shield.png";
import FaceCompareFrontDocument from "../../DocumentCamera/FaceCompareFrontDocument";
import { BrowserInfo, detect } from "detect-browser";
import { UserContextV2 } from "../../../context/UserContextV2";

const PassportScan = ({
  setStep,
  onSuccess,
  skin,
}: {
  setStep: any;
  onSuccess: () => void;
  skin: string;
}) => {
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUserVerify, setIsUserVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanningFailed, setIsScanningFailed] = useState(false);
  // const [opStatus, setOpStatus] = useState<number>();

  const context = useContext(UserContext);

  // api v2
  const userContextV2 = useContext(UserContextV2);
  const { id, enrollImageData, setPortraitConfScore } = context;

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsScanningFailed(true);
  //   }, cameraDelay);
  // }, []);

  const onSuccessFrontScan = async (result: {
    croppedDocument: string;
    croppedMugshot: string;
    inputImage: string;
    portraitConfScore: number;
  }) => {
    const {
      inputImage,
      croppedDocument,
      croppedMugshot,
      portraitConfScore: compareScore,
    } = result;
    setIsLoading(true);
    // setIsScanningFailed(false);
    setPortraitConfScore(compareScore);

    const getBase64StringFromDataURL = (dataURL: string) =>
      dataURL.replace("data:", "").replace(/^.+,/, "");
    const ocrResult: any = await doDocumentOCR(
      getBase64StringFromDataURL(croppedDocument)
    );

    // function formatDate(date: string) {
    //   try {
    //     var d = new Date(date),
    //       month = "" + (d.getMonth() + 1),
    //       day = "" + d.getDate(),
    //       year = d.getFullYear();

    //     if (month.length < 2) month = "0" + month;
    //     if (day.length < 2) day = "0" + day;

    //     return [year, month, day].join("");
    //   } catch (e) {
    //     console.log(e);
    //     return undefined;
    //   }
    // }
    // const govId = {
    //   portraitConfScore: compareScore,
    //   firstName: ocrResult?.FIRST_NAME,
    //   lastName: ocrResult?.LAST_NAME,
    //   dob: formatDate(ocrResult?.DATE_OF_BIRTH),
    // };

    // const federationHash = await federationGetHashData({
    //   guid: context.guid,
    //   govID: {
    //     firstName: govId.firstName,
    //     lastName: govId.lastName,
    //     dob: govId.dob,
    //   },
    //   preferred: {
    //     ssn4: context.ssn4,
    //   },
    // });
    // await updateUser({
    //   id,
    //   // @ts-ignore
    //   attributes: {
    //     govId: govId,
    //     federationHash: federationHash?.responseHash,
    //   },
    // });

    // const uploadImageInput = await uploadDL({
    //   id,
    //   type: DLType.FRONTDLORIGINAL,
    //   image: inputImage,
    // });
    // const uploadCroppedDocumentImage = await uploadDL({
    //   id,
    //   type: DLType.FRONTDLCROPPED,
    //   image: croppedDocument,
    // });
    // const uploadCroppedMugshotImage = await uploadDL({
    //   id,
    //   type: DLType.FRONTDLHEADSHOT,
    //   image: croppedMugshot,
    // });
    // const barcode = {
    //   ...ocrResult,
    //   id_type: "PASSPORT",
    // };

    // await uploadDL({
    //   id,
    //   type: DLType.BARCODEJSON,
    //   barcode: JSON.stringify(barcode),
    // });

    const documentId = await createDocumentUploadIdWithSession({
      documentType: createDocumentUploadIdTypeEnum.passport,
      sessionToken: userContextV2.sessionToken,
      content: JSON.stringify({
        DocumentNumber: "340020013",
        FirstName: "CLARK",
        MiddleName: "SAMPLE",
        LastName: "HARDCODED VALUES",
        Aliases: ["AZURE SAMPLE"],
        DateOfBirth: new Date("1980-01-01"),
        DateOfExpiration: new Date("2019-05-05"),
        DateOfIssue: new Date("2014-05-06"),
        Sex: "M",
        CountryRegion: "USA",
        DocumentType: "P",
        Nationality: "USA",
        PlaceOfBirth: "MASSACHUSETTS, U.S.A.",
        PlaceOfIssue: "LA PAZ",
        IssuingAuthority: "United States Department of State",
        PersonalNumber: "A234567893",
      }),
    });

    await uploadDocumentImageWithSession({
      sessionToken: userContextV2.sessionToken,
      documentImageType: documentImageTypeEnum.FRONT,
      documentId: documentId.id,
      imageString: inputImage,
    });

    await uploadDocumentImageWithSession({
      sessionToken: userContextV2.sessionToken,
      documentImageType: documentImageTypeEnum.FRONT_CROPPED_DOCUMENT,
      documentId: documentId.id,
      imageString: croppedDocument,
    });

    await uploadDocumentImageWithSession({
      sessionToken: userContextV2.sessionToken,
      documentImageType: documentImageTypeEnum.FRONT_MUGSHOT,
      documentId: documentId.id,
      imageString: croppedMugshot,
    });

    await updateUserWithSession({
      sessionToken: userContextV2.sessionToken,
      updateType: updateTypeEnum.compare,
      portrait_conf_score: compareScore.toFixed(2),
    });

    // if (
    //   uploadImageInput &&
    //   uploadCroppedDocumentImage &&
    //   uploadCroppedMugshotImage
    // ) {
    await closeCamera(ELEMENT_ID);
    setTimeout(() => {
      setIsUserVerify(true);
    }, 2000);
    setTimeout(() => {
      setIsLoading(false);
      setIsUserVerify(false);
      setStep(STEPS.ADDRESS_DETAIL);
      // setTimeout(() => {
      //   setIsScanningFailed(true);
      // }, cameraDelay);
    }, 4000);
    // }
  };

  const onCameraNotGranted = (e: boolean) => {
    if (!e) {
      setStep(STEPS.CAMERA_PERMISSION_FAIL);
    }
  };

  const onCameraFail = async () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;

  return (
    <>
      {/*{opStatus !== 0 && isScanningFailed && (*/}
      {/*  <Alert*/}
      {/*    severity="info"*/}
      {/*    onClick={async () => {*/}
      {/*      setStep(STEPS.SWITCH_DEVICE);*/}
      {/*      await closeCamera(ELEMENT_ID);*/}
      {/*    }}*/}
      {/*    className={classes.alertWrap}*/}
      {/*  >*/}
      {/*    You can try switching to other device.*/}
      {/*  </Alert>*/}
      {/*)}*/}
      <Grid
        style={styles.cardGrid}
        className={`cardGridMobile overflowUnset ${classes.cardSxMobile}`}
      >
        <Box position={"relative"}>
          <Box position={"relative"}>
            {isLoading && (
              <Box style={styles.overlay as React.CSSProperties}>
                {isUserVerify ? (
                  <img
                    src={shield}
                    alt="shield"
                    style={styles.shield as React.CSSProperties}
                  />
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            )}
            <FaceCompareFrontDocument
              onSuccess={onSuccessFrontScan}
              onReadyCallback={onCameraNotGranted}
              onFailCallback={(e) => null}
              onCameraFail={onCameraFail}
              enrollImageData={enrollImageData}
              setStep={setStep}
              isPassport={true}
            />
          </Box>
        </Box>
      </Grid>
      <Box style={{ height: 84 }} className={classes.scanBottomBox}>
        {os !== "iOS" && os !== "Android OS" && (
          <Box className={classes.otherDevice} pl={3} mb={1}>
            <Typography
              component="p"
              textAlign={"left"}
              fontSize={15}
              fontWeight={500}
              mt={0}
              onClick={() => {
                setStep(STEPS.SWITCH_DEVICE);
              }}
            >
              <PhoneIphoneIcon /> Switch to Mobile Device
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => {
            setStep(STEPS.PERSONAL_DETAILS);
          }}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
            fontSize={14}
          >
            I do not have a passport
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default PassportScan;
