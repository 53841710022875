// export const PROOF_TEMPLATE_ID = "11298666-7aa7-4a43-8838-6b37127d9e39"; PID User Verify
export const PROOF_TEMPLATE_ID = "bc3a00fd-6844-407d-8373-ffad6394fdea"; // PID UUID Verify

export const ISSUER_ID =
  "did:dock:5EJ5Pd3SeYGQestzL8zwC1HRW5LgKP9ZGak6znoTYyKYnAqQ";

export const SCHEMA_ID =
  "https://schema.dock.io/TestBravo-V1-1691421981356.json";

export const SCHEMA_NAME = "Test Bravo";

export const TEST_CREDENTIALS_ID =
  "https://creds-testnet.dock.io/a1c08b8ad0d5f2fc3cb7bc70ae6599a47dac0e028f4638a54eb7314ff4ab7b51";

export const TEST_PASSWORD = "r489pn29n11o1n3q598r";

export const privateIdDomain = "privateid.com";

export const AUTH_VERIFICATION_ID = "bc3a00fd-6844-407d-8373-ffad6394fdea"; // PID UUID Verify
