import {
  UpdatePayload,
  VerificationSessionPayload,
  MessagePayload,
} from "../interface";
import cryptonetsAPI from "./index";
import identityAPI from "./orchestration";
import axios from "axios";

export const sendMessage = async (payload: MessagePayload) => {
  try {
    const result = await cryptonetsAPI.post(`/user/communicate`, payload);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createUser = async (payload: any) => {
  try {
    const result = await cryptonetsAPI.post(`/user/create`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const updateUserToken = async (
  payload: { customerId: string },
  verificationsessiontoken: string
) => {
  try {
    const result = await identityAPI.put(
      `/verification-session/${verificationsessiontoken}/customer-information`,
      payload
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getUser = async (payload: any) => {
  try {
    const result = await identityAPI.post(`/v2/users/get`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const updateUserApi = async (payload: UpdatePayload) => {
  try {
    const result = await cryptonetsAPI.post(`/user/update`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const getUserPortrait = async (token: any) => {
  try {
    const type = "portrait";

    const result = await identityAPI.get(
      `/v2/verification-session/${token}/img/${type}`
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const deleteUserApi = async (payload: UpdatePayload) => {
  try {
    const result = await cryptonetsAPI.post(`/user/delete`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const createVerificationSession = async (
  payload: VerificationSessionPayload
) => {
  try {
    const result = await identityAPI.post(`/v2/verification-session`, payload);
    console.log(JSON.stringify(result));
    return result;
  } catch (err) {
    return err;
  }
};

export const verifyTokenApi = async (id: any) => {
  try {
    const result = await identityAPI.get(`/v2/verification-session/${id}`);
    return result;
  } catch (err) {
    return err;
  }
};

export const verifyIdApi = async ({ id, payload }: any) => {
  try {
    const result = await identityAPI.post(`/verify-id/${id}`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const getProductGroupList = async () => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      x_api_key: process.env.REACT_APP_API_KEY,
    },
  };
  try {
    const result = await identityAPI.get(
      `/product-group/list/`,
      requestOptions
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const feedback = async (payload: any) => {
  try {
    const result = await cryptonetsAPI.post(`/user/communicate`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export enum iodcPostEnum {
  login = "login",
  register = "register",
}

export const postUserDataToOidc = async (
  token: string,
  interactionUid: string,
  method: iodcPostEnum
) => {
  try {
    const result = await axios.post(
      `https://oidc.privateid.com/interaction/${interactionUid}/${method}`,
      {
        token,
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const postLoginAttemp = async (userData: {
  status: string;
  predictedGUID: string;
  barcodeHash?: string;
  error: boolean;
  errorMessage?: string;
}) => {
  try {
    const payload = {
      status: userData.status,
      guid: userData.predictedGUID,
      barcodeHash: userData.barcodeHash,
      error: userData.error,
      errorMessage: userData.errorMessage,
    };
    const result = await identityAPI.post("/attempt", payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const federationGetHashData = async (userData: {
  guid: string;
  govID?: {
    firstName?: string;
    lastName?: string;
    dob?: string;
  };
  preferred?: {
    firstName?: string;
    lastName?: string;
    dob?: string;
    ssn4?: string;
  };
  apiKey?: string;
}) => {
  try {
    const payload = {
      ...userData,
      api_key: userData.apiKey || process.env.REACT_APP_API_KEY,
    };

    const result = await cryptonetsAPI.post("/federation/getHash", payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const federationCompareHash = async (comparePayload: {
  federationHash: string;
  firstName: string;
  lastName: string;
  dob: string;
  ssn4: string;
  guid: string;
  apiKey?: string;
}) => {
  try {
    const payload = {
      federationHash: comparePayload.federationHash,
      firstName: comparePayload.firstName,
      lastName: comparePayload.lastName,
      dob: comparePayload.dob,
      ssn4: comparePayload.ssn4,
      guid: comparePayload.guid,
      api_key: comparePayload.apiKey || process.env.REACT_APP_API_KEY,
    };

    const result = await cryptonetsAPI.post("/federation/compareHash", payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const doDocumentOCR = async (image: string, apiKey?: string) => {
  try {
    const payload = {
      b64_image: image,
    };
    const result = await cryptonetsAPI.post("/user/processImage", payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const updateDocumentUploadIdWithSession = async ({
  sessionToken,
  documentId,
  content,
}: {
  sessionToken: string;
  documentId: string;
  content: any;
}) => {
  try {
    const result = await identityAPI.put(
      `/v2/verification-session/${sessionToken}/document/${documentId}`,
      { content }
    );
    return result;
  } catch (err) {
    return err;
  }
};
