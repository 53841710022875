import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SelfieEmoji from "../../../assets/selfie.png";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import STEPS from "../../../pages/login/steps";
import { PreForgetHeading } from "../PreForgetMe";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

interface PreLoginProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  IAL?: any;
}

const PreLogin = (props: PreLoginProps) => {
  const { skin, setStep, IAL } = props;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const muiTheme = useTheme();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        flexDirection={"column"}
        style={styles.cardGrid}
        className={classes.cardGridMobile}
      >
        <PreForgetHeading>
          {IAL
            ? "Get ready to take a selfie and scan your driver’s license"
            : "Get ready to take a selfie"}
        </PreForgetHeading>
        <img src={SelfieEmoji} alt="scan" height={"200px"} />
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          setStep(STEPS.LOGIN);
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          Continue
        </Typography>
      </Button>
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          navigateWithQueryParams("/");
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          Back to homepage
        </Typography>
      </Button>
    </>
  );
};

export default PreLogin;
