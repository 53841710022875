import { CircularProgress, MenuItem, Select } from "@mui/material";
import { switchCamera } from "@privateid/cryptonets-web-sdk";
import { useEffect, useState } from "react";
import useCamera, { setResolutionForIphoneCC } from "../../hooks/useCamera";
import useWasm from "../../hooks/useWasm";
import styles from "../../styles/Home.module.css";
import { isBackCamera, isIphoneCC } from "../../utils";
import useCameraPermissions from "../../hooks/useCameraPermissions";
import { useStyles } from "./styles";
import STEPS from "../../pages/register/steps";
import CanvasLayout from "./canvasLayout";
import { detect, OperatingSystem, BrowserInfo } from "detect-browser";
import InstructionsStepper from "./MobileStepper";

export const SWITCH_DEVICE = "Switch Device";

const Camera = ({
  children,
  currentAction,
  style,
  mode = "front",
  message,
  onReadyCallback = () => {},
  onSwitchCamera = () => {},
  onCameraFail = () => {},
  onWasmLoadFail = () => {},
  requireHD = false,
  isDocumentScan = false,
  onCameraNotFullHd = () => {},
  setStep,
  loader,
  canvasLayout,
  enrollOneFaProgress,
  hideInstructionOverlay = true,
  isPassport = false,
}: any) => {
  //@ts-ignore
  const browserData: BrowserInfo = detect();
  const { os }: { os: OperatingSystem | null } = browserData;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const { isCameraGranted } = useCameraPermissions(onReadyCallback);
  const elementId = "userVideo";
  const classes = useStyles();
  const { ready, init, device, devices } = useCamera(
    elementId,
    mode,
    requireHD,
    onCameraFail,
    isDocumentScan || isPassport
  );

  const [deviceId, setDeviceId] = useState(device);
  const [finishSlider, setFinishSlider] = useState(hideInstructionOverlay);
  const isBack = isBackCamera(devices, deviceId || device) ?? mode === "back";
  const [devicesList] = useState(devices);

  const [hideDocumentFrame, setHideFrame] = useState(false);

  useEffect(() => {
    handleWasmLoad();
  }, [wasmReady, ready, wasmStatus, finishSlider]);

  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;

    if (
      wasmReady &&
      !wasmStatus.isChecking &&
      wasmStatus.support &&
      finishSlider
    ) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        onReadyCallback(true);
      }
    }

    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onWasmLoadFail();
    }
  };

  const handleSwitchCamera = async (e: any) => {
    if (e.target.value === SWITCH_DEVICE) {
      setStep(STEPS?.SWITCH_DEVICE);
      return;
    }
    setDeviceId(e.target.value);
    // @ts-ignore
    const { capabilities } = await switchCamera(null, e.target.value);
    if (isIphoneCC(capabilities)) {
      await setResolutionForIphoneCC();
    }
    setTimeout(() => {
      onSwitchCamera(true);
    }, 3000);
  };

  const finishInstructionSlider = () => {
    setFinishSlider(true);
  };

  const renderComponent = () => {
    if (!ready) {
      if (!hideInstructionOverlay) {
        const instructionOverlayStyle = {
          height: isDocumentScan || isPassport ? "99%" : "",
        };
        return (
          <div className="instructionOverlay" style={instructionOverlayStyle}>
            <InstructionsStepper
              finishInstructionSlider={finishInstructionSlider}
              isDocumentScan={isDocumentScan}
              isPassport={isPassport}
            />
          </div>
        );
      } else {
        return (
          <div className="overlayLoader">
            <CircularProgress />
          </div>
        );
      }
    }
    return null;
  };

  const renderCameraSelect = () => {
    if (ready) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 2,
            width: "100%",
            justifyContent: "space-between",
          }}
          className="mainCameraSelectWrap"
        >
          <label style={{ color: "#000", paddingRight: 5 }}>
            Select Camera:
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={deviceId || device}
            onChange={(e: any) => handleSwitchCamera(e)}
            className="cameraSelect"
          >
            {(devicesList?.length ? devicesList : devices).map(
              (e: { label: string; value: string }, index: number) => {
                return (
                  <MenuItem id={e.value} value={e.value} key={e.value}>
                    {e.label}
                  </MenuItem>
                );
              }
            )}
            {os !== "iOS" && os !== "Android OS" && (
              <MenuItem value={SWITCH_DEVICE}>Switch to Mobile Device</MenuItem>
            )}
          </Select>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.cameraContainer} style={style}>
      {renderComponent()}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {renderCameraSelect()}
      </div>
      {message && !canvasLayout && (
        <div className={styles.enrollDisplay}>
          <span> {message} </span>
        </div>
      )}
      {ready && !hideDocumentFrame && <div className={classes.documentBarCodeOverlay} />}
      <div
        style={{
          position: "relative",
        }}
      >
        <video
          id="userVideo"
          className={`
            ${styles.cameraDisplay} 
            ${
              isBack || isDocumentScan || isPassport ? "" : styles.mirrored
            }  videoCamera`}
          muted
          autoPlay
          playsInline
        />
        {canvasLayout && (
          <CanvasLayout
            setStep={setStep}
            loader={loader}
            ready={ready}
            hideMessage={() => {}}
            enrollOneFaProgress={enrollOneFaProgress}
            message={message}
            isDocument={isDocumentScan}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default Camera;
