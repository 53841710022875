import axios from "axios";
import {
  AUTH_VERIFICATION_ID,
  privateIdDomain,
  PROOF_TEMPLATE_ID,
  TEST_CREDENTIALS_ID,
  TEST_PASSWORD,
} from "../constants/vc-dock";

import axiosInt from "./orchestration";

// const axiosInt = axios.create({
//   baseURL: process.env.REACT_APP_API_ORCHESTRATION ?? "https://api-testnet.dock.io",
// });

// axiosInt.interceptors.request.use(
//   async (config) => {
//     config.headers.authorization =
//       // "Bearer eyJzY29wZXMiOlsidGVzdCIsImFsbCJdLCJzdWIiOiI5NDM0IiwiaWF0IjoxNjkwMTM0OTYyLCJleHAiOjQ3Njk0MzA5NjJ9.xv3hYvnxc49QQeImetmfM84MMJ77IRSE4ucSjkWB0KGNXd1QZ_fO0yT4pPQ-adNjqAumHXM3wdwoz6opDxKAeg";
//       "Bearer eyJzY29wZXMiOlsidGVzdCIsImFsbCJdLCJzdWIiOiI5NjM1IiwiaWF0IjoxNjkxNDIxMjk0LCJleHAiOjQ3NzA3MTcyOTR9.36SwCaJhE9UVgLwNIwTE3tprhLBDyZ9TavMYRP6KmyVoFNkzqkh6FXyfwzKapWg10-w05ldolQZ24-lr7dZsRg";
//     return Promise.resolve(config);
//   },
//   (error) => Promise.reject(error)
// );
//
// axiosInt.interceptors.response.use(
//   (response) => response.data,
//   (error) => {
//     return Promise.reject(error.response);
//   }
// );

const issueCredentials = (userId: string) => {
  try {
    return axiosInt.post(`/v2/users/vc/credentials/${userId}`);
  } catch (e) {
    return e;
  }
};

const verifyCredentials = async (userId: string) => {
  try {
    return axiosInt.get(`/v2/users/vc/verify/${userId}`);
  } catch (e) {
    return e;
  }
};

const generateDidForUser = () => {
  try {
    return axiosInt.post("/dids", { type: "key" });
  } catch (e) {
    return e;
  }
};

const getCredentialDetails = (userId: string) => {
  try {
    return axiosInt.get(`/v2/users/vc/credentials/${userId}`);
  } catch (e) {
    return e;
  }
};

const viewPDF = async (userId: string) => {
  try {
    return axiosInt.get(`/v2/users/vc/pdf/${userId}`, { responseType: "blob" });
  } catch (e) {
    return e;
  }
};

const deleteCredentials = async (credentialsId: string) => {
  try {
    return axiosInt.delete(`/v2/users/vc/credentials/${credentialsId}`);
  } catch (e) {
    return e;
  }
};

const createProofRequest = () => {
  try {
    return axiosInt.post(`/v2/users/vc/proof-request/${PROOF_TEMPLATE_ID}`, {
      domain: privateIdDomain,
    });
  } catch (e) {
    return e;
  }
};

const createAuthProofRequest = () => {
  try {
    return axiosInt.post(`/proof-templates/${AUTH_VERIFICATION_ID}/request`, {
      domain: privateIdDomain,
    });
  } catch (e) {
    return e;
  }
};

const createPresentations = (payload: any) => {
  try {
    return axiosInt.post("/v2/users/vc/presentations", payload);
  } catch (e) {
    return e;
  }
};

const sendPresentationProofRequest = (proofRequestId: string, payload: any) => {
  try {
    return axiosInt.post(
      `/v2/users/vc/proof-requests/${proofRequestId}/send-presentation`,
      payload
    );
  } catch (e) {
    return e;
  }
};

const getAuthProofRequest = (authProofRequestId: string) => {
  try {
    return axiosInt.post(`/v2/users/vc/auth-proof-request/${authProofRequestId}`);
  } catch (e) {
    return e;
  }
};

export {
  issueCredentials,
  verifyCredentials,
  generateDidForUser,
  viewPDF,
  getCredentialDetails,
  createProofRequest,
  createPresentations,
  sendPresentationProofRequest,
  deleteCredentials,
  createAuthProofRequest,
  getAuthProofRequest,
};
