import { useState } from "react";
import { faceLogin } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";


let stopLogin = false;
let showError = false;

const useFaceLogin = (
    setShowSuccess: any = () => { },
    onSetStatus?: (e: number) => void,
    onNotEnrolled: () => void = () => { },
    onFaceNotFound: () => void = () => { },
) => {
    const [faceLoginWithLivenessMessage, setPredictMessage] = useState("");
    const [faceLoginInputImageData, setFaceLoginInputImageData] =
        useState<any>(null);
    const [faceLoginData, setPredictData] = useState<any>(null);

    const setStopLogin = () => {
        stopLogin = true;
    }

    let tries = 0;
    let intervalID: any = null;

    let currentSecond = 0;
    let userNotEnrolledTries = 5;

    const doFaceLogin = async () => {
        // eslint-disable-next-line no-unused-vars
        const inputImage = await faceLogin(
            callback,
            {
                input_image_format: "rgba",
            },
            undefined,
        );

        setFaceLoginInputImageData(inputImage);
        if (!intervalID) {
            intervalID = setInterval(() => {
                currentSecond++;
            }, 1000);
        }
    };

    const callback = async (result: any) => {
        if (!stopLogin) {
            onSetStatus?.(result?.returnValue?.status);
            if (result?.returnValue?.error) {
                setPredictMessage("Please position your face in the center of the circle");
                return;
            }

            if (result.returnValue?.status === 0) {
                handleSuccess(result.returnValue);
                return;
            } else {
                handleError(result.returnValue);
            }
        } else {
            stopLogin = false;
        }
    };

    const handleSuccess = (returnValue: any) => {
        const { status } = returnValue;
        setPredictMessage(getStatusMessage(status));
        setPredictData(returnValue);
        setShowSuccess(returnValue);
    };

    const handleError = (returnValue: any) => {
        const { message = "", status = -100 } = returnValue || {};
        if (!showError) {
            showError = true;
            setPredictMessage(status === -1 || status === -100 ? "Please position your face in the center of the circle" : getStatusMessage(status));
            setTimeout(() => {
                showError = false;
            }, 2000);
        }
        if (status === -1 && message === "User not enrolled") {
            handleUserNotEnrolled();
            return;
        }
        if (currentSecond >= 20) {
            handleFaceNotFound();
            return;
        }
        doFaceLogin();
    };

    const handleUserNotEnrolled = () => {
        userNotEnrolledTries--;
        if (userNotEnrolledTries <= 0) {
            clearInterval(intervalID);
            currentSecond = 0;
            userNotEnrolledTries = 5;
            onNotEnrolled();
        }
        else {
            doFaceLogin();
        }
    };

    const handleFaceNotFound = () => {
        clearInterval(intervalID);
        currentSecond = 0;
        onFaceNotFound();
    };

    return {
        doFaceLogin,
        faceLoginWithLivenessMessage,
        faceLoginInputImageData,
        faceLoginData,
        setStopLogin,
    };
};

export default useFaceLogin;
