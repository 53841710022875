import { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import {
  getStatusFromUser,
  navigateToUrl,
  stopCamera,
  SUCCESS,
} from "../../utils";
import successThumb from "../../assets/success.gif";
import shield from "../../assets/shield.png";
import homeStyles from "../../styles/Home.module.css";
import config from "../../config";
import {
  createVerificationSession,
  getUser,
  postLoginAttemp,
} from "../../services/api";
import { useNavigate } from "react-router";
import useToast from "../../utils/useToast";
import Camera from "../Camera";
import { CvsRequestContext } from "../../context/RequestContext";
import useFaceLoginWithLivenessCheck from "../../hooks/useFaceLoginWithLiveness";
import { LoginContext } from "../../context/LoginContext";
import STEPS from "../../pages/register/steps";
import {
  ACCOUNT_CREATED,
  AUTHENTICATION_SUCCESSFUL,
  BASE_URL,
} from "../../constants";
import { startRegistration } from "@simplewebauthn/browser";
import useQuery from "../../hooks/useQueryParams";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

const Success = ({
  skin,
  matchesSM,
  postToOidc = () => {},
  message,
  fromLogin,
  setStep,
}: {
  skin: string;
  matchesSM: boolean;
  postToOidc?: () => void;
  message?: string;
  fromLogin?: boolean;
  setStep?: (e: any) => void;
}) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  const [loading, setLoading] = useState(false);
  const [isInitialPredict, setInitialPredict] = useState(true);
  const [isCameraScan, setIsCameraScan] = useState(false);
  const isScanning = false;
  const [isUserVerify, setIsUserVerify] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const mainTheme = Theme;
  const context = useContext(UserContext);
  const palette: { [key: string]: any } = mainTheme.palette;
  const requestContext = useContext(CvsRequestContext);
  const loginContext = useContext(LoginContext);
  let query = useQuery();
  const consumerFlow = query.get("bypassSsn");
  useEffect(() => {
    handleOidcRequest();
  }, [requestContext]);

  const handleOidcRequest = () => {
    if (requestContext.requestFromOIDC) {
      setTimeout(() => {
        postToOidc();
      }, 3000);
    }
  };

  const postToAttempt = async () => {
    const userData = {
      status: "success",
      predictedGUID: loginContext.predictedGUID,
      barcodeHash: loginContext.barcodeHash,
      error: false,
    };
    if (loginContext.predictedGUID) {
      await postLoginAttemp(userData);
    }
  };

  useEffect(() => {
    postToAttempt();
  }, []);

  const createVerification = async () => {
    if (user?._id && user?.token) {
      navigateToUrl(user?.successUrl, user?.token);
      return;
    }
    setLoading(true);
    const payload = {
      ...config.clientConfig,
    };
    const result: any = await createVerificationSession(payload);
    if (result?.token) {
      navigate(
        `/register?token=${result?.token}&skin=${skin}&bypassSsn=${consumerFlow}`
      );
    }
    setLoading(false);
  };

  const handlePredictSuccess = async (result: any) => {
    switch (result.status) {
      case -100:
        setInitialPredict(false);
        return setIsCameraScan(true);
      case 0: {
        localStorage.setItem("uuid", JSON.stringify(result?.PI?.uuid || {}));
        const payload = {
          guid: result?.PI?.guid,
        };
        const data: any = await getUser(payload);
        if (data?.orchestrationStatus === "Not Initiated") {
          showToast(data?.orchestrationStatus, "error");
          return createVerification();
        } else {
          setIsUserVerify(true);
          localStorage.setItem("user", JSON.stringify(data || {}));
          nextStep(data);
          stopCamera();
        }
        return false;
      }
      default: {
        showToast(result?.message, "error");
        return createVerification();
      }
    }
  };

  const retryTimes = isCameraScan ? 12 : 5;

  const { faceLoginWithLiveness, faceLoginWithLivenessMessage } =
    useFaceLoginWithLivenessCheck(
      handlePredictSuccess,
      undefined,
      retryTimes,
      isInitialPredict
    );

  const nextStep = async (userParam: any) => {
    const user = userParam || JSON.parse(localStorage.getItem("user") ?? "{}");
    if (!user._id) return;
    const userStatus = getStatusFromUser(user);
    setTimeout(async () => {
      setIsUserVerify(false);
      stopCamera();
      if (userStatus === SUCCESS) {
        showToast("Logged in Successfully", "success");
        const userData = {
          status: "success",
          predictedGUID: loginContext.predictedGUID,
          barcodeHash: loginContext.barcodeHash,
          error: false,
        };
        await postLoginAttemp(userData);
      } else {
        showToast(
          "You have not successfully completed your verification",
          "error"
        );
        const userData = {
          status: "failure",
          predictedGUID: loginContext.predictedGUID || "none",
          barcodeHash: loginContext.barcodeHash,
          error: true,
          errorMessage: "Authentication failed - Verification not complete",
        };
        await postLoginAttemp(userData);
      }
      navigateWithQueryParams("/");
    }, 2000);
  };

  useEffect(() => {
    // consumerFlow && handleEnrollSuccess();
    setTimeout(() => {
      const status = getStatusFromUser(window.localStorage.getItem("user"));
      const { successUrl } = context.verificationSession;
      if (successUrl && status === SUCCESS) {
        window.location.href = successUrl;
      }
    }, 2000);
  }, []);

  // Enroll ONEFA
  const handleEnrollSuccess = async () => {
    const response = await fetch(BASE_URL + "/generate-registration-options", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid }), // body data type must match "Content-Type" header
    });
    let attResp;
    try {
      const opts = await response.json();
      attResp = await startRegistration(opts);
    } catch (error: any) {
      return;
    }

    const verificationResp = await fetch(BASE_URL + "/verify-registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ data: attResp, uuid: uuid }),
    });

    const verificationJSON = await verificationResp.json();
    if (verificationJSON?.verified) {
      showToast("Passkey successfully generated", "success");
    }
  };
  return (
    <>
      {isCameraScan ? (
        <>
          <Box
            position={"relative"}
            padding={"10px 10px"}
            mt={0}
            mb={1}
            pr={"12px"}
          >
            {isUserVerify && (
              <Box style={styles.overlayCamera as React.CSSProperties}>
                <img
                  src={shield}
                  alt="shield"
                  style={styles.shield as React.CSSProperties}
                />
              </Box>
            )}
            <div id="canvasInput" className={homeStyles.container}>
              <Camera
                onReadyCallback={faceLoginWithLiveness}
                onSwitchCamera={faceLoginWithLiveness}
                message={faceLoginWithLivenessMessage}
                style={{ height: "unset" }}
              ></Camera>
            </div>
          </Box>
        </>
      ) : (
        <>
          <Grid
            style={{
              ...styles.cardGrid,
              backgroundColor: matchesSM ? "" : "#e9e9e9",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            className={classes.cardGridMobile}
          >
            {isScanning && (
              <div style={{ display: "none" }}>
                <Camera
                  onReadyCallback={faceLoginWithLiveness}
                  onSwitchCamera={faceLoginWithLiveness}
                  style={{ height: "unset" }}
                ></Camera>
              </div>
            )}
            {/* <img
              src={successThumb}
              alt=""
              className={classes.checkIcon}
              width={400}
            /> */}
            <Box>
              <Typography
                component="p"
                textAlign={"center"}
                fontSize={25}
                fontWeight={matchesSM ? 'bold' : 500}
                lineHeight={1.5}
                mt={2}
                className={classes.cardInnerHeading}
                px={matchesSM ? 5 : ""}
              >
                {message ??
                  "Congratulations! You have been successfully verified."}
              </Typography>
              {uuid?.length && (
                <Typography
                  component="p"
                  textAlign={"center"}
                  fontSize={15}
                  fontWeight={500}
                  lineHeight={1.5}
                  mt={0}
                  className={classes.cardInnerHeading}
                  style={{ wordBreak: "break-all" }}
                >
                  UUID : {uuid}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid style={{ marginBottom: 20 }}>
            {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
            {message === ACCOUNT_CREATED ? (
              <>
                <Button
                  variant="contained"
                  color={"inherit"}
                  style={styles.continueButton}
                  className={classes.continueButton}
                  onClick={() => navigateWithQueryParams("/")}
                >
                  <Typography
                    component="p"
                    color={palette?.[skin]?.text}
                    textAlign="center"
                    fontWeight={600}
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    textTransform="capitalize"
                  >
                    Return to home page
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color={"inherit"}
                  style={styles.continueButton}
                  className={classes.continueButton}
                  onClick={() => setStep?.(STEPS.FEEDBACK)}
                >
                  <Typography
                    component="p"
                    color={palette?.[skin]?.text}
                    textAlign="center"
                    fontWeight={600}
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    textTransform="capitalize"
                  >
                    Provide feedback
                  </Typography>
                </Button>
              </>
            ) : message === AUTHENTICATION_SUCCESSFUL ? (
              <>
                <Button
                  variant="contained"
                  color={"inherit"}
                  style={styles.continueButton}
                  className={classes.continueButton}
                  onClick={() => navigateWithQueryParams("/")}
                >
                  <Typography
                    component="p"
                    color={palette?.[skin]?.text}
                    textAlign="center"
                    fontWeight={600}
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    textTransform="capitalize"
                  >
                    Return to home page
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color={"inherit"}
                  style={styles.continueButton}
                  className={classes.continueButton}
                  onClick={() => setStep?.(STEPS.FEEDBACK)}
                >
                  <Typography
                    component="p"
                    color={palette?.[skin]?.text}
                    textAlign="center"
                    fontWeight={600}
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    textTransform="capitalize"
                  >
                    Provide feedback
                  </Typography>
                </Button>
              </>
            ) : (
              !requestContext.requestFromOIDC && (
                <Box>
                  {fromLogin ? (
                    <Button
                      variant="contained"
                      color={"inherit"}
                      style={styles.continueButton}
                      className={classes.continueButton}
                      onClick={() => navigateWithQueryParams("/")}
                    >
                      <Typography
                        component="p"
                        color={palette?.[skin]?.text}
                        textAlign="center"
                        fontWeight={600}
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                        textTransform="capitalize"
                      >
                        {loading ? (
                          <CircularProgress className={classes.scanLoader} />
                        ) : (
                          "Continue"
                        )}
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color={"inherit"}
                      style={styles.continueButton}
                      className={classes.continueButton}
                      onClick={createVerification}
                    >
                      <Typography
                        component="p"
                        color={palette?.[skin]?.text}
                        textAlign="center"
                        fontWeight={600}
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                        textTransform="capitalize"
                      >
                        {loading ? (
                          <CircularProgress className={classes.scanLoader} />
                        ) : (
                          "Start again"
                        )}
                      </Typography>
                    </Button>
                  )}
                </Box>
              )
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Success;
