import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useContext } from "react";
import {
  updateUser,
  updateUserManualInputWithSession,
} from "@privateid/cryptonets-web-sdk/dist/apiUtils";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import { UserContext } from "../../../context/UserContext";
import STEPS from "../../../pages/register/steps";
import MonthPicker from "../../DatePicker";
import CvsButton from "../../Button";
import * as yup from "yup";
import dayjs from "dayjs";
import { federationGetHashData } from "../../../services/api";
import { UserContextV2 } from "../../../context/UserContextV2";

export const personalDetailsSchema = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  dob: yup
    .date()
    .test("dob", "Please enter valid DOB", function (value, ctx) {
      if (!value) return true;
      const valid = dayjs(value).isValid();
      return !valid ? ctx.createError() : valid;
    })
    .typeError("Please enter a valid DOB (MM/DD/YYYY)."),
});
const PersonalDetails = ({
  setStep,
  skin,
  matchesSM,
  setPrevStep,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
  setPrevStep: (e: string) => void;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState();
  const palette: { [key: string]: any } = mainTheme.palette;
  const [formData, setFormData] = useState<any>({});
  const [dob, setDob] = useState(null);
  const context = useContext(UserContext);
  const { id } = context;
  const userContextV2 = useContext(UserContextV2);

  const onInputChange = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = async (e?: any) => {
    e?.preventDefault();
    try {
      const isFormValid = await personalDetailsSchema.isValid(formData, {
        abortEarly: false, // Prevent aborting validation after first error
      });
      if (isFormValid) {
        // If form is valid, continue submission.
        setErrors(undefined);
        setPrevStep(STEPS.REGISTER_FORM);
        setLoader(true);
        const year = formData.dob.slice(4);
        const day = formData.dob.slice(2, 4);
        const month = formData.dob.slice(0, 2);
        const dob = year + month + day;
        // const payload = {
        //   preferred: {
        //     firstName: formData.firstName,
        //     lastName: formData.lastName,
        //     dob: dob,
        //     ssn4: context.ssn4,
        //   },
        //   guid: context.guid,
        // };

        await updateUserManualInputWithSession({
          sessionToken: userContextV2.sessionToken,
          firstName: formData.firstName,
          lastName: formData.lastName,
          dob: dob,
        });

        // const federationHash = await federationGetHashData(payload);
        // const updateUserResult: any = await updateUser({
        //   id,
        //   // @ts-ignore
        //   attributes: {
        //     federationHash: federationHash.responseHash || "",
        //     preferred: { ...formData },
        //   },
        // });
        // if (updateUserResult?._id) {
          setStep(STEPS.ADDRESS_DETAIL);
          setLoader(false);
        // }
      } else {
        // If form is not valßid, check which fields are incorrect:
        personalDetailsSchema
          .validate(formData, { abortEarly: false })
          .catch((err) => {
            // Collect all errors in { fieldName: boolean } format:
            const errors = err.inner.reduce(
              (acc: any, error: { path: any; message: any }) => {
                return {
                  ...acc,
                  [error.path]: error.message,
                };
              },
              {}
            );

            // Update form errors state:
            setErrors(errors);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <form onSubmit={handleContinue} style={{ height: "100%" }}>
        <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={16}
            fontWeight={700}
            lineHeight={1.5}
            mt={3}
            mb={2}
            className={classes.cardInnerHeading}
          >
            Please enter your personal details below.
          </Typography>
          <Box width={"100%"}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="First name"
              type="text"
              color={errors?.["firstName"] ? "error" : "primary"}
              helperText={errors?.["firstName"]}
              placeholder="Your legal first name"
              name="firstName"
              required={true}
              sx={{
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mt: 2,
                mb: 2,
              }}
              onChange={onInputChange}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Last name"
              type="text"
              color={errors?.["lastName"] ? "error" : "primary"}
              helperText={errors?.["lastName"]}
              required={true}
              placeholder="Your legal last name"
              name="lastName"
              sx={{
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mt: 0,
                mb: 2,
              }}
              onChange={onInputChange}
            />
            <Box className="datePickerWrap" width="100%">
              <MonthPicker
                day={true}
                helperText={errors?.["dob"]}
                value={dob as any}
                setEnrollData={(newValue: any) => {
                  console.log("DATE PCIKER:", newValue);
                  setDob(newValue)
                  function formatDate(date: any) {
                    let d = new Date(date),
                      month = "" + (d.getMonth() + 1),
                      day = "" + d.getDate(),
                      year = d.getFullYear();

                    if (month.length < 2) month = "0" + month;
                    if (day.length < 2) day = "0" + day;

                    return [month, day, year].join("");
                  }
                  console.log("formattedDated:", formatDate(newValue));
                  setFormData({
                    ...formData,
                    dob: formatDate(newValue),
                  });
                }}
              />
            </Box>
          </Box>
        </Grid>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        <Box mb={"52px"}>
          <CvsButton
            skin={skin}
            title={
              loader ? (
                <CircularProgress className={classes.scanLoader} />
              ) : (
                "Continue"
              )
            }
            onClick={() => {
              handleContinue();
            }}
          />
        </Box>
      </form>
    </>
  );
};

export default PersonalDetails;
