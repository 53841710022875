import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { getCredentialDetails } from "../../services/vc-dock";
import { headerVisible } from "../../theme";
import { useStyles } from "./styles";
import { Card, NoVcFound } from "../VerifyPresentation";
import { CircularProgress } from "@mui/material";

const ViewCredentials = ({ theme, skin }: any) => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const classes = useStyles();
  const [cardData, setCardData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const viewCredentials = async () => {
    try{
      const credentialDetails = await getCredentialDetails(
          user._id
      );
      setCardData(credentialDetails);
      setLoading(false);
      return;
    }
    catch (e){
      setLoading(false)
    }
  };
  useEffect(() => {
    viewCredentials();
  }, []);

  return (
    <>
      {headerVisible?.includes(skin) && <Header theme={theme} />}
      <div className={classes.pageWrap}>
        {loading ? (
          <div className={classes.noVcWrap}>
            <CircularProgress />
          </div>
        ) : cardData ? (
          <>
            <h3 className={classes.heading}>Credentials</h3>
            <Card data={cardData} user={user} setCardData={setCardData} />
          </>
        ) : (
          <NoVcFound skin={skin} classes={classes} user={user} setCardData={setCardData} />
        )}
      </div>
    </>
  );
};

export default ViewCredentials;
