// @ts-nocheck
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { enroll1FA } from "@privateid/cryptonets-web-sdk";
import {
  getStatusMessage,
  MessageType,
} from "@privateid/cryptonets-web-sdk/dist/utils";
import { useSearchParams } from "react-router-dom";

const useEnrollOneFaWithLiveness = (
  element,
  onSuccess,
  retryTimes = 4,
  deviceId = null
) => {
  const [searchParams] = useSearchParams();
  const [enrollStatus, setEnrollStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [enrollPortrait, setEnrollPortrait] = useState<ImageData>();
  const [enrollData, setEnrollData] = useState<
    { puid: string; guid: string } | undefined
  >();

  let showError = false;

  const enrollUserOneFa = async (token = "", skipAntispoof = false) => {
    if (token) {
      setProgress((p) => p + 20);
    } else {
      setProgress(0);
    }
    // eslint-disable-next-line no-unused-vars
    try {
      const bestImage = await enroll1FA(callback, {
        input_image_format: "rgba",
        mf_token: token,
        skip_antispoof:
          searchParams.get("skipAntispoof") === "true" || skipAntispoof,
      });
      if (bestImage) {
        setEnrollPortrait(
          new ImageData(bestImage.imageData, bestImage.width, bestImage.height)
        );
      }
    } catch (e) {
      // enrollUserOneFa(token);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  // const RerunAction = new Rerun(enrollUserOneFa);

  const handleFailureCase = (result, reScanIfFail) => {
    if (result.returnValue.status === -1) {
      const regex = /offline/gi;
      if (regex.test(result.returnValue.message)) {
        setEnrollStatus("Low Bandwidth / No Internet Detected");
      } else {
        setEnrollStatus("Please try again");
      }
      setProgress(0);
      setTimeout(() => {
        enrollUserOneFa("", skipAntispoofProcess);
      }, 3000);
    } else if (result.returnValue.validation_status.length > 0) {
      if (result.returnValue.validation_status[0].anti_spoof_performed) {
        if (
          (result.returnValue.validation_status[0].anti_spoof_status === 4 &&
            result.returnValue.validation_status[0].status === 22) ||
          result.returnValue.validation_status[0].status === 23
        ) {
          setEnrollStatus(
            getStatusMessage(result.returnValue.validation_status[0].status)
          );
        } else if (
          (result.returnValue.validation_status[0].anti_spoof_performed &&
            result.returnValue.validation_status[0].anti_spoof_status === 0 &&
            result.returnValue.validation_status[0].status === 10) ||
          result.returnValue.validation_status[0].status === 11
        ) {
          setEnrollStatus(
            getStatusMessage(result.returnValue.validation_status[0].status)
          );
        } else {
          setEnrollStatus(
            getStatusMessage(
              result.returnValue.validation_status[0].anti_spoof_status,
              MessageType.antispoofStatus
            )
          );
        }
      } else {
        setEnrollStatus(
          getStatusMessage(result.returnValue.validation_status[0].status)
        );
      }
      const enrollToken =
        result.returnValue.validation_status[0].status === 0
          ? result.returnValue.validation_status[0].enroll_token
          : "";
      enrollUserOneFa(enrollToken);
    } else {
      setEnrollStatus(null);
      if (reScanIfFail) {
        enrollUserOneFa();
      }
    }
  };

  const callback = async (result) => {
    console.log(result);
    if (result.returnValue.status === 0) {
      if (result.returnValue.guid && result.returnValue.puid) {
        setProgress((p) => p + 20);
        setEnrollStatus("Enroll Success");
        setTimeout(() => {
          onSuccess(result.returnValue);
          setEnrollData(result.returnValue);
        }, 3000);
      } else {
        handleFailureCase(result, false);
      }
    } else {
      handleFailureCase(result, true);
    }
  };

  return {
    enrollStatus,
    enrollUserOneFa,
    progress,
    enrollPortrait,
    enrollData,
  };
};

export default useEnrollOneFaWithLiveness;
