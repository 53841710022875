import { useState } from "react";
import Header from "../../components/Header";
import { headerVisible } from "../../theme";
import HomeComponent from "../../components/HomeComponent";
import SignInWithMessage from "../../components/SignInWithMessage";

interface SignInProps {
  theme: string;
  skin: string;
  withBarcode: boolean;
  withVerifiedCredentialsAuth: boolean;
  requestProof?: boolean;
}

const AAL2 = ({ skin, withBarcode, withVerifiedCredentialsAuth, requestProof }: SignInProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const themeName = skin || "primary";

  return (
    <>
      {headerVisible?.includes(skin) && <Header theme={themeName} />}
      <div className={`homePageWrapper homeComponent ${!isModalOpen && 'homeBG'}`}>
        {!isModalOpen && <HomeComponent theme={themeName} />}
        <SignInWithMessage
          skin={skin}
          onModalVisible={(e) => setIsModalOpen(e)}
          withBarcode={withBarcode}
          withVerifiedCredentialsAuth={withVerifiedCredentialsAuth}
          twoFactorAuthentication={true}
          requestProof={requestProof}
        />
      </div>
    </>
  );
};

export default AAL2;
