import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import STEPS from "../../pages/register/steps";

import { useStyles, styles } from "../../styles/styles";
import passkeyImage from "../../assets/passkey.png";
import { theme as Theme } from "../../theme";
import CvsButton from "../Button";
import { BASE_URL } from "../../constants";
import { startRegistration } from "@simplewebauthn/browser";
import useToast from "../../utils/useToast";
import { useEffect } from "react";
import useQuery from "../../hooks/useQueryParams";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

const Passkey = ({ setStep, skin }: { setStep: any; skin: string }) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const { showToast } = useToast();
  let query = useQuery();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const consumerFlow = query.get("bypassSsn");

  useEffect(() => {
    consumerFlow && handleEnrollSuccess();
  }, []);

  // Enroll ONEFA
  const handleEnrollSuccess = async () => {
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    const response = await fetch(BASE_URL + "/generate-registration-options", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid }), // body data type must match "Content-Type" header
    });
    let attResp;
    try {
      const opts = await response.json();
      attResp = await startRegistration(opts);
    } catch (error: any) {
      setStep(STEPS.SUCCESS);
      return;
    }

    const verificationResp = await fetch(BASE_URL + "/verify-registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ data: attResp, uuid: uuid }),
    });

    const verificationJSON = await verificationResp.json();
    if (verificationJSON?.verified) {
      showToast("Passkey successfully generated", "success");
      setStep(STEPS.SUCCESS);
    }
  };
  return (
    <>
      <Grid
        style={styles.cardGrid}
        className={classes.cardGridMobile}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          backgroundColor: "#ddd",
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <img src={passkeyImage} alt="" width="100px" />
          <Typography
            component="p"
            textAlign={"left"}
            fontSize={28}
            fontWeight={700}
            lineHeight={1.5}
            ml={1}
            className={classes.cardInnerHeading}
            color="grey"
            width={"215px"}
          >
            Create Your Priflo Passkey
          </Typography>
        </Box>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Box marginTop={1}>
        <CvsButton
          title={"Return To Home Page"}
          onClick={() => navigateWithQueryParams("/")}
          skin={skin}
        />
        <CvsButton
          title={"Provide Feedback"}
          onClick={() => setStep(STEPS.FEEDBACK)}
          skin={skin}
        />
      </Box>
    </>
  );
};

export default Passkey;
