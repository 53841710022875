import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useRef, useContext } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import {
  createUserWithSession,
} from "@privateid/cryptonets-web-sdk/dist/apiUtils";
import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import { createUserID } from "../../utils";
import STEPS from "../../pages/register/steps";
import useToast from "../../utils/useToast";
import { ssnInputsSchema } from "../../constants/schema";
import CvsButton from "../Button";
import { useSearchParams } from "react-router-dom";
import { UserContextV2 } from "../../context/UserContextV2";

const RegisterInputsSsn = ({
  setStep,
  skin,
  matchesSM,
  setPrevStep,
}: {
  setStep: any;
  skin: string;
  matchesSM: boolean;
  setPrevStep: (e: string) => void;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const [searchParams] = useSearchParams();
  const { showToast } = useToast();
  const [loader, setLoader] = useState(false);
  const [isDisable, setIsDisable] = useState<any>({
    ssn: false,
  });
  const palette: { [key: string]: any } = mainTheme.palette;

  const ssn4Ref = useRef<HTMLFormElement | null>(null);
  const context = useContext(UserContext);

  const { phoneNumber, setSSN4, setId, tokenParams, email } = context;
  const [showSSN4Error, setShowSSN4Error] = useState({
    error: false,
    message: "",
  });
  const handleCheckSSN4Input = () => {
    ssnInputsSchema
      .validate({
        ssn: ssn4Ref?.current?.value || 0,
      })
      .catch((error: { message: any }) =>
        setShowSSN4Error({ error: true, message: error?.message })
      );
  };

  const userContextV2 = useContext(UserContextV2);
  const handleContinue = async (e?: any) => {
    e?.preventDefault();
    const ialValue: any = searchParams.get("IAL");
    setPrevStep(STEPS.REGISTER_FORM_SSN);

    if (ssn4Ref?.current?.value.length !== 4 && !isDisable.ssn) {
      showToast("Enter SSN4", "error");
      handleCheckSSN4Input();
    } else if (ssn4Ref?.current?.value.length === 4 || isDisable.ssn) {
      setLoader(true);
      const inputSSN4 = ssn4Ref?.current?.value;
      setSSN4(inputSSN4);
      const newID = await createUserID();
      setId(newID);

      userContextV2.setSSN(inputSSN4);

      const resultV2 = await createUserWithSession({
        sessionToken: userContextV2.sessionToken,
        phone: userContextV2.phoneNumber,
        email: userContextV2.email,
        ssn: inputSSN4,
      });

      setStep(STEPS.ENROLL);
      setLoader(false);
    }
  };

  enum FocusHandlerEnum {
    ssn4 = "ssn4",
  }

  const handleOnFocus = (handle: FocusHandlerEnum) => {
    if (handle === FocusHandlerEnum.ssn4)
      setShowSSN4Error({ error: false, message: "" });
  };

  const renderButton = () => (
    <CvsButton
      skin={skin}
      title={
        loader ? (
          <CircularProgress className={classes.scanLoader} />
        ) : (
          "Continue"
        )
      }
      onClick={() => {
        handleContinue();
      }}
    />
  );

  return (
    <>
      <form onSubmit={handleContinue} style={{ height: "100%" }}>
        <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={16}
            fontWeight={500}
            lineHeight={1.5}
            mt={3}
            mb={0}
            className={classes.cardInnerHeading}
            px={5}
          >
            Please enter the last four digits of your US Social Security Number
            (SSN)
          </Typography>
          <Typography
            component="p"
            textAlign={"center"}
            fontSize={13}
            fontWeight={500}
            lineHeight={1.5}
            mt={0}
            mb={2}
            className={classes.cardInnerHeading}
            px={5}
            color={"#ccc"}
          >
            or US Individual Taxpayer Identification Number (ITIN).
          </Typography>
          <Box width={"100%"}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="SSN4"
              type="tel"
              placeholder="SSN4 – Social Security Number"
              name="SSN4"
              InputProps={{
                startAdornment: <AccountBoxIcon sx={{ pr: 1 }} />,
              }}
              inputProps={{
                maxLength: 4,
              }}
              inputRef={ssn4Ref}
              onBlur={handleCheckSSN4Input}
              onFocus={() => {
                handleOnFocus(FocusHandlerEnum.ssn4);
              }}
              color={showSSN4Error.error ? "error" : "primary"}
              helperText={showSSN4Error.error ? showSSN4Error.message : ""}
              sx={{
                "& .MuiFormHelperText-contained": {
                  color: "red",
                },
                mt: 2,
              }}
              disabled={isDisable.ssn}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDisable.ssn}
                  onChange={() =>
                    setIsDisable({ ...isDisable, ssn: !isDisable.ssn })
                  }
                />
              }
              label="I do not know my SSN"
            />
            {matchesSM && <Box mt={"2rem"}>{renderButton()}</Box>}
          </Box>
        </Grid>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        {!matchesSM && <Box mb={"52px"}>{renderButton()}</Box>}
      </form>
    </>
  );
};

export default RegisterInputsSsn;
