import {
  ISSUER_ID,
  privateIdDomain,
  SCHEMA_ID,
  SCHEMA_NAME,
} from "../constants/vc-dock";

export const generateVCPayload = (userDetails: any) => {
  const {
    uuid,
    customerId,
    guid,
    // phone,
    // govId: { firstName, lastName, dob, address },
    // ssn,
    // email,
  } = userDetails;
  //{
  //   "anchor": false,
  //   "password": "shiven",
  //   "persist": true,
  //   "emailMessage": "",
  //   "credential": {
  //     "name": "Test Bravo",
  //     "type": [
  //       "VerifiableCredential",
  //       "TestBravo"
  //     ],
  //     "issuer": "did:dock:5EJ5Pd3SeYGQestzL8zwC1HRW5LgKP9ZGak6znoTYyKYnAqQ",
  //     "issuanceDate": "2023-08-11T16:58:43.651Z",
  //     "subject": {
  //       "uuid": "123123123123",
  //       "customerId": "123123123",
  //       "guid": "123123",
  //       "phone": "123123",
  //       "firstname": "Shiven",
  //       "lastname": "Juneja",
  //       "address1": "5954",
  //       "city": "Chandigarh",
  //       "state": "Chandigarh",
  //       "zip": "160101",
  //       "date_of_birth": "210493",
  //       "drivers_license_dob": "210493",
  //       "country": "India",
  //       "ssn": "9991",
  //       "email": "shiven@privateid.com"
  //     }
  //   },
  //   "algorithm": "dockbbs+",
  //   "distribute": true
  // }
  return {
    anchor: false,
    password: uuid,
    persist: true,
    recipientEmail: "",
    emailMessage: "",
    credential: {
      schema: SCHEMA_ID,
      name: SCHEMA_NAME,
      type: ["VerifiableCredential", "PIDUUIDTemplate", "TestBravo"],
      issuer: ISSUER_ID,
      issuanceDate: new Date().toISOString(),
      subject: {
        uuid: uuid,
        customerId: customerId,
        guid: guid,
        // phone: phone,
        // firstname: firstName,
        // lastname: lastName,
        // address1: address.addressLine1,
        // city: address.city,
        // state: address.state,
        // zip: address.zipCode,
        // date_of_birth: convertDate(dob),
        // drivers_license_dob: convertDate(dob),
        // country: address.country,
        // ssn: ssn,
        // email: email,
      },
    },
    distribute: true,
  };
};

export const generatePresentationPayload = (
  credential: any,
  challenge: string,
  userDid: string
) => {
  return {
    challenge,
    domain: privateIdDomain,
    holder: userDid,
    credentials: [credential],
  };
};
function convertDate(date: string, toISO = true): string | null {
  if (!date) return null;
  if (date.includes("/") || date.includes("-") || date.includes("Z"))
    return date;

  try {
    const year = date.slice(4);
    const day = date.slice(2, 4);
    const month = date.slice(0, 2);
    return toISO
      ? new Date(`${month}/${day}/${year}`).toISOString()
      : `${year}-${month}-${day}`;
  } catch (err) {
    console.log("EXCEPTION: convertDate", err);
    return date;
  }
}

function createGuid() {
  function p8(s?: boolean) {
    const p = `${Math.random().toString(16)}000000000`.substr(2, 8);
    return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
  }
  return p8() + p8(true) + p8(true) + p8();
}

export function extractPathKeys(inputData: any) {
  // Extract paths from the input data
  const paths =
    inputData.request.input_descriptors[0].constraints.fields[0].path;

  // Extract keys from the paths
  return paths.map((path: string) => path.split(".").pop());
}

export function prepareCredentialsForPresentation(
  credential: any,
  path: string[]
) {
  const credentialsObj: { [x: string]: string } = {};
  path.forEach((k: string) => {
    credentialsObj[k] = credential.credentialSubject[k];
  });
  return { ...credential, credentialSubject: credentialsObj };
}
