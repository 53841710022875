import { useEffect, useState } from "react";
import styles from "../../styles/Home.module.css";
import Camera from "../Camera";
import { getScanFrontColor } from "../../constants";
import Card from "../../assets/card.svg";
import { getFrontDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import useScanHealthcareCard from "../../hooks/useHealthcareScan";
import { doDocumentOCR } from "../../services/api";

const HealthcareScanWrapper = ({
  onSuccess,
  onReadyCallback,
  onFailCallback,
  onCameraFail,
  setOpStatus,
  setStep,
}: {
  onSuccess: (e: any) => void;
  onReadyCallback: (e: boolean) => void;
  onFailCallback: (e: { status: string; message: string }) => void;
  onCameraFail: (e: any) => void;
  setOpStatus: (e: number) => void;
  setStep: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isReady, setIsReady] = useState(false);
  const handleHealthCareScanSuccess = async ({
    croppedCardBase64,
    inputCardBase64,
  }: {
    croppedCardBase64: string;
    inputCardBase64: string;
  }) => {
    if (croppedCardBase64) {
      const getBase64StringFromDataURL = (dataURL: string) =>
        dataURL.replace("data:", "").replace(/^.+,/, "");
      const ocrResult: any = await doDocumentOCR(
        getBase64StringFromDataURL(croppedCardBase64)
      );
      let isHealthCard = false;
      const medicalList = ["medical", "healthcare", "care", "health insurance"];
      ocrResult.map((e: any) => {
        if (e?.BlockType === "WORD" && medicalList.includes(e?.Text)) {
          isHealthCard = true;
        }
      });

      if (isHealthCard) {
        onSuccess?.({ croppedCardBase64, inputCardBase64 });
      } else {
        handleHealthCareScan(true);
      }
    }
  };
  const {
    croppedDocumentBase64,
    doScanHealthcareCard,
    inputImageBase64,
    scanData,
  } = useScanHealthcareCard(() => {});

  useEffect(() => {
    if (croppedDocumentBase64 && inputImageBase64) {
      handleHealthCareScanSuccess({
        croppedCardBase64: croppedDocumentBase64,
        inputCardBase64: inputImageBase64,
      });
    }
  }, [croppedDocumentBase64, inputImageBase64]);

  const handleHealthCareScan = async (e: boolean) => {
    setIsReady(e);
    onReadyCallback?.(e);
    if (e) {
      await doScanHealthcareCard();
    }
  };

  const returnMessage = () => {
    return getFrontDocumentStatusMessage(scanData?.op_status);
  };

  return (
    <div id="canvasInput" className={`${styles.container} documentCamera`}>
      {isReady && (
        <>
          <div
            className={styles.cameraFrame}
            style={{
              borderColor: getScanFrontColor(scanData?.op_status),
            }}
          >
            <img src={Card} alt="" />
          </div>
          <div className={styles.cameraFrameOuter} />
        </>
      )}
      <Camera
        onReadyCallback={handleHealthCareScan}
        onSwitchCamera={handleHealthCareScan}
        onCameraFail={onCameraFail}
        style={{ height: "unset" }}
        mode={"back"}
        requireHD={true}
        message={returnMessage()}
        isDocumentScan={true}
        setStep={setStep}
      ></Camera>
    </div>
  );
};

export default HealthcareScanWrapper;
