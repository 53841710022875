import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme, nameMap } from "../../../theme";
import { navigateToUrl } from "../../../utils";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";

interface CannotVerifyProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  prevStep: string;
}

const CannotVerify = (props: CannotVerifyProps) => {
  const { skin, setStep, prevStep } = props;
  const muiTheme = useTheme();

  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();
  const context = useContext(UserContext);
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign="center"
          fontSize={25}
          fontWeight={700}
          mt={4}
        >
          ARE YOU SURE <br />
          YOU WANT TO EXIT?
        </Typography>
        <Typography
          component="p"
          textAlign="center"
          fontSize={20}
          fontWeight={500}
          mt={7}
        >
          {nameMap[skin] || "Stations"} cannot verify your identity
          <br /> without your consent.
        </Typography>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => setStep(prevStep)}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          Back to consent
        </Typography>
      </Button>
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => navigateToUrl("/", context?.tokenParams)}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          Exit
        </Typography>
      </Button>
    </>
  );
};

export default CannotVerify;
