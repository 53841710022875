import { Box, Grid, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { closeCamera } from "@privateid/cryptonets-web-sdk";

import Header from "../../components/Header";
import Camera from "../../components/Camera";
import homeStyles from "../../styles/Home.module.css";
import { useEffect, useState } from "react";
import HomeModal from "../../components/Modal/homeModal";
import usePrevious from "../../hooks/usePrevious";
import useFaceLoginWithLivenessCheck from "../../hooks/useFaceLoginWithLiveness";
import { ELEMENT_ID } from "../../constants";
interface ContinuousAuthenticationProps {
  theme: string;
  skin: string;
}

let retryTimes = 12;
let invalidCount = 0;
let notEnrolled = 0;

const ContinuousAuthentication = ({ skin }: ContinuousAuthenticationProps) => {
  const [user, setUser] = useState<any>({});
  const [showCamera, setShowCamera] = useState(false);
  const themeName = skin || "primary";
  const prevUser: any = usePrevious(user);
  useEffect(() => {
    if (user?.puid && invalidCount > 5) {
      setShowCamera(true);
    } else if (showCamera && invalidCount > 10) {
      setUser({});
      localStorage.removeItem("uuid");
      setShowCamera(false);
      invalidCount = 0;
    }
  }, [invalidCount]);

  useEffect(() => {
    removeUser();
  }, [user?.PI?.uuid]);

  const removeUser = async () => {
    if (user?.puid && prevUser?.puid && user?.puid !== prevUser?.puid) {
      setUser({});
      localStorage.removeItem("uuid");
    }
  };

  const handlePredictSuccess = async (result: any) => {
    retryTimes = retryTimes + 1;
    const userUUID = localStorage.getItem("uuid");
    switch (result.status) {
      case -1: {
        localStorage.removeItem("uuid");

        notEnrolled++;
        setUser(result);
        if (notEnrolled >= 5) {
          notEnrolled = 0;
        }
        faceLoginWithLiveness();
        return true;
      }

      case -100: {
        userUUID && invalidCount++;
        faceLoginWithLiveness();
        return true;
      }

      case 0: {
        invalidCount = 0;
        notEnrolled = 0;
        setUser(result);
        localStorage.setItem("uuid", JSON.stringify(result?.puid || {}));
        setShowCamera(false);
        faceLoginWithLiveness();
        return false;
      }
      default:
        return false;
    }
  };

  const { faceLoginWithLiveness, faceLoginWithLivenessMessage } =
    useFaceLoginWithLivenessCheck(
      handlePredictSuccess,
      undefined,
      retryTimes,
      !user?.puid
    );

  const onCloseCamera = async () => {
    await closeCamera(ELEMENT_ID);
    setTimeout(() => {
      window.location.href = `/`;
    }, 500);
  };

  return (
    <>
      {<Header theme={themeName} onCloseCamera={onCloseCamera} />}
      <div
        className="homePageWrapper"
        style={{ backgroundColor: user?.puid ? "green" : "" }}
      >
        {showCamera ? (
          <HomeModal open={showCamera}>
            <Box position={"relative"} padding={"10px 10px"} mt={0} pr={"12px"}>
              <div id="canvasInput" className={homeStyles.container}>
                <Camera
                  onReadyCallback={faceLoginWithLiveness}
                  onSwitchCamera={faceLoginWithLiveness}
                  message={faceLoginWithLivenessMessage}
                ></Camera>
              </div>
            </Box>
          </HomeModal>
        ) : (
          <Box height={"100vh"} pt={10}>
            {user?.status === 0 ? (
              <>
                <Typography
                  component="p"
                  textAlign="left"
                  fontSize={"25px"}
                  fontWeight={500}
                  overflow={"auto"}
                  color={"#333"}
                >
                  USER AUTHENTICATED: {'face'}
                </Typography>
                <Typography
                  component="p"
                  textAlign="left"
                  fontSize={"25px"}
                  fontWeight={500}
                  overflow={"auto"}
                  color={"#aaa"}
                >
                  UUID: {user?.puid}
                </Typography>
                <Typography
                  component="p"
                  textAlign="left"
                  fontSize={"25px"}
                  fontWeight={500}
                  overflow={"auto"}
                  color={"#aaa"}
                >
                  GUID: {user?.guid}
                </Typography>
              </>
            ) : (
              <Grid
                item
                container
                justifyContent={"center"}
                spacing={1}
                alignItems={"center"}
              >
                <Grid item alignItems={"center"}>
                  <Lock
                    sx={{ fontSize: 25, marginTop: "-5px", color: "#aaa" }}
                  />
                </Grid>
                <Grid item paddingLeft={0}>
                  <Typography
                    component="p"
                    textAlign="center"
                    fontSize={"25px"}
                    fontWeight={500}
                    sx={{ padding: 1, pt: 0, pl: 0 }}
                    overflow={"auto"}
                    color={"#aaa"}
                  >
                    Look at the camera to login
                  </Typography>
                </Grid>
              </Grid>
            )}

            <div
              id="canvasInput"
              className={homeStyles.container}
              style={{ display: "none" }}
            >
              <Camera
                onReadyCallback={faceLoginWithLiveness}
                onSwitchCamera={faceLoginWithLiveness}
              ></Camera>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default ContinuousAuthentication;
