import { Box, Container, Typography } from "@mui/material";
import { useStyles } from "../../styles/styles";

import Header from "../../components/Header";

const Privacy = ({ skin }: any) => {
  const privacyClasses = useStyles();
  return (
    <Box>
      <a className={privacyClasses.skipToContentLink} href="#main">
        Skip to content
      </a>
      <Header theme={skin} />
      <Container maxWidth="md">
        <Box pt={10}>
          <Typography
            variant="h1"
            fontSize={50}
            fontWeight={400}
            mb={0}
            letterSpacing={1}
            align={"center"}
            mt={3}
          >
            Privacy Policy
          </Typography>
          <Typography
            fontSize={"0.9em"}
            align={"center"}
            fontWeight={400}
            mt={1}
          >
            Effective Date: May 1, 2023
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={3}
          >
            A. Privacy Statement for the Private Identity® Age Estimation
            Service
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            This privacy policy (“Privacy Policy”) governs how Private Identity
            LLC (“Private Identity,” "Private ID," “we”, “our” or “us”) uses the
            Personal Data we collect, receive and store regarding individuals in
            connection with the use of the Website g.private.id (our “Service”),
            as well as your choices regarding use and disclosure of your
            Personal Data. As used in this Privacy Policy, the term “Personal
            Data” (“Personal Data”) means information that identifies you or
            your household directly or indirectly, by reference to identifier(s)
            such as your name, identification number, location data, online
            identifier or one or more factors specific to your physical,
            physiological, genetic, mental, economic, cultural, educational,
            commercial, professional or social identity.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            By accessing our Services, you agree to be bound by this Privacy
            Policy. If you do not agree to the terms of this Privacy Policy,
            please do not use our Services. Each time you use our Service, the
            current version of the Privacy Policy will apply. Accordingly,
            whenever you use our Service, you should check the date of this
            Privacy Policy (which appears at the top) and review any changes
            since the last version. This Privacy Policy is applicable to all
            Service visitors, registered users, and all other users of our
            Service.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Further, you acknowledge that this Privacy Policy is part of our
            Terms of Service​. This Privacy Policy is incorporated by reference,
            and by accessing or using our Service, you agree to be bound by the
            Terms of Service. If you do not agree to our Terms of Service, do
            not access or use our Service.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            B. Personal Data Collected by Private Identity
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            When you use our age estimation Service or access the Website to
            have access to general information available on the Site, we do not
            currently require you to provide Personal Data.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Log Files. We use log files for our products for billing and quality
            assurance only. The information inside the Private Identity log
            files does not include internet protocol (IP) addresses, type of
            browser, Internet Service Provider (ISP), referring/exit pages,
            clicked pages or any other information your browser or device may
            send to us. We do not combine the information collected by any log
            files with customer or end-user personally identifiable information
            (PII).
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Personal Data that you Knowingly and Willingly Provide. Private
            Identity collects the information that you knowingly and voluntarily
            provide when you use our Service (such as through web forms),
            including feedback information. You can choose not to provide us
            with feedback.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Anonymized and/or Pseudonymized Information. When you use our
            service to estimate age, we anonymize your biometric information on
            your device, and only collect anonymized age information. As
            explained in the Terms of Use, the Services provide you a limited,
            revocable, nonexclusive, non-assignable license to access/download
            the Private ID Estimate Age application onto a device (phone,
            laptop, etc.) and use the Estimate Age application for the sole
            purpose of accessing our Services to estimate age yourself.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            C. How Is Your Personal Data Used? We do not process any Personal
            Data, and do not have any Personal Data available to be with third
            parties.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            We may use Anonymized Data we collect about you on its own or
            combine it with other information we have about you to:
            <Typography
              component={"p"}
              variant="body1"
              fontWeight={400}
              mb={0}
              mt={0}
            >
              • To create user accounts and/or profiles through registration; •
              Perform internal operations, such as tracking and improving the
              effectiveness of our website, mobile experience, and marketing
              efforts; • Provide product and/or Service fulfillment, such as
              creating and maintaining accounts, managing purchases, payments,
              and more; • Resolve disputes between users and/or a user and the
              company as set forth in our Terms of Use; • Use the location of
              your mobile device to prevent fraud or deliver other
              location-based services that you may request; • Receive feedback;
              • Conduct audits; • Recruit; • Provide information to our business
              partners; • Provide information to our suppliers; • Provide
              information to our representatives and/or advisors, such as our
              attorneys, accountants, and others to help us comply with legal,
              accounting, or security requirements; • Transfer your information,
              such as to servers outside of the United States, or as part of a
              merger or sale of the business; • Protect the rights, safety, and
              information of our members, third parties, as well as our own.
              This includes fraud prevention, business or product recalls,
              prosecuting and defending a court arbitration or similar
              proceeding, and more; • Comply with legal requirements, such as in
              response to a subpoena or similar investigative demand; • Protect
              against harm to the rights, property or safety of Private
              Identity, our users, or the public as required or permitted by
              law. We may also disclose your Personal Data as otherwise set
              forth in this Privacy Policy, as permitted by law, or with your
              consent.
            </Typography>
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            D. Who Can Access the Anonymized Information We Collect?
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            We will only transfer your Personal Data to trusted third-parties
            who provide sufficient guarantees in respect of the technical and
            organizational security measures governing the processing to be
            carried out and who can demonstrate a commitment to compliance with
            those measures.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Where third parties are processing Personal Data on our behalf, they
            will be required to agree, by contractual means or otherwise, to
            process the Personal Data in accordance with the applicable law and
            to act only on our instructions, or as permitted by law.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Private Identity may disclose your Personal Data in the following to
            the following entities and purposes:
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Agents. We employ other companies and individuals to perform
            functions on our behalf. Examples of such functions include cloud
            storage providers and other third party service providers involved
            in processing transactions and billing, processing credit card
            payments, and providing customer service. We share our users’
            Personal Data with these agents as we deem necessary for such third
            parties to perform their functions.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Employees. Only authorized employees have access to your Personal
            Data.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Authorized Business Partners. These include agents, contractors,
            vendors, licensors, resellers, system integrators, etc. Private
            Identity, our affiliates, and our business partners may share this
            Personal Data with each other and use it consistent with this
            Privacy Policy. They may also combine it with other information to
            provide and improve our products, Service, content, and advertising.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            To Comply with Legal Process, Protect Private Identity, or Enforce
            our Rights. We may release your Personal Data when it is necessary
            to (i) conform to legal requirements or comply with legal process;
            (ii) enforce or apply our conditions of use and other agreements
            (iii) protect the rights, safety or property of Private Identity,
            our affiliates, service providers, our users or the public, or (iv)
            prevent a crime or protect national security (including exchanging
            information with other companies and organizations for fraud
            protection and credit risk reduction).
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            As Part of a Merger or Sale of Business. We may disclose your
            Personal Data in connection with a substantial corporate
            transaction, such as the sale of our business, a divestiture,
            merger, consolidation, or asset sale, or in the unlikely event of
            bankruptcy.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            E. Selling of Personal Data
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            We will not sell your Personal Data to third parties for their use
            without your consent.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            F. Disclosure of Personal Data for a Business Purpose
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            We will never disclose your Personal Data to third parties for a
            business purpose with whom you do not have an existing relationship.
            We may disclose your information (including Personal Data) to our
            strategic business partners with whom you have an existing
            relationship in order for our partners to better target their
            products and services to you. Our partners will not have direct
            access to your Personal Data but rather will have the ability to
            communicate with you through your participation with our Service.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            The kinds of Personal Data we have disclosed for a business purpose
            over the preceding twelve (12) months include: None.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            G. Minors and Children Privacy
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Children Online Protection. Our Service is not directed to children
            under the age of 15, if you are not 15 years or older, do not use
            our Service. We do not knowingly collect Personal Data from children
            under the age of 15. If we learn that Personal Data of persons less
            than 15 years-of-age has been collected through our Service, we will
            take the appropriate steps to delete this information. If you are a
            parent or guardian and discover that your child or a minor under the
            age of 13 has posted, submitted or otherwise communicated Personal
            Data to our Service without your consent, then you may alert us at
            compliance@private.id so that we may take appropriate action to
            remove the minor's Personal Data from our systems.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            H. Links to Third Party Services
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Our Service may contain links to third party websites, applications
            and services not operated by us. These links are provided as a
            service and do not imply any endorsement by Private Identity of the
            activities or content of these sites, applications or services nor
            any association with their operators. Private Identity is not
            responsible for the privacy policies or practices of any third party
            including Websites or services directly linked to our Service. We
            encourage you to review the privacy policies of any third-party site
            that you link from our Service.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            (1) Security
            <br />
            <br />
            We take reasonable technical and organizational precautions to
            protect the confidentiality, security and integrity of all data
            including any Personal Data. Although we use security measures to
            help protect Personal Data against loss, misuse or unauthorized
            disclosure, we cannot guarantee the security of information
            transmitted to us over the Internet. While we strive to use
            commercially acceptable means to protect Personal Data, there is no
            guarantee that information may not be accessed, disclosed, altered
            or destroyed.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            (2) Storage
            <br />
            <br />
            Any Personal Data that you provide to us is generally stored on
            Google Cloud Platform servers located in the United States. If you
            are located in another jurisdiction, you should be aware that once
            your Personal Data is submitted through our Service, it will be
            transferred to our servers in the United States and that the United
            States currently does not have uniform data protection laws in
            place.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            (3) Retention
            <br />
            <br />
            Your selfie is always captured and processed on-device. We do not
            receive, process or retain your Personal Data.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            I. International Users.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            We are headquartered in the United States. Your Personal Data may be
            accessed by us or transferred to us in the United States or to our
            affiliates, partners, merchants, or service providers who are
            located worldwide. If you are visiting our Service from outside the
            United States, be aware that your information may be transferred to,
            stored, and processed in the United States where our servers are
            located, and our central database is operated. By using our Service,
            you consent to any transfer of this information.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            We will protect the privacy and security of Personal Data according
            to this privacy statement, regardless of where it is processed or
            stored, however you explicitly acknowledge and consent to the fact
            that Personal Data stored or processed in the United States will be
            subject to the laws of the United States, including the ability of
            governments, courts or law enforcement or regulatory agencies of the
            United States to obtain disclosure of your Personal Data.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            J. Your Rights and Deletion Requests
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            mb={0}
            mt={2}
          >
            Private Identity will not collect any Private Data subject to the
            rights established by the GDPR or CCPA. On a voluntary basis,
            Private Identity will take reasonable steps to delete anonymized
            subject data upon request. Where we are able to authenticate a user
            deletion request, and subject to a waiting period during which we
            will confirm any contractual or legal commitments to retain the
            anonymized data, anonymized data will be permanently erased.
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={800}
            mb={0}
            letterSpacing={1}
            mt={4}
          >
            K. Changes to this Privacy Policy
          </Typography>
          <Typography
            component={"p"}
            variant="body1"
            fontWeight={400}
            pb={6}
            mt={2}
          >
            We reserve the right to update or modify this Privacy Policy from
            time to time. If we make any material changes to this Privacy Policy
            or the way we use, share or collect Personal Data, we will notify
            you by revising the “Effective Date” at the top of this Privacy
            Policy, prominently posting an announcement of the changes on our
            Service, or sending an email to the email address you most recently
            provided us (unless we do not have such an email address) prior to
            the new policy taking effect. Users are bound by any changes to the
            Privacy Policy when he or she uses our Service after notice of such
            changes has been communicated. We encourage you to review this
            Privacy Policy regularly to ensure that you are familiar with
            Private Identity’s current practices.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Privacy;
