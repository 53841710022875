import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useStyles, styles } from "../AuthenticateComponents/Login/styles";
import React, { useEffect, useState } from "react";
import { startRegistration } from "@simplewebauthn/browser";
import homeStyles from "../../styles/Home.module.css";
import Camera from "../Camera";
import { UserContext } from "../../context/UserContext";
import {
  closeCamera,
  updateUser,
  uploadPortrait,
  convertCroppedImage,
  updateUserWithSession,
  updateTypeEnum,
  uploadEnrollImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import shield from "../../assets/shield.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import STEPS from "../../pages/register/steps";
import { onStepChange, stopCamera } from "../../utils";
import SpinnerLoader from "../SpinnerLoader";
import { BASE_URL, ELEMENT_ID } from "../../constants";

import useEnrollOneFaWithLiveness from "../../hooks/useEnrollOneFaWithLiveness";
import { ClassNameMap } from "@mui/styles";
import { detect, BrowserInfo } from "detect-browser";
import useToast from "../../utils/useToast";
import { UserContextV2 } from "../../context/UserContextV2";

function EnrollProgressMessage(props: {
  enrollOneFaProgress: number;
  classes: ClassNameMap;
}) {
  if (props.enrollOneFaProgress === 100) {
    return (
      <Typography
        align="center"
        fontSize={14}
        fontWeight={700}
        mt={1}
        mb={1}
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress className={props.classes.progressBar} /> Verifying
        User, Please wait...
      </Typography>
    );
  }
  return (
    <Typography align="center" fontSize={14} fontWeight={700} mt={1} mb={1}>
      Please look at the camera to enroll
    </Typography>
  );
}

const Enroll = ({
  setStep,
}: {
  onReadyCallback?: (e: boolean) => void;
  onSwitchCamera?: () => void;
  message?: string;
  setStep: (e: string) => void;
  skin: string;
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const context = React.useContext(UserContext);
  // user api v2
  const userContextV2 = React.useContext(UserContextV2);

  const { id, setGUID, setUUID, setDlAction } = context;
  const [showSuccess, setShowSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const { showToast } = useToast();
  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;
  const {
    enrollUserOneFa,
    progress: enrollOneFaProgress,
    enrollStatus,
    enrollPortrait,
    enrollData,
  } = useEnrollOneFaWithLiveness("userVideo", () => {}, 4);

  useEffect(() => {
    if (enrollData?.puid && enrollData?.guid && enrollPortrait) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
      handleUserUpdate(enrollData?.guid, enrollData?.puid, enrollPortrait);
    }
  }, [enrollData?.guid, enrollPortrait]);
  const handleUserUpdate = async (
    guid: string,
    uuid: string,
    enrollInageData: ImageData
  ) => {
    console.log("HANDLE USER UPDATE:", {guid,uuid,enrollInageData})
    setGUID(guid);
    setUUID(uuid);
    userContextV2.setGUID(guid);
    userContextV2.setUUID(uuid);
    userContextV2.setEnrollImageData(enrollInageData);

    const updateUser = await updateUserWithSession({
      sessionToken: userContextV2.sessionToken,
      updateType: updateTypeEnum.enroll,
      uuid,
      guid,
    });

    if (enrollInageData) {
      context.setEnrollImageData(enrollInageData);
      const enrollPortraitBase64 = await convertCroppedImage(
        enrollInageData.data,
        enrollInageData.width,
        enrollInageData.height
      );

      const uploadImageSession = await uploadEnrollImageWithSession({
        sessionToken: userContextV2.sessionToken,
        imageString: enrollPortraitBase64,
      });

      // await uploadPortrait({
      //   id,
      //   portrait: enrollPortraitBase64,
      // });
      // const params = {
      //   id,
      //   attributes: {
      //     guid,
      //     uuid,
      //   },
      // };

      // const updateRes = (await updateUser(params)) as any;

      const updateV2Params = {
        sessionToken: userContextV2.sessionToken,
        guid: guid,
        uuid: uuid,
        updateType: updateTypeEnum.enroll,
      };

      const updateUserV2 = await updateUserWithSession(updateV2Params);

      console.log("update user", updateUserV2);

      // if (updateRes.guid && updateRes.uuid) {
      localStorage.setItem("uuid", JSON.stringify(uuid || {}));
      setShowSuccess(true);
      setTimeout(async () => {
        await closeCamera(ELEMENT_ID);
        setDlAction("frontscan");
        onStepChange(STEPS.ENROLL, setStep, STEPS.DRIVERLICENSE);
      }, 2000);
      // }
    }
  };

  const onCameraFail = async () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  const cameraPermissionCheckAndEnroll = async (e: boolean) => {
    if (e) {
      await enrollUserOneFa();
    } else {
      setStep(STEPS.SWITCH_DEVICE);
    }
  };

  const onWasmLoadFail = () => {
    setStep(STEPS.SWITCH_DEVICE);
  };

  return (
    <>
      <Box
        position={"relative"}
        padding={matchesSM ? "0px 10px" : "10px 10px"}
        mt={0}
        pr={"12px"}
      >
        {(showSuccess || enrollOneFaProgress === 100) && (
          <Box
            style={
              {
                ...styles.overlayCamera,
                top: matchesSM ? 46 : "56px",
                height: matchesSM ? "77%" : "70%",
              } as React.CSSProperties
            }
            className="completeOverlay"
          >
            {showSuccess ? (
              <img
                src={shield}
                alt="shield"
                style={styles.shield as React.CSSProperties}
              />
            ) : (
              <SpinnerLoader />
            )}
          </Box>
        )}
        <>
          <div id="canvasInput" className={homeStyles.container}>
            <Camera
              onReadyCallback={cameraPermissionCheckAndEnroll}
              onSwitchCamera={() => {}}
              onCameraFail={onCameraFail}
              message={enrollStatus}
              onWasmLoadFail={onWasmLoadFail}
              setStep={setStep}
              loader={loader}
              canvasLayout={true}
              enrollOneFaProgress={enrollOneFaProgress}
              hideInstructionOverlay={false}
            />
          </div>
          {os !== "iOS" && os !== "Android OS" && (
            <Box className={classes.otherOptions}>
              <Typography
                component="p"
                textAlign={matchesSM ? "center" : "left"}
                fontSize={15}
                fontWeight={500}
                mt={2}
                onClick={() => {
                  setStep(STEPS.SWITCH_DEVICE);
                  stopCamera();
                }}
              >
                <PhoneIphoneIcon /> Switch to Mobile Device
              </Typography>
            </Box>
          )}
          <Box style={{ height: 50 }}>
            <EnrollProgressMessage
              enrollOneFaProgress={enrollOneFaProgress}
              classes={classes}
            />
          </Box>
        </>
      </Box>
    </>
  );
};
export default Enroll;
