import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import IDLaptopImage from "../../../assets/Hand-Scan-Back-DL.png";
import STEPS from "../../../pages/login_with_barcode/steps";

interface PreDocumentScanProps {
  skin: string;
  setStep: (e: string) => void;
  config?: {};
}

const PreDocumentScan = (props: PreDocumentScanProps) => {
  const { skin, setStep } = props;
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const classes = useStyles();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={700}
          mt={2}
          className={classes.cardInnerHeading}
          letterSpacing={1}
        >
          Get ready to scan the back of your
          <br /> US Driver's License or ID Card
        </Typography>
        <img
          src={IDLaptopImage}
          alt=""
          className={classes.dlImage}
        />
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          setStep(STEPS.BACK_DOCUMENT_SCAN);
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="none"
          fontSize={14}
        >
          Continue
        </Typography>
      </Button>
    </>
  );
};

export default PreDocumentScan;
