import React, { useMemo } from "react";
import STEPS from "../pages/register/steps";
const StepContext = React.createContext({
  step: STEPS.PRE_ENROLL,
  setStep: (step: string) => {},
  goOneStepBack: () => {},
  stepFlow: [STEPS.PRE_ENROLL],
  setIsWasmSupported: (v: boolean) => {},
  isWasmSupported: false,
  sentToSwitchDeviceTab: false,
  setSentToSwitchDeviceTab: (v: boolean) => {},
});

export const StepProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setStep] = React.useState(STEPS.PRE_ENROLL);
  const [isWasmSupported, setIsWasmSupported] = React.useState<boolean>(false);
  const [stepFlow, setStepFlow] = React.useState<string[]>([]);
  const [sentToSwitchDeviceTab, setSentToSwitchDeviceTab] =
    React.useState(false);
  const goOneStepBack = () => {
    if (stepFlow.length <= 1) return;
    const newStepFlow = [...stepFlow];
    newStepFlow.pop();
    setStepFlow(newStepFlow);
    setStep(newStepFlow[newStepFlow.length - 1]);
  };
  const handleStepChange = (newStep: string) => {
    setStep(newStep);
    setStepFlow([...stepFlow, newStep]);
  };

  const values = {
    step,
    setStep: handleStepChange,
    goOneStepBack,
    stepFlow,
    isWasmSupported,
    setIsWasmSupported,
    sentToSwitchDeviceTab,
    setSentToSwitchDeviceTab,
  };

  const memoValues = useMemo(() => values, [values]);
  return (
    <StepContext.Provider value={memoValues}>{children}</StepContext.Provider>
  );
};

export const useStep = () => React.useContext(StepContext);
