import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PreForgetHeading } from "../PreForgetMe";
import QRCode from "react-qr-code";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import {
  createAuthProofRequest, createProofRequest,
  getAuthProofRequest,
} from "../../../services/vc-dock";
import STEPS from "../../../pages/register/steps";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

interface VerifiedCredentialsAuthProps {
  skin: string;
  setStep: (e: string) => void;
  handleFailure: (message: string, systemMessage: string) => void;
  handleSuccess: () => void;
  checked: boolean;
  handleChange: (e: boolean) => void;
}

const VerifiedCredentialsAuthentication = (
  props: VerifiedCredentialsAuthProps
) => {
  const { skin, setStep, handleFailure, handleSuccess, checked, handleChange } =
    props;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const muiTheme = useTheme();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();

  const [qrLink, setQrLink] = useState("");

  let intervalId: any;
  let counter = 0;
  useEffect(() => {
    const handleAuthProofRequest = async () => {
      const authProofRequestRes: any = await createProofRequest();
      setQrLink(authProofRequestRes?.qr || "");

      intervalId = setInterval(async () => {
        const res: any = await getAuthProofRequest(authProofRequestRes?.id);

        if (res?.verified) {
          clearInterval(intervalId);
          handleSuccess();
        }

        if (counter >= 36) {
          handleFailure(
            "Unable to Verify Credentials",
            "Unable to Verify Credentials"
          );
          clearInterval(intervalId);
        }
        counter++;
      }, 5000);
    };
    handleAuthProofRequest();
  }, []);

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        flexDirection={"column"}
        style={styles.cardGrid}
        className={classes.cardGridMobile}
      >
        <PreForgetHeading>
          Scan QR code on device to Verify Credentials
        </PreForgetHeading>
        {qrLink && <QRCode width={200} height={200} value={qrLink} />}
      </Grid>
      <Grid container justifyContent={"center"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => {
                handleChange(e.target.checked);
              }}
            />
          }
          label="Save Passkey on device"
        />
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          navigateWithQueryParams("/");
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          Back to homepage
        </Typography>
      </Button>
    </>
  );
};

export default VerifiedCredentialsAuthentication;
