import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import IdPhoneImage from "../../../assets/phone-scan-front.png";
import IdLaptopImage from "../../../assets/Hand-scan-front-laptop.png";
import STEPS from "../../../pages/register/steps";
import { HeadingWrap } from "../InsuranceCard";

interface PreDocumentProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
}

const PreDocument = (props: PreDocumentProps) => {
  const { skin, setStep } = props;
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <HeadingWrap classes={classes}>
          Get ready to scan your
          <br /> US Driver's License or ID Card
        </HeadingWrap>
        <img
          src={matchesSM ? IdPhoneImage : IdLaptopImage}
          alt=""
          className={classes.dlImage}
        />
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          setStep(STEPS.DRIVERLICENSE);
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="capitalize"
          fontSize={14}
        >
          Continue
        </Typography>
      </Button>
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          setStep(STEPS.PERSONAL_DETAILS);
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="none"
          fontSize={14}
        >
          I do not have my Photo ID
        </Typography>
      </Button>
    </>
  );
};

export default PreDocument;
