import {
  Button,
  Card,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import STEPS from "../../../pages/register/steps";
import React from "react";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

interface DatabaseConsentProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  setPrevStep: (e: string) => void;
}

const DatabaseConsent = (props: DatabaseConsentProps) => {
  const { skin, setStep, setPrevStep } = props;
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  return (
    <>
      <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={700}
          lineHeight={1.5}
          mt={2}
          className={classes.cardInnerHeading}
        >
          User Consent
        </Typography>
        <Card className={classes.consentCard}>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
            }}
          >
            <ListItem className={classes.listText}>
              You certify that you reside in one of the following states:
              Alaska, Arkansas, Colorado, Connecticut, Delaware, Florida,
              Georgia, Idaho, Indiana, Iowa, Kansas, Kentucky, Louisiana,
              Maryland, Massachusetts, Michigan, Minnesota, Mississippi,
              Missouri, Montana, Nevada, New York, North Dakota, Ohio, Oklahoma,
              Pennsylvania, South Carolina, South Dakota, Tennessee, Texas,
              Utah, Vermont, Washington, Washington DC, West Virginia,
              Wisconsin, or Wyoming.
            </ListItem>
            <ListItem className={classes.listText}>
              If you choose to capture your selfie using the Private Identity
              application, your selfie will be processed by a neural network
              running in your browser that compares your face to your Photo ID
              in a few milliseconds. This process is fast and accurate, and does
              not keep or share your selfie.
            </ListItem>
            <ListItem className={classes.listText}>
              The biometric data is only captured and processed on your device,
              and is deleted within one second. Private Identity never
              transmits, receives, stores, manages or discloses your biometric
              data.
            </ListItem>
            <ListItem className={classes.listText}>
              Only encrypted data is sent to the Private Identity servers.
              Private Identity then confirms with third-party partners whether
              or not you have reached the identity assurance threshold required
              to create an account.
            </ListItem>
            <ListItem className={classes.listText}>
              This app is certified compliant with the IEEE 2410-2021 Standard
              for Biometric Privacy. No biometric template is created,
              processed, stored, transmitted, or received.
            </ListItem>
            <ListItem className={classes.listText}>
              I acknowledge I am over 21 years of age, all information I
              provided is accurate, and I am prohibited from allowing any other
              person to access or use my verified Private Identity account.
            </ListItem>
            <ListItem className={classes.listText}>
              I agree to register for an account using my face images and my US
              state issued driving license or identity card. Private Identity,
              and our third-party enrollment and identity proofing service
              providers IDEMIA and CentralAMS, may share, use and maintain the
              images and information you provide, and the information on file
              with other third-party service providers to further verify your
              identity, to protect against or prevent actual or potential fraud
              or unauthorized use of the Service, and to establish, maintain and
              authenticate your verified digital identity, for the duration of
              our business relationship.
            </ListItem>
            <ListItem className={classes.listText}>
              I agree to receive email and SMS messages for the purpose of
              registering and maintaining an account.
            </ListItem>
            <ListItem className={classes.listText}>
              I have read and accepted the Private Identity LLC{" "}
              <Link
                href="https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use"
                target={"_blank"}
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                href="https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy"
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              , CentralAMS{" "}
              <Link
                href="https://www.centralams.com/terms-of-use/"
                target={"_blank"}
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                href="https://www.centralams.com/privacy-policy/"
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              , and the IDEMIA{" "}
              <Link
                href="https://na.idemia.com/terms/"
                target={"_blank"}
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                href="https://na.idemia.com/privacy/"
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              .
            </ListItem>
          </List>
        </Card>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => {
          setStep(STEPS.REGISTER_FORM);
          setPrevStep(STEPS.REGISTER_CONSENT);
        }}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="none"
          fontSize={14}
        >
          Accept and continue
        </Typography>
      </Button>
      <Button
        variant="contained"
        color={"inherit"}
        style={styles.continueButton}
        className={classes.continueButton}
        onClick={() => navigateWithQueryParams("/")}
      >
        <Typography
          component="p"
          color={palette?.[skin]?.text}
          textAlign="center"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          textTransform="none"
          fontSize={14}
        >
          No, I do not consent
        </Typography>
      </Button>
    </>
  );
};

export default DatabaseConsent;
