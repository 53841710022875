import React, { createContext, useMemo, useState } from "react";

const defaultUserStatus = {
  requestSSN9: false,
  requestScanID: false,
  requestResAddress: false,
};

export const FederationContext = createContext({
  ssn4: "",
  setSSN4: (ssn4: string) => {},
  guid: "",
  setGUID: (uuid: string) => {},
  firstName: "",
  setFirstName: (firstName: string) => {},
  lastName: "",
  setLastName: (lastName: string) => {},
  dob: "",
  setDob: (dob: string) => {},
  federationHash: "",
  setFederationHash: (federationHash: string) => {}
});

const FederationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [ssn4, setSSN4] = useState("");
  const [guid, setGUID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [federationHash, setFederationHash] = useState("");
  const values = {
    ssn4,
    setSSN4,
    guid,
    setGUID,
    firstName, 
    setFirstName,
    lastName, 
    setLastName,
    dob, 
    setDob,
    federationHash, 
    setFederationHash,
  };
  const memoValues = useMemo(() => values, [values]);
  return (
    <FederationContext.Provider value={memoValues}>{children}</FederationContext.Provider>
  );
};

export default FederationContextProvider;
