import {
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { styles, useStyles } from "../../../styles/styles";
  import { theme as Theme } from "../../../theme";
  import IdImage from "../../../assets/passport.png";
  import STEPS from "../../../pages/register/steps";
  import CvsButton from "../../Button";
  
  interface InsuranceCardProps {
    theme: string;
    skin: string;
    setStep: (e: string) => void;
  }
  
  export const HeadingWrap = ({ classes, children }: any) => (
    <Typography
      component="p"
      textAlign={"center"}
      fontSize={16}
      fontWeight={700}
      mt={2}
      className={classes.cardInnerHeading}
      letterSpacing={1}
    >
      {children}
    </Typography>
  );
  
  const PrePassportScan = (props: InsuranceCardProps) => {
    const { skin, setStep } = props;
    const mainTheme = Theme;
    const palette: { [key: string]: any } = mainTheme.palette;
    const muiTheme = useTheme();
    const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
    const classes = useStyles();
    return (
      <>
        <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
          <HeadingWrap classes={classes}>
            Get ready to scan your
            <br /> Passport
          </HeadingWrap>
          <img src={IdImage} alt="" className={classes.passport} />
        </Grid>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        <CvsButton
          skin={skin}
          title="Continue"
          onClick={() => {
            setStep(STEPS.PASSPORT_SCAN);
          }}
        />
        <CvsButton
          skin={skin}
          title="I do not have my passport"
          onClick={() => {
            setStep(STEPS.PRE_DOCUMENT);
          }}
        />
      </>
    );
  };
  
  export default PrePassportScan;
  