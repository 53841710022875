import { useEffect, useContext } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";

import { useStyles, styles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import { UserContext } from "../../../context/UserContext";
import { getStatusFromUser, SUCCESS } from "../../../utils";
import heartLogo from "../../../assets/heart-logo-white.png";
import successThumb from "../../../assets/success.gif";
import { postLoginAttemp } from "../../../services/api";
import { LoginContext } from "../../../context/LoginContext";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

export const CardHeader = ({ classes, title }: any) => (
  <Grid
    container
    alignItems="center"
    justifyContent={"center"}
    className={classes.cardHeaderWrap}
  >
    <Typography
      component="p"
      textAlign="center"
      fontSize={16}
      fontWeight={900}
      letterSpacing={"1px"}
      sx={{ paddingTop: 2, paddingBottom: 2 }}
      className={classes.cardHeading}
    >
      <img
        src={heartLogo}
        alt=""
        width={35}
        className={classes.headerBoxLogo}
      />
      {title}
    </Typography>
  </Grid>
);
const LoginSuccess = ({
  skin,
  matchesSM,
  postToOidc = () => {},
}: {
  skin: string;
  matchesSM: boolean;
  postToOidc: () => void;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const context = useContext(UserContext);
  const palette: { [key: string]: any } = mainTheme.palette;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const loginContext = useContext(LoginContext);

  useEffect(() => {
    setTimeout(() => {
      const status = getStatusFromUser(window.localStorage.getItem("user"));
      const { successUrl } = context.verificationSession;
      if (successUrl && status === SUCCESS) {
        window.location.href = successUrl;
      }
    }, 2000);
  }, []);

  const logUserSuccessAttempt = async () => {
    const userData = {
      status: "success",
      predictedGUID: loginContext.predictedGUID,
      barcodeHash: loginContext.barcodeHash,
      error: false,
    };
    await postLoginAttemp(userData);
  };

  useEffect(() => {
    logUserSuccessAttempt();
    setTimeout(() => {
      postToOidc();
    }, 3000);
  }, []);

  return (
    <>
      <CardHeader classes={classes} title="Login Successful!" />
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Grid
        style={{ ...styles.cardGrid, backgroundColor: "#e9e9e9" }}
        className={classes.cardGridMobile}
      >
        <img
          src={successThumb}
          alt=""
          className={classes.checkIcon}
          width={400}
        />
      </Grid>
      <Grid style={{ marginBottom: 30, height: 80 }}>
        {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={20}
          fontWeight={500}
          lineHeight={1.5}
          mt={1}
          className={classes.cardInnerHeading}
          color={palette?.[skin]?.primaryColor}
        >
          Thank you for using identity verification!
        </Typography>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => navigateWithQueryParams("/")}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="capitalize"
          >
            Continue
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default LoginSuccess;
