import { makeStyles } from "@mui/styles";
import bannerImage from "../../assets/cardBg.svg";
import { localThemes, theme as Theme } from "../../theme";
const mainTheme = Theme;
const palette: { [key: string]: any } = mainTheme.palette;
const skin = localThemes?.includes(window?.location?.search?.split("skin=")[1])
  ? window?.location?.search?.split("skin=")[1]
  : "primary";

export const useStyles = makeStyles((theme: any) => ({
  pageWrap: {
    flexDirection: "column",
    padding: "0px 1.5rem",
    paddingTop: "3rem",
    height: "calc(100vh - 50px)",
    "@media (min-width: 1600px)": {
      maxWidth: "1200px",
      margin: "0 auto",
    },
  },
  heading: {
    fontSize: "32px",
    color: "#333",
    display: "block",
    textAlign: "left",
  },
  cardWrap: {
    backgroundColor: "rgb(220 21 49 / 80%)",
    borderRadius: "16px",
    filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px -1px 10px)",
    padding: "16px 17px",
    backgroundImage: `url(${bannerImage})`,
    width: "335px",
    // height: "335px !important",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  cardHeader: {
    display: "flex",
    color: "#fff",
    alignItems: "center",
    "& h4": {
      margin: "0px",
      marginBottom: "5px",
      fontSize: 16,
    },
    "& p": {
      margin: "0px",
      fontSize: 13,
    },
  },
  cardHeaderLeft: {
    marginLeft: "10px",
  },
  cardBodyWrap: {
    overflow: "auto",
    // height: "300px",
    position: "relative",
  },
  cardItem: {
    // marginTop: 30,
    color: "#fff",
    paddingTop: 25,
    "& h4": {
      margin: "0px",
      fontSize: 14,
      fontWeight: "600",
    },
    "& p": {
      margin: "0px",
      fontSize: 11,
      marginBottom: "5px",
    },
  },
  cardHeaderRight: {
    flex: 1,
    textAlign: "right",
    "& svg": {
      cursor: "pointer",
      fontSize: "30px",
    },
  },
  checkBoxWrap: {
    position: "absolute",
    bottom: 0,
    right: 0,
    "& svg": {
      color: "#fff",
    },
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    marginTop: '24px',
    // justifyContent: "flex-end",
    padding: "0px 1.5rem",
    "@media (min-width: 1600px)": {
      maxWidth: "1200px",
      margin: "0 auto",
    },
    "& button": {
      margin: "unset !important",
    },
  },
  scanLoader: {
    width: "16px !important",
    height: "16px !important",
    marginBottom: "2px",
    marginRight: "0px",
    color: `${palette[skin]?.text} !important`,
    [theme.breakpoints.between("xs", "sm")]: {
      color: `${palette[skin]?.text} !important`,
    },
  },
  noVCHeading: {
    textAlign: "center",
    fontSize: 25,
  },
  noVcWrap: {
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
