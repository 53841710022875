import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styles, useStyles } from "../../../styles/styles";
// import Heart from '../../../assets/cardAnimation-loop.gif';
import Heart from '../../../assets/prifloAnimation.gif';

const Animation = () => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  return (
    <Box
    sx={{
      height: '100%',
      // backgroundColor: "#f15b22",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: matchesSM ? "center" : 'space-around'
    }}
    >
      <img
        src={Heart}
        alt=""
        width={matchesSM ? "250px" : "300px"}
        style={styles.AnimationImage}
        className={classes.AnimationImage}
      />
      <Box
        sx={{
          // backgroundColor: "#f15b22",
          mb: "-3px"
        }}
      >
        <Typography
          component="p"
          textAlign={"center"}
          fontSize={16}
          fontWeight={700}
          lineHeight={1.5}
          pt={matchesSM ? 2 : 3}
          pb={matchesSM ? 2 : 3}
          className={classes.cardInnerHeading}
          color={"#1194ff"}
          px={matchesSM ? 2 : 10}
        >
          Please wait while we create your account.
        </Typography>
      </Box>
    </Box>
  );
};

export default Animation;
