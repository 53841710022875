const config = {
  clientConfig: {
    successUrl: window.location.origin,
    failureUrl: window.location.origin,
    type: "IDENTITY",
    productGroupId: "privateid",
  },
};

export default config;
