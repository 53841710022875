import STEPS from "../pages/register/steps";

export const getBackDocumentMessage = (id: number) => {
  switch (id) {
    case 0:
      return "Success";
    case 12:
      return "";
    case -1:
    case 10:
      return "Move closer to barcode";
    case 3:
      return "Almost done . . .";
    case 4:
      return "Move just a little closer";
    case 18:
      return "Present barcode in box";
    case 9:
      return "Too blurry";
    case -2:
      return "System error. Please try again later.";
    default:
      return "";
  }
};

export const getScanFrontMessage = (status: number) => {
  switch (status) {
    case 0:
      return "Success";
    case 12:
      return "";
    case -1:
      return "Move closer";
    case 18:
      return "Show front of document";
    case 3:
      return "Almost done . . .";
    case 4:
      return "Move just a little closer";
    case 9:
      return "Too blurry";
    case -2:
      return "System error. Please try again later.";
    case 5:
    case 6:
    case 7:
    case 8:
      return "Please put the ID at the center of the screen.";
    default:
      return "";
  }
};

export const getScanFrontColor = (status: number) => {
  switch (status) {
    case 10:
    case 0:
      return "rgb(21, 182, 124)";
    case 12:
      return "";
    case -1:
      return "#000";
    case 18:
      return "#000";
    case 3:
      return "rgb(21, 182, 124)";
    // case 4:
    //   return "MOVE JUST A LITTLE CLOSER";
    case 7:
    case 9:
      return "rgba(246,62,62,.4392156862745098)";
    case -2:
      return "rgba(246,62,62,.4392156862745098)";
    case 5:
    case 6:
    case 8:
      return "#000";
    default:
      return "#000";
  }
};

export const getScanBackColor = (status: number) => {
  switch (status) {
    case 0:
      return "rgb(21, 182, 124)";
    case 12:
      return "";
    case -1:
      return "#000";
    case 18:
      return "#000";
    case 3:
      return "rgb(21, 182, 124)";
    // case 4:
    //   return "MOVE JUST A LITTLE CLOSER";
    case 7:
    case 9:
      return "rgba(246,62,62,.4392156862745098)";
    case -2:
      return "rgba(246,62,62,.4392156862745098)";
    case 5:
    case 6:
    case 8:
      return "#000";
    default:
      return "#000";
  }
};

export const ELEMENT_ID = "userVideo";
export const MAX_VERIFY_COUNTS = 3;
export const IAL = "IAL";
export const FAL = "FAL";
export const AAL_target = "AAL_target";
export const availableVersion = [IAL, FAL, AAL_target];
export const StepsWithVersion: any = {
  IAL: {
    "1.0": [
      // {
      //   currentStep: STEPS.ENROLL,
      //   nextStep: STEPS.SUCCESS,
      // },
    ],
    "1.2": [
      // {
      //   currentStep: STEPS.ENROLL,
      //   nextStep: STEPS.INSURANCE_CARD,
      // },
    ],
    "1.4": [
      // {
      //   currentStep: STEPS.ENROLL,
      //   nextStep: STEPS.PRE_DOCUMENT,
      // },
      // {
      //   currentStep: STEPS.PRE_DOCUMENT,
      //   nextStep: STEPS.PERSONAL_DETAILS,
      // },
    ],
    "1.5": [
      // {
      //   currentStep: STEPS.ENROLL,
      //   nextStep: STEPS.PRE_DOCUMENT,
      // },
    ],
    "1.6": [
      // {
      //   currentStep: STEPS.ENROLL,
      //   nextStep: STEPS.PRE_DOCUMENT
      // },
      // {
      //   currentStep: STEPS.DRIVERLICENSE,
      //   faceCompareLocal: true,
      // },
    ],
    "1.8": [],
    "2.0": [],
  },
  FAL: {
    "1.0": [
      {
        currentStep: STEPS.ENROLL,
        nextStep: STEPS.SUCCESS,
      },
    ],
    "1.5": [
      {
        currentStep: STEPS.ENROLL,
        nextStep: STEPS.PRE_DOCUMENT,
      },
    ],
  },
  AAL_target: {
    "1": [
      {
        currentStep: STEPS.ENROLL,
        nextStep: STEPS.SUCCESS,
      },
    ],
    "2": [
      {
        currentStep: STEPS.ENROLL,
        nextStep: STEPS.PRE_DOCUMENT,
      },
    ],
  },
};

export const versionOptions = [
  { label: "IAL 1.0" },
  { label: "IAL 1.2" },
  { label: "IAL 1.4" },
  { label: "IAL 1.5" },
  { label: "IAL 1.6" },
  { label: "Bypass SSN" },
];

export const ACCOUNT_CREATED = "Account created!";
export const AUTHENTICATION_SUCCESSFUL = "Authentication successful";
export const AUTHENTICATION_FAILED = "Authentication failed";
export const ACCOUNT_CREATION_FAILED = "Account Creation Failed";
export const ACCOUNT_NOT_APPROVED = "Account not approved";

export const REMOVE_GLASSES = "Remove glasses";
export const BASE_URL = "https://simplewebauthn.privateid.com"; //"http://127.0.0.1:8000";

export const FLOW = {
  CREATE_USER: 1,
  UPLOAD_SELFIE: 2,
  UPLOAD_DOC_FRONT: 3,
  UPLOAD_DOC_BACK: 4,
};
