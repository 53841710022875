import React from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styles, useStyles } from "./styles";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSkinContext } from "../../context/SkinContext";
import { ELEMENT_ID } from "../../constants";
import { closeCamera } from "@privateid/cryptonets-web-sdk";
import privateIdLogo from "../../assets/privateidLogo.png";

import { generateDidForUser, issueCredentials } from "../../services/vc-dock";
import { generateVCPayload } from "../../utils/vc-dock";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";
import { useSearchParams } from "react-router-dom";

interface HeaderProps {
  theme?: string;
  onCloseCamera?: () => void;
}

const Header = (props: HeaderProps) => {
  const routes = [
    "/authenticate",
    "/",
    "/register",
    "/signin",
    "/terms",
    "/privacy",
    "/login_with_barcode",
    "/login",
    "/continuous_authentication",
    "/federation_hash",
    "/view-credentials",
    "/view-presentation",
    "/aal2",
    "/login_with_dl",
    "/request-proof",
  ];

  const { theme, onCloseCamera } = props;
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const { skin } = useSkinContext();
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [searchParams] = useSearchParams();
  const classes = useStyles();

  const renderHeaderImage = () => {
    const isAtHomePage = routes.includes(window.location.pathname);
    if (isAtHomePage) {
      return (
        <img
          // src={mainLogo}
          src={privateIdLogo}
          alt=""
          width={skin === "c1" ? 120 : 160}
          onClick={handleHeaderImageClick}
          style={{ cursor: "pointer" }}
          className="headerLogo"
        />
      );
    }
    return null;
  };

  const handleHeaderImageClick = () => {
    if (onCloseCamera) {
      onCloseCamera();
    } else {
      window.location.href = `/?skin=${skin}${
        searchParams.get("skipAntispoof")?.length
          ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
          : ""
      }`;
    }
  };

  const onSignInClick = async () => {
    navigateWithQueryParams("/signin");
  };

  const handleLogout = () => {
    closeCamera(ELEMENT_ID);
    localStorage.removeItem("user");
    localStorage.removeItem("uuid");
    setTimeout(() => navigateWithQueryParams("/"), 50);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewCredentials = async () => {
    navigateWithQueryParams("/view-credentials");
  };

  const handlePresentationFlow = async () => {
    navigateWithQueryParams("/view-presentation");
  };

  const generateCredentials = async () => {
    const credentials: any = await issueCredentials(user._id);
    const vcData = { did: credentials.did, credentialsId: credentials.id };
    localStorage.setItem("user", JSON.stringify({ ...user, ...vcData }));
  };

  const menuItems = [
    { name: "View Credentials", func: viewCredentials },
    { name: "Verify Presentation", func: handlePresentationFlow },
    { name: "Delete Passkey", func: handleLogout },
  ];

  const renderAccountMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 99999991,
            },
            ul: { paddingBottom: 0, paddingTop: 0 },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!user.credentialsId ? (
          <MenuItem className="AgeItem" onClick={generateCredentials}>
            Generate Credentials
          </MenuItem>
        ) : (
          menuItems.map(({ name, func }) => (
            <MenuItem key={name} className="AgeItem" onClick={func}>
              {name}
            </MenuItem>
          ))
        )}
        {/*<TooltipContainer*/}
        {/*  position="left"*/}
        {/*  content="Logout will log a user out and allow them to Authenticate (Sign In) again using their face with liveness detection with the option for face with liveness detection and barcode as a 3rd factor."*/}
        {/*>*/}
        <MenuItem className="AgeItem" onClick={handleLogout}>
          Logout
        </MenuItem>
        {/*</TooltipContainer>*/}
      </Menu>
    );
  };

  const renderSignInButton = () => {
    if (
      ["/authenticate", "/", "/register", "/terms", "/privacy"].includes(
        window.location.pathname
      )
    ) {
      return (
        <Button
          color={theme as "inherit"}
          variant="contained"
          style={styles.signupButton}
          className={`${classes.headerButton} ${
            matchesSM ? classes.headerButtonMobile : ""
          }`}
          onClick={onSignInClick}
          sx={{
            textTransform: "unset",
            color: skin === "c1" ? "black" : undefined,
          }}
        >
          Sign in
        </Button>
      );
    }
  };

  let wrapper = routes?.includes(window?.location?.pathname)
    ? styles.homeBar
    : styles.appBar;

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.headerWrap}>
      <AppBar
        position="fixed"
        style={{
          ...wrapper,
          borderBottom: "inherit",
          // backgroundColor: "inherit",
          zIndex: 9999999,
        }}
      >
        <Toolbar>
          {renderHeaderImage()}
          <Grid container alignItems="center" justifyContent="flex-end"></Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ flex: 1 }}
          >
            {user?._id ? (
              <>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }} />
                  </IconButton>
                </Tooltip>
                {renderAccountMenu()}
              </>
            ) : (
              <>{renderSignInButton()}</>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
