import { localThemes } from "./theme";

import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import Signin from "./pages/signin";
import Register from "./pages/register";
import FaceLogin from "./pages/login";
import ForgetMePage from "./pages/forgetMe";
import Authenticate from "./pages/authenticate";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import ContinuousAuthentication from "./pages/continuous_authentication";
import FederationHash from "./pages/federationHash";
import ViewCredentials from "./pages/ViewCredentials";
import VerifyPresentation from "./pages/VerifyPresentation";
import AAL2 from "./pages/aal2";

const skin = localThemes?.includes(window?.location?.search?.split("skin=")[1])
  ? window?.location?.search?.split("skin=")[1]
  : "primary";
const themeName = skin || "primary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home theme={themeName} skin={skin} />,
  },
  {
    path: "/signup",
    element: <Register theme={themeName} skin={skin} />,
  },
  {
    path: "/signin",
    element: (
      <Signin
        theme={themeName}
        skin={skin}
        withBarcode={false}
        withVerifiedCredentialsAuth={false}
      />
    ),
  },
  {
    path: "/register",
    element: <Register theme={themeName} skin={skin} />,
  },
  {
    path: "/login",
    element: <FaceLogin skin={skin} theme={themeName} />,
  },
  {
    path: "/login_with_dl",
    element: (
      <Signin
        theme={themeName}
        skin={skin}
        withBarcode={true}
        withVerifiedCredentialsAuth={false}
      />
    ),
  },
  {
    path: "/login_with_barcode",
    element: (
      <Signin
        theme={themeName}
        skin={skin}
        withBarcode={false}
        withVerifiedCredentialsAuth={true}
      />
    ),
  },
  {
    path: "/forget_me",
    element: <ForgetMePage skin={skin} theme={themeName} />,
  },
  {
    path: "/authenticate",
    element: <Authenticate theme={themeName} skin={skin} />,
  },
  {
    path: "/terms",
    element: <Terms theme={themeName} skin={skin} />,
  },
  {
    path: "/privacy",
    element: <Privacy theme={themeName} skin={skin} />,
  },
  {
    path: "/continuous_authentication",
    element: <ContinuousAuthentication theme={themeName} skin={skin} />,
  },
  {
    path: "/federation_hash",
    element: <FederationHash theme={themeName} skin={skin} />,
  },
  {
    path: "/view-credentials",
    element: <ViewCredentials theme={themeName} skin={skin} />,
  },
  {
    path: "/view-presentation",
    element: <VerifyPresentation theme={themeName} skin={skin} />,
  },
  {
    path: "/aal2",
    element: (
      <AAL2
        theme={themeName}
        skin={skin}
        withBarcode={false}
        withVerifiedCredentialsAuth={true}
        requestProof={false}
      />
    ),
  },
  {
    path: "/request-proof",
    element: (
      <AAL2
        theme={themeName}
        skin={skin}
        withBarcode={false}
        withVerifiedCredentialsAuth={false}
        requestProof={true}
      />
    ),
  },
]);

export default router;
