import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SelfieEmoji from "../../../assets/selfie.png";
import { styles, useStyles } from "../../../styles/styles";
import { theme as Theme } from "../../../theme";
import STEPS from "../../../pages/forgetMe/steps";
import CvsButton from "../../Button";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

interface PreForgetMeProps {
  theme: string;
  skin: string;
  setStep: (e: string) => void;
  IAL?: any;
}

export const PreForgetHeading = ({ children }: any) => (
  <Typography
    component="p"
    textAlign="center"
    fontSize={16}
    fontWeight={500}
    mt={2}
    color={"#333"}
    mb={2}
  >
    {children}
  </Typography>
);

const PreForgetMe = (props: PreForgetMeProps) => {
  const { skin, setStep } = props;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const muiTheme = useTheme();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        flexDirection={"column"}
        style={styles.cardGrid}
        className={classes.cardGridMobile}
      >
        <PreForgetHeading>
          Are you sure you want to delete account? <br />
          Get ready to take a selfie.
        </PreForgetHeading>
        <img src={SelfieEmoji} alt="scan" height={"200px"} />
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <CvsButton
        skin={skin}
        title="Continue"
        onClick={() => {
          setStep(STEPS.FORGETME);
        }}
      />
      <CvsButton
        skin={skin}
        title="Back to homepage"
        onClick={() => {
          navigateWithQueryParams("/");
        }}
      />
    </>
  );
};

export default PreForgetMe;
