import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import STEPS from "../../pages/register/steps";
import ScanBackDocument from "../DocumentCamera/ScanBackDocument";
import shield from "../../assets/shield.png";
import { UserContext } from "../../context/UserContext";
import {
  closeCamera,
  updateUser,
  uploadDL,
  uploadHC,
} from "@privateid/cryptonets-web-sdk";
import { DLType, HCType } from "@privateid/cryptonets-web-sdk/dist/types";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SpinnerLoader from "../SpinnerLoader";
import FaceCompareFrontDocument from "../DocumentCamera/FaceCompareFrontDocument";
import { cameraDelay } from "../../utils";
import { ELEMENT_ID } from "../../constants";
import { BrowserInfo, detect } from "detect-browser";
import DLAnimation from "../SignupComponents/DLAnimation";
import { federationGetHashData } from "../../services/api";
import HealthcareScanWrapper from "../HealthCareScanWrap";

const HealthCareScan = ({
  setStep,
  onSuccess,
  skin,
}: {
  setStep: any;
  onSuccess: () => void;
  skin: string;
}) => {
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUserVerify, setIsUserVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanningFailed, setIsScanningFailed] = useState(false);
  const [opStatus, setOpStatus] = useState<number>();
  const context = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => {
      setIsScanningFailed(true);
    }, cameraDelay);
  }, []);

  const onHealcareScanSuccess = async ({
    croppedDocumentBase64,
    inputImageBase64,
    rescan,
  }: {
    croppedDocumentBase64: string;
    inputImageBase64: string;
    rescan: any
  }) => {

    const checkOCRData = async (croppedImage: string) => {
      if(croppedImage){
        return true;
      }
      return false
    }

    if(await checkOCRData(croppedDocumentBase64)){
      const croppedDocumentUpload = await uploadHC({ id: context.id, type: HCType.FRONTHCORIGINAL, image: inputImageBase64 });
      const originalDocumentUpload = await uploadHC({ id: context.id, type: HCType.FRONTHCORIGINAL, image:inputImageBase64})
    }
    else{
      rescan();
    }
  };

  const onCameraNotGranted = (e: boolean) => {
    if (!e) {
      setStep(STEPS.CAMERA_PERMISSION_FAIL);
    }
  };

  const onCameraFail = async () => {
    setStep(STEPS.SWITCH_DEVICE);
  };


  // @ts-ignore
  const browserInfo: BrowserInfo = detect();
  const { os } = browserInfo;

  return (
    <>
      {opStatus !== 0 && isScanningFailed && (
        <Alert
          severity="info"
          onClick={async () => {
            setStep(STEPS.SWITCH_DEVICE);
            await closeCamera(ELEMENT_ID);
          }}
          className={classes.alertWrap}
        >
          You can try switching to other device.
        </Alert>
      )}
      <Grid
        style={styles.cardGrid}
        className={`cardGridMobile overflowUnset ${classes.cardSxMobile}`}
      >
        <Box position={"relative"}>
          <Box position={"relative"}>
            {isLoading && (
              <Box style={styles.overlay as React.CSSProperties}>
                {isUserVerify ? (
                  <img
                    src={shield}
                    alt="shield"
                    style={styles.shield as React.CSSProperties}
                  />
                ) : (
                  <SpinnerLoader />
                )}
              </Box>
            )}
            <HealthcareScanWrapper
              onSuccess={onHealcareScanSuccess}
              onReadyCallback={onCameraNotGranted}
              onCameraFail={onCameraFail}

              setOpStatus={(e: number) => setOpStatus(e)}
              setStep={setStep}
              onFailCallback={() => { }}
            />
          </Box>
        </Box>
      </Grid>
      <Box style={{ height: 84 }} className={classes.scanBottomBox}>
        {os !== "iOS" && os !== "Android OS" && (
          <Box className={classes.otherDevice} pl={3} mb={1}>
            <Typography
              component="p"
              textAlign={"left"}
              fontSize={15}
              fontWeight={500}
              mt={0}
              onClick={() => {
                setStep(STEPS.SWITCH_DEVICE);
              }}
            >
              <PhoneIphoneIcon /> Switch to Mobile Device
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => {
            setStep(STEPS.PERSONAL_DETAILS);
          }}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="none"
            fontSize={14}
          >
            I do not have a Healthcare Card
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default HealthCareScan;
