import React from "react";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useStyles } from "./styles";
import { theme as Theme } from "../../../theme";
import { useSkinContext } from "../../../context/SkinContext";
import ModalTop from "../ModalTop";

interface HomeModalProps {
  open: boolean;
  handleClose?: () => void;
  theme?: string;
  children?: React.ReactNode;
  skin?: string;
  onBack?: () => void;
  onFeedback?: () => void;
  showFeedback?: boolean;
  displayFeedback?: boolean;
  step?: string;
  success?: boolean;
  failure?: boolean;
}

const HomeModal = (props: HomeModalProps) => {
  const {
    open,
    handleClose,
    onFeedback,
    showFeedback,
    displayFeedback = true,
    success,
    failure
  } = props;
  const children: any = props?.children;
  const classes = useStyles();
  const muiTheme = useTheme();
  const mainTheme = Theme;
  const palette: { [key: string]: any } = mainTheme.palette;

  const { skin } = useSkinContext();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      hideBackdrop
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="homeModal"
      className={`${classes.modal} ${success && 'success'} ${failure && 'failure'}`}
      fullScreen={true}
      sx={{
        "& .MuiDialog-paper": {
          boxShadow: "none",
          backgroundColor: (success || failure) && matchesSM ? "transparent" : "",
        },
      }}
    >
      <Box
        mt={matchesSM ? 0 : 3}
        className={classes.homeModalCover}
        sx={{ position: "relative", flexDirection: "column" }}
      >
        <Card
          sx={{
            position: "relative",
            padding: 0,
            backgroundColor: (success || failure) && matchesSM ? "transparent" : "",
          }}
          className={classes.cardInner}
        >
          <ModalTop palette={palette} skin={skin} />
          <CardContent
            className={classes.cardChild}
            style={{
              padding: 0,
              backgroundColor: (success || failure) && matchesSM ? "transparent" : "",
            }}
          >
            {children}
          </CardContent>
          {showFeedback && (
            <Grid
              display={"flex"}
              alignItems={"center"}
              mb={matchesSM ? "0px" : "-25px"}
              justifyContent={matchesSM ? "center" : "flex-end"}
              pt={1}
              pl={matchesSM ? 2 : ""}
              pr={matchesSM ? 2 : ""}
            >
              <Link
                color={"#000"}
                className={classes.linkbox}
                target="_blank"
                href="https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy"
                ml={"0px !important"}
              >
                Privacy
              </Link>
              <Link
                color={"#000"}
                className={classes.linkbox}
                target="_blank"
                href="https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use"
                ml={"20px !important"}
              >
                Terms
              </Link>
              {displayFeedback && (
                <Link
                  color={"#000"}
                  className={classes.linkbox}
                  onClick={onFeedback}
                  ml={"20px !important"}
                >
                  Feedback
                </Link>
              )}
            </Grid>
          )}
        </Card>
        {/* <Grid
          sx={{ position: "absolute", bottom: matchesSM ? -25 : "" }}
          className={classes.footer}
        >
          <ListItem>
          </ListItem>
        </Grid> */}
      </Box>
    </Dialog>
  );
};

export default HomeModal;
