import { Box, Grid, Typography } from "@mui/material";
import STEPS from "../../pages/register/steps";

import { useStyles, styles } from "../../styles/styles";
import CvsButton from "../Button";
import SpinnerLoader from "../SpinnerLoader";

const IdentificationType = ({
  setStep,
  setDlAction,
  onPredictSuccess,
  loader,
  skin,
  requestProof,
}: {
  setStep: any;
  setDlAction: (e: string) => void;
  onPredictSuccess: (e: boolean) => void;
  loader: boolean;
  skin: string;
  requestProof?: boolean;
}) => {
  const classes = useStyles();
  return (
    <>
      {loader && (
        <Box style={styles.overlay as React.CSSProperties}>
          <SpinnerLoader />
        </Box>
      )}
      <form style={{ height: "100%" }}>
        <Grid style={styles.cardGrid} className={classes.cardGridMobile}>
          <Box marginBottom={7}>
            <Typography
              component="p"
              textAlign={"center"}
              fontSize={16}
              fontWeight={700}
              lineHeight={1.5}
              mt={3}
              mb={2}
              className={classes.cardInnerHeading}
            >
              Select Identification type
            </Typography>
          </Box>
          {/*<Box marginTop={3}>*/}
          {/*  <CvsButton*/}
          {/*    title={"Passkey"}*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      onPredictSuccess(false);*/}
          {/*    }}*/}
          {/*    skin={skin}*/}
          {/*  />*/}
          {/*</Box>*/}
          <Box marginTop={3}>
            <CvsButton
              title={"Drivers License"}
              onClick={() => {
                setDlAction("backscan");
                setStep(STEPS.BACK_DOCUMENT_SCAN);
              }}
              skin={skin}
            />
          </Box>
          <Box marginTop={3}>
            {!requestProof && (
              <CvsButton
                title={"Verified credentials (VC)"}
                onClick={() => setStep(STEPS.VERIFIED_CREDENTIALS_AUTH)}
                skin={skin}
              />
            )}
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default IdentificationType;
