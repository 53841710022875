import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useRef, useContext } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useStyles, styles } from "../../styles/styles";
import { theme as Theme } from "../../theme";
import { UserContext } from "../../context/UserContext";
import {
  updateTypeEnum,
  updateUser,
  updateUserWithSession,
} from "@privateid/cryptonets-web-sdk/dist/apiUtils";
import STEPS from "../../pages/register/steps";
import useToast from "../../utils/useToast";
import { formatPhoneInput } from "../../utils";
import { UserContextV2 } from "../../context/UserContextV2";

const RequestSsn = ({
  setStep,
  skin,
  matchesSM,
  onSuccess,
  setPrevStep,
  loading,
}: {
  setStep: any;
  setPrevStep?: (e: string) => void;
  skin: string;
  matchesSM: boolean;
  onSuccess: () => void;
  loading: boolean;
}) => {
  const classes = useStyles();
  const mainTheme = Theme;
  const { showToast } = useToast();
  const [ssn, setSnn] = useState("");
  const palette: { [key: string]: any } = mainTheme.palette;

  const ssn9Ref = useRef<HTMLFormElement | null>(null);

  const context = useContext(UserContext);
  const { id } = context;
  const userContextV2 = useContext(UserContextV2);

  const handleContinue = async () => {
    if (ssn9Ref?.current?.value.length !== 11) {
      showToast("Enter SSN9", "error");
    } else if (ssn9Ref?.current?.value.length === 11) {
      const inputSSN9 = ssn9Ref?.current?.value.replace(/-/g, "");
      // const updateUserResult: any = await updateUser({
      //   id,
      //   attributes: { ssn9: inputSSN9 } as any,
      // });

      await updateUserWithSession({
        sessionToken: userContextV2.sessionToken,
        updateType: updateTypeEnum.personalDetails,
        ssn9: inputSSN9,
      });

      // if (updateUserResult?.level === "error") {
      //   showToast(updateUserResult?.message, "error");
      // } else {
      onSuccess();
      // }
    }
  };
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        style={styles.cardGrid}
        className={classes.cardGridMobile}
      >
        <Box width={"100%"}>
          <Typography
            component="p"
            textAlign={matchesSM ? "center" : "left"}
            fontSize={16}
            fontWeight={500}
            lineHeight={1.5}
            mt={0}
            className={classes.cardInnerHeading}
            mb={3}
          >
            We were unable to find your record. <br />
            Please try again with your full social security number.
          </Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            label="SSN9"
            type="tel"
            placeholder="SSN9"
            name="SSN9"
            InputProps={{
              startAdornment: <AccountBoxIcon sx={{ pr: 1 }} />,
            }}
            onChange={(e) => setSnn(e?.target?.value)}
            value={formatPhoneInput(ssn)}
            inputRef={ssn9Ref}
          />
        </Box>
      </Grid>
      {!matchesSM && <Divider color={palette?.[skin]?.borderColor} />}
      <Box>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={handleContinue}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="capitalize"
            fontSize={14}
          >
            {loading ? (
              <CircularProgress className={classes.scanLoader} />
            ) : (
              "Continue"
            )}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color={"inherit"}
          style={styles.continueButton}
          className={classes.continueButton}
          onClick={() => {
            setStep(STEPS.CONSENT_FAIL);
            setPrevStep?.(STEPS.REQUEST_SSN);
          }}
        >
          <Typography
            component="p"
            color={palette?.[skin]?.text}
            textAlign="center"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            textTransform="capitalize"
            fontSize={14}
          >
            No, I do not consent
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default RequestSsn;
