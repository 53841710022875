import { useState } from "react";
import { useTheme, useMediaQuery } from "@mui/material";

import HomeModal from "../../components/Modal/homeModal";
import Header from "../../components/Header";
import STEPS from "./steps";
import { useNavigate } from "react-router";
import ForgetMe from "../../components/AuthenticateComponents/ForgetMe";
import ForgetMeSuccess from "../../components/AuthenticateComponents/ForgetMeSuccess";
import PreForgetMe from "../../components/AuthenticateComponents/PreForgetMe";

interface ForgetMePageProps {
  theme: string;
  skin: string;
}

const ForgetMePage = ({ theme, skin }: ForgetMePageProps) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(STEPS.START);
  const muiTheme = useTheme();
  const matchesSM = useMediaQuery(muiTheme.breakpoints.down("sm"));

  const _renderChildren = () => {
    switch (step) {
      case STEPS.START:
        return <PreForgetMe setStep={setStep} skin={skin} theme={theme} />;
      case STEPS.FORGETME:
        return <ForgetMe setStep={setStep} skin={skin} />;
      case STEPS.SUCCESS:
        return <ForgetMeSuccess matchesSM={matchesSM} skin={skin} />;
      default:
        return <></>;
    }
  };
  const themeName = skin || "primary";
  return (
    <>
      {<Header theme={themeName} />}
      <div className="homePageWrapper">
        <HomeModal
          handleClose={() => {
            navigate("/");
          }}
          open={true}
          onFeedback={() => {}}
          showFeedback={false}
          success={step === STEPS.SUCCESS}
        >
          {_renderChildren()}
        </HomeModal>
      </div>
    </>
  );
};

export default ForgetMePage;
